import React from 'react';
import { styled as muiStyled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";

const Form = muiStyled(Drawer)(() => ({
    '.MuiDrawer-paperAnchorBottom': {
        padding: '3rem 2rem',
        borderTopLeftRadius: '3rem',
        borderTopRightRadius: '3rem',
        maxHeight: '95%',
    },
}));

export function MobileForm({ children }) {
    return (
        <Form
            anchor="bottom"
            open={true}
            hideBackdrop={true}
        >
            {children}
        </Form>
    );
}