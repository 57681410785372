import React from "react";
import styled from "styled-components";
import { Rating } from "@mui/material";
import { THEME_COLORS } from "./colors";
import { MinimalLink } from "../profile/styledComponents";
import StarIcon from "@mui/icons-material/Star";
import { Subheader14 } from "./Typography";

export const Ratings = styled(Rating).attrs({
    emptyIcon: <StarIcon style={{opacity: 0.35}} fontSize="inherit"/>,
    precision: 0.5,
})`
  .MuiRating-decimal {
    label {
      top: 4px !important;
    }
  }
  path {
    color: ${THEME_COLORS.primary} !important;
  }
`;

export const RatingText = styled(Subheader14)`
  margin: 0 12px !important;
`;

export const RatingLink = styled(MinimalLink)`
  color: ${THEME_COLORS.primary} !important;
  font-size: 0.75rem !important;
`;
