import {useContext, useMemo, useState} from "react";
import {AuthContext} from "../app/AuthContext";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import { fromApi as fromAssetApi } from "../common/models/post";
import { fromApi as fromContractApi } from "../common/models/contractPost";

export function useGetFollowerContent() {
    const { authContext } = useContext(AuthContext);
    const { token } = authContext;
    const [assetLoading, setAssetLoading] = useState(false);
    const [completedSwappLoading, setCompletedSwappLoading] = useState(false);
    const [assetLoaded, setAssetLoaded] = useState(false);
    const [completedSwappLoaded, setCompletedSwappLoaded] = useState(false);
    const [followingAssetContent, setFollowingAssetContent] = useState([]);
    const [followingSwappsContent, setFollowingSwappsContent] = useState([]);

    const getFollowingAssets = () => {
        axios
            .get(`${process.env.REACT_APP_PUBLIC_API_URL}/posts/posts`, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'x-access-token': token,
                },
            })
            .then(({ data }) => {
                setFollowingAssetContent((data ?? [])
                    .filter((record) => !!record.images.length)
                    .map((record) => ({
                    modifiedDate: moment(record.modified_at ?? record.created_at).format("dddd, MMMM Do YYYY, h:mm:ss a"),
                    index: -(moment(record.modified_at  ?? record.created_at).unix()),
                    type: 'ASSET',
                    isFollowing: record.is_following,
                    data: fromAssetApi(record),
                })));
                setAssetLoaded(true);
                setAssetLoading(false);
            })
            .catch(error => {
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
                setAssetLoaded(true);
                setAssetLoading(false);
            });
    };
    
    const getCompletedSwapps = () => {
        axios
            .get(`${process.env.REACT_APP_PUBLIC_API_URL}/posts/contracts`, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'x-access-token': token,
                },
            })
            .then(({ data }) => {
                setFollowingSwappsContent((data ?? []).map((record) => ({
                    modifiedDate: moment(record.modified_at ?? record.created_at).format("dddd, MMMM Do YYYY, h:mm:ss a"),
                    index: -(moment(record.modified_at ?? record.created_at).unix()),
                    type: 'CONTRACT',
                    isFollowing: record.is_following,
                    data: fromContractApi(record),
                })));
                setCompletedSwappLoaded(true);
                setCompletedSwappLoading(false);
            })
            .catch(error => {
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
                setCompletedSwappLoaded(true);
                setCompletedSwappLoading(false);
            });
    };

    if (!assetLoaded && !assetLoading) {
        setAssetLoading(true);
        getFollowingAssets();
    }

    if (!completedSwappLoaded && !completedSwappLoading) {
        setCompletedSwappLoading(true);
        getCompletedSwapps();
    }

    const followingContent = useMemo(() => ([
        ...followingAssetContent,
        ...followingSwappsContent
    ]), [followingAssetContent, followingSwappsContent]);

    return {
        followingContent,
        loading: assetLoading || completedSwappLoading,
    };
}