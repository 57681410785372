import React, {useCallback, useState} from "react";
import styled from "styled-components";
import { Tag, TruncateButton, UserDisplayName } from "../styledComponents";
import { getPronounDisplay } from "../utils";
import { getCategoryLabelsFromAssets } from "../../common/utils";
import PrimaryButton from "../../common/PrimaryButton";
import { InputAdornment, Tooltip, Typography } from "@mui/material";
import { VisibilitySharp } from "@mui/icons-material";
import PlaceIcon from '@mui/icons-material/LocationOnRounded';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { THEME_COLORS } from "../../common/colors";
import useFetchUserAssets from "../../assets/hooks/useFetchUserAssets";
import { ActiveOfferList } from "./ActiveOfferList";
import { NoDataYet, SubHeader, TagDisplay } from "./styles";
import {Body10, Body12, Header12, Header14, Subheader14} from "../../common/Typography";
import { useHistory } from "react-router-dom";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import { DEFAULT_MAX_CATEGORIES_DISPLAY } from "../constants";
import { useIsAlreadyFollowing } from "../../follow/useIsAlreadyFollowing";
import { useFollow } from "../../follow/useFollow";
import { useGetFollowingsInfo } from "../../follow/useGetFollowingInfo";
import { FollowersList } from "./components/FollowersList";
import { Popover } from "../../common/Popover";
import { autoCopy } from "../../common/utils/autoCopy";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import ContentCopyIcon from '@mui/icons-material/ContentCopyRounded';
import CheckIcon from '@mui/icons-material/CheckRounded';

const NameHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

const PublicViewIcon = styled(VisibilitySharp)`
  fill: #AAB1A9 !important;
  margin-top: 4px !important;
  cursor: pointer !important;
  font-size: 20px !important;
`;

const PublicViewOffIcon = styled(VisibilityOffIcon)`
  fill: #AAB1A9 !important;
  margin-top: 4px !important;
  cursor: pointer !important;
  font-size: 20px !important;
`;

const UserCategoryList = styled(Subheader14)`
  color: ${THEME_COLORS.primary} !important;
  margin-bottom: 0 !important;
`;

const Location = styled(Body10)`
  display: flex !important;
  align-items: center !important;
  margin-bottom: 1.5rem !important;
`;

const LocationIcon = styled(PlaceIcon)`
  fill: #717273 !important;
  margin-left: -4px;
`;

const ActionButtons = styled.div`
  max-width: 250px;
  display: flex;
`;

const FollowingContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  margin-bottom: 0.5rem;
  
  h4 {
    position: relative;
    bottom: 3px;
  }
`;

const FollowingSection = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
  cursor: pointer;
`;

const ActiveOfferHeader = styled.div`
  text-align: center;
  width: 90%;
  max-width: 440px;
  background-color: ${THEME_COLORS.primary};
  border-radius: 28px;
  padding: 4px;
  color: #fff;
  margin: 48px 0 16px 0;
`
const MiddlePanel = styled.div`
  grid-area: MiddlePanel;
  width: 100%;
  padding: 0 7%;
`;

const Content = styled.div`
  max-width: 640px;
  margin: 0 auto;
`;

const ProfileLabel = styled(SubHeader)`
    margin-top: 2.6rem !important;
    margin-bottom: 1rem !important;
    font-weight: 800 !important;
`;

const InviteLinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 140px;
    width: 300px;
    margin-top: 0.5rem;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 4px 4px 14px #515050;
    justify-content: center;
    border-radius: 1.5rem;

    .MuiInputBase-root {
        width: 100%;
        .MuiInputBase-input {
            padding: 10.5px 14px;
            background-color: #e3e5e3;
        }
        
        button {
            padding: 4px;
            padding-left: 0;
        }
    }
`;

const InviteLink = styled(Popover)`
    .share-content-pop-over {
        transform: translate3d(463px, -139px, 0px) !important;
    }
`;

const InviteHeader = styled.div`
    margin-bottom: 1rem;
`;

function InviteLinkModal({
    inviteLink,
     copySuccess,
     setCopySuccess,
}) {
    return (
        <InviteLinkContainer>
            <InviteHeader>
                <Header12>Invite a friend</Header12>
                <Body10>Copy your invite link and share it with others.</Body10>
            </InviteHeader>
            <OutlinedInput
                id="invite-link"
                value={inviteLink}
                disabled={true}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => autoCopy(inviteLink, setCopySuccess)}
                            edge="end"
                        >
                            {copySuccess ? <CheckIcon/> : <ContentCopyIcon/>}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </InviteLinkContainer>
    );
}

export function MiddleSection({ isMobile, isCurrentUser, categories, type, user, userLookingForList, userInfo, contractDetails = [] }) {
    const { userAssets } = useFetchUserAssets(user);
    const history = useHistory();
    const [showAllCategories, setShowAllCategories] = useState(false);
    const [followType, setFollowType] = useState(null);
    const userLookingForListDisplay = showAllCategories ? userLookingForList : userLookingForList.slice(0, DEFAULT_MAX_CATEGORIES_DISPLAY);
    const { isFollowing, loading: checking, setIsFollowing } = useIsAlreadyFollowing(user.id, isCurrentUser);
    const followCallback = (_, bool) => {
        setIsFollowing(bool);
    }

    const { follow, loading, unfollow } = useFollow(followCallback);
    const { followersCount, followers, followingsCount, followings } = useGetFollowingsInfo(user.id);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [copySuccess, setCopySuccess] = useState(false);

    const handleInviteClick = useCallback((event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }, [anchorEl]);

    return (
        <MiddlePanel>
            <Content>
                <NameHeader>
                    <UserDisplayName>
                        {user.firstname} {user.surname} {user?.pronouns?.length > 0 ? `(${getPronounDisplay(user.pronouns)})` : ''}
                    </UserDisplayName>
                    {
                        type === 'view' &&
                        <PublicViewIcon onClick={() => history.push(`/profile/${user.uuid}`)}  fontSize="medium"  />
                    }
                    {
                        type === 'public' && isCurrentUser &&
                        <PublicViewOffIcon onClick={() => history.push(`/profile`)}  fontSize="medium"  />

                    }
                </NameHeader>
                <UserCategoryList variant="h6" gutterBottom>
                    {getCategoryLabelsFromAssets(categories, userAssets).join(' | ')}
                </UserCategoryList>
                <FollowingContainer>
                    <FollowingSection onClick={() => setFollowType("Followers")}>
                        <Header14>{followersCount || '-'}</Header14>
                        <Body12 variant="h4">followers</Body12>
                    </FollowingSection>
                    <FollowingSection onClick={() => setFollowType("Following")}>
                        <Header14>{followingsCount || '-'}</Header14>
                        <Body12 variant="h4">following</Body12>
                    </FollowingSection>
                </FollowingContainer>
                <Location variant="body2">
                    <LocationIcon fontSize="small" />
                    {user.displaylocation  || 'No Location'}
                </Location>
                {
                    type === 'view' &&
                    <ActionButtons>
                        <PrimaryButton
                            variant="contained"
                            className="condensed"
                            onClick={() => history.push('/profile/edit')}
                            sx={{ marginRight: '1rem !important' }}
                        >
                            Edit
                        </PrimaryButton>
                        <Tooltip title="Feature coming soon!" arrow placement="bottom">
                            <PrimaryButton
                                variant="contained"
                                className="condensed"
                                sx={{ marginRight: '1rem !important' }}
                            >
                                History
                            </PrimaryButton>
                        </Tooltip>
                        <PrimaryButton
                            variant="contained"
                            className="condensed"
                            onClick={handleInviteClick}
                        >
                            Invite
                        </PrimaryButton>
                        {
                            anchorEl &&
                            <InviteLink
                                open={anchorEl}
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl(null)}
                                isMobile={isMobile}
                                placement="bottom-start"
                            >
                                <InviteLinkModal
                                    inviteLink={`${process.env.REACT_APP_PUBLIC_URL}/signup?invite-token=${user.userinvitationkey}`}
                                    copySuccess={copySuccess}
                                    setCopySuccess={setCopySuccess}
                                />
                            </InviteLink>
                        }
                    </ActionButtons>
                }
                {
                    type === 'public' &&
                    <ActionButtons>
                        {!isFollowing &&
                            <PrimaryButton
                                variant="contained"
                                className="condensed"
                                disabled={isCurrentUser || checking}
                                loading={loading}
                                sx={{marginRight: '1rem !important'}}
                                onClick={isCurrentUser ? undefined : () => follow(user.id)}
                            >
                            Follow
                        </PrimaryButton>
                        }
                        {isFollowing &&
                            <PrimaryButton
                                variant="contained"
                                className="condensed"
                                sx={{marginRight: '1rem !important'}}
                                loading={loading}
                                onClick={() => unfollow(user.id)}
                            >
                                Unfollow
                            </PrimaryButton>
                        }
                        <PrimaryButton
                            variant="contained"
                            className="condensed"
                            onClick={() => history.push(`/user-assets/${user.uuid}`)}
                        >
                            View assets
                        </PrimaryButton>
                    </ActionButtons>
                }
                {
                    type === 'view' &&
                    <div>
                        <ActiveOfferList user={user} contractDetails={contractDetails}/>
                    </div>
                }
                <ProfileLabel>
                    About {user.firstname}:
                </ProfileLabel>
                <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body1" gutterBottom>
                    {user.description || 'No bio yet.'}
                </Typography>
                {
                    type === 'public' &&
                    <TagDisplay>
                        <ProfileLabel>Looking for:</ProfileLabel>
                        <div>
                            <div>
                                {
                                    user.topcategories?.length > 0 ? userLookingForListDisplay
                                        .map((value) => {
                                            return (
                                                <Tag key={`${value}-tag`}>
                                                    {value}
                                                </Tag>
                                            )
                                        }) : 'No top categories yet.'
                                }
                            </div>
                            {
                                userLookingForList?.length > DEFAULT_MAX_CATEGORIES_DISPLAY &&
                                <TruncateButton
                                    size="small"
                                    onClick={() => setShowAllCategories(!showAllCategories)}
                                    startIcon={showAllCategories ? <RemoveIcon/> : <AddIcon/>}
                                >
                                    {showAllCategories ? 'Less' : 'More'}
                                </TruncateButton>
                            }
                        </div>
                    </TagDisplay>
                }
                {
                    type === 'public' &&
                    <div>
                        <ProfileLabel>
                            When I swapp, I value:
                        </ProfileLabel>
                        <div>
                            {
                                user.profilevalues?.length > 0 ? user.profilevalues.map((value) => {
                                        return (
                                            <Tag key={`${value}-tag`}>
                                                {value}
                                            </Tag>
                                        )
                                    }) :
                                    <NoDataYet>
                                        No values added yet
                                    </NoDataYet>
                            }
                        </div>
                    </div>
                }
            </Content>
            {
                !!followType &&
                <FollowersList
                    followingContent={followType === 'Followers' ? followers : followings}
                    title={followType}
                    isOpen={!!followType}
                    follow={follow}
                    unfollow={unfollow}
                    onClose={() => setFollowType(null)}
                    userInfo={userInfo}
                    isCurrentUser={isCurrentUser}
                />
            }
        </MiddlePanel>
    );
}