import React from "react";
import { NavBar } from "../NavBar";
import HomeLogo from "../HomeLogo";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {styled as muiStyled, useTheme} from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";

const Banner = styled.div`
  background-color: ${({ color }) => color === 'gray' ? '#F4F5F4' : '#FFF'};
  height: ${({ expanded }) => expanded === 'true' ? 120 : 60}px;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
`;

const ActionButton = styled.div`
  position: absolute;
  left: 0;
`;

const LogoHeader = styled.div`
  background-color: ${({ color }) => color === 'gray' ? '#F4F5F4' : '#FFF'};
  width: 100%;
  z-index: 1097;
  height: 58px;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  padding: 0 2rem 2rem 2rem;
`;

const LeftDrawer = muiStyled(Drawer)(
    ({ zIndex }) => ({
        gridArea: 'LeftPanel',
        width: '100%',
        zIndex: zIndex ?? 9999,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: '100%',
            boxSizing: 'border-box',
            backgroundColor: '#F4F5F4',
        },
    }),
);

const DrawerButton = styled.div`
  position: fixed;
  z-index: 1098;
  background-color: #F4F5F4;
  left: 0;
  top: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DrawerCloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.3rem;
`;

const DrawerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export function FixBannerPageLayout({
    children,
    color,
    expanded,
    leftPanel,
    isOpen,
    hidePanelControls,
    zIndex,
    actionButton,
}) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <NavBar />
            <Banner color={color} expanded={expanded}>
                <LogoHeader color={color}>
                    {
                        actionButton &&
                        <ActionButton>
                            { actionButton }
                        </ActionButton>
                    }
                    <HomeLogo />
                </LogoHeader>
            </Banner>
            {
                !!leftPanel &&
                <LeftDrawer
                    anchor="left"
                    open={isOpen || open}
                    zIndex={zIndex}
                >
                    <Logo>
                        <HomeLogo />
                    </Logo>
                    <DrawerContainer>
                        {leftPanel}
                        {
                            !hidePanelControls &&
                            <DrawerCloseButton>
                                <IconButton onClick={handleDrawerClose}>
                                    {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                                </IconButton>
                            </DrawerCloseButton>
                        }
                    </DrawerContainer>
                </LeftDrawer>
            }
            <Container>
                {children}
            </Container>
            {
                !!leftPanel && !hidePanelControls &&
                <DrawerButton onClick={handleDrawerOpen}>
                    <ChevronRightIcon/>
                </DrawerButton>
            }
        </div>
    );
}