import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { styled as muiStyled } from "@mui/material/styles";

const Section = muiStyled(Card)(() => ({
    '&': {
        borderRadius: '1.5rem',
        marginBottom: '1rem ',
        boxShadow: '0px 0px 12px -1px rgba(0,0,0,0.2)',
    },
}));

export function ContentSection({ children, ...rest }) {
    return (
        <Section {...rest}>
            <CardContent>
                {children}
            </CardContent>
        </Section>
    )
}
