import axios from 'axios';
import OneSignal from "react-onesignal";
import _ from 'lodash';
import { useState } from 'react';
import { fromApi } from "../common/models/user";

function useFetchProfile(userData, token) {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const fetchProfile = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API_URL}/users/profile`, {
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'x-access-token': token,
        },
      })
      .then(response => {
        setUserInfo(fromApi(response.data));
        setLoaded(true);
        setLoading(false);

        OneSignal.login(response.data?.uuid);
        return response.data;
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        setLoading(false);
        setLoaded(true);
      });
  };

  if (_.isEmpty(userInfo) && loading === false && loaded === false) {
    fetchProfile();
  }

  return { userInfo, loading, loaded };
}

export default useFetchProfile;
