import * as React from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import styled from "styled-components";
import {Ratings} from "../../common/RatingDisplays";

const productLabels = {
    1: 'For Parts',
    2: 'Acceptable',
    3: 'Good',
    4: 'Like-New',
    5: 'Brand-New',
};

const serviceLabels = {
    1: 'Novice',
    2: 'Intermediate',
    3: 'Proficient',
    4: 'Advanced',
    5: 'Expert',
};

const Label = styled.div`
    margin-left: 8px;
`

const getLabelText = (labels) => (value) => {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
};

export default function DescriptiveRating({ categoryType, onChange, value, readOnly, alignment }) {
    const [hover, setHover] = React.useState(-1);
    const labels = categoryType === 'SERVICE' ? serviceLabels : productLabels;

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: alignment ?? 'center',
            }}
        >
            <Ratings
                name="hover-feedback"
                value={value}
                precision={1}
                readOnly={readOnly}
                getLabelText={getLabelText(labels)}
                onChange={onChange}
                onChangeActive={(event, newHover) => {
                    setHover(newHover);
                }}
            />
            {value !== null && (
                <Label>{labels[hover !== -1 ? hover : value]}</Label>
            )}
        </Box>
    );
}