import * as React from 'react';
import styled from "styled-components";
import { styled as muiStyled } from '@mui/material/styles';
import MuiDialog from '@mui/material/Dialog';
import {Body10, Header16} from "../Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

const StyledDialog = muiStyled(MuiDialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: '36px',
        border: '2px solid #D9D9D9',
        overflowY: 'hidden',
        width: '100%',
        padding: '1.5rem 1rem',
    },
    '& .MuiDialogContent-root': {
        maxHeight: 568,
        minHeight: 300,
        border: 'unset',
    },
    '& .MuiDialogTitle-root': {
        textAlign: 'center',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        marginBottom: 12,
        width: '60%',
    },
}));

const Label = styled(Header16)`
  margin-bottom: 0.5rem !important;
  text-align: center !important;
  ${({type}) => type === 'error' && 'color: #D65C5E !important;'}
  ${({type}) => type === 'disabled' && 'color: #F4F5F4 !important;'}
`;

export default function ActionMenuModal({
   options = [],
   theme,
   radius,
   onClose,
}) {

    return (
        <StyledDialog
            onClose={onClose}
            aria-labelledby="action-menu"
            open
            backgroundcolor={theme}
            borderradius={radius}
        >
            <Stack
                divider={<Divider flexItem />}
                spacing={2}
            >
                {options.map((option, i) => {
                    return (
                        <div key={`${option.label}-${i}`}>
                            <Label type={option.type} onClick={option.action}>
                                {option.label}
                            </Label>
                            {option.description && <Body10 sx={{ textAlign: 'center' }}>{option.description}</Body10>}
                        </div>
                    );
                })}
            </Stack>
        </StyledDialog>
    );
}