import { useCallback, useContext, useState } from "react";
import { AuthContext } from "../../app/AuthContext";
import { fromApi } from "../../common/models/user";
import axios from "axios";
import useUploadFinalImage from "../../common/hooks/useUploadFinalImage";
import { validation } from "../validation";
import { capitalizeFirstLetter } from "../../common/utils/capitalizeFirstLetter";
import { genericUpdateError } from "../../toaster/utils";
import { toUserForm } from "../editProfile/toUserForm";
import { isUserProfileCompleted } from "../../common/isUserProfileCompleted";

export default function useEditProfile(userData, setUserInfo, setOpenModal, userAssets, setDraftTimestamp) {
    const validations = validation();

    const { authContext } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const { loading: loadingImage, uploadFinalImage } = useUploadFinalImage((data) => {
        setUserInfo({
            ...userData,
            profilepicture: data.Location,
        });
        setOpenModal(null);
    });

    const [errors, setErrors] = useState({
        firstname: '',
        surname: '',
        profilepicture: '',
        profilevalues: '',
        displaylocation: '',
        topcategories: '',
        top_my_asset_categories: '',
        description: '',
        username: '',
        phone: '',
    });

    const validateProfile = (inputs) => {
        const errorFields = Object
            .entries(inputs)
            .filter(([key]) => Object.keys(errors).includes(key))
            .reduce((err, entries) => {
                err[entries[0]] = validations[entries[0]](entries);
                return err;
            }, {});
        setErrors(errorFields);
        return !Object.values(errorFields).some(v => v);
    }

    const updateProfile = useCallback((formData) => {
        if(validateProfile(formData)) {
            const data = toUserForm(formData);
            setLoading(true);
            axios
                .put(`${process.env.REACT_APP_PUBLIC_API_URL}/users`,
                    {
                        ...data,
                        firstname: capitalizeFirstLetter(data.firstname),
                        surname: capitalizeFirstLetter(data.surname),
                    },
                    {
                        headers: {
                            'Content-type': 'application/json; charset=UTF-8',
                            'x-access-token': authContext.token,
                        },
                    })
                .then(response => {
                    const updatedUserData = fromApi({
                        ...userData,
                        ...formData,
                        assetcount: (userAssets || []).length,
                    })
                    setUserInfo(updatedUserData);
                    setLoading(false);
                    setDraftTimestamp(null);
                    localStorage.removeItem(`draft-profile-${userData.uuid}`);

                    if (isUserProfileCompleted(updatedUserData)) {
                        window.location = '/profile?message=update_profile_success';
                    }
                })
                .catch(error => {
                    setLoading(false);
                    genericUpdateError(`Failed to update your profile: ${error}`);
                    if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
                });
        }
    }, [userAssets]);

    return { loading, loadingImage, updateProfile, uploadFinalImage, errors }
}