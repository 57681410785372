import React, {useCallback, useEffect, useState} from "react";
import DialogForm from "../../../common/DialogForm";
import { FollowerContent } from "./FollowerContent";
import styled from "styled-components";
import Searchbar from "../../../common/Searchbar";
import ActionMenuModal from "../../../common/Mobile/ActionMenuModal";

const Container = styled.div`
  padding: 2rem;
`;

const FollowList = styled.div`
  overflow-y: auto;
  max-height: 480px;
  margin-top: 1.2rem;
`;

export function FollowersList({
  followingContent,
  title,
  isOpen,
  follow,
  unfollow,
  isMobile,
  onClose,
  userInfo,
  isCurrentUser,
}) {
    const [filteredContent, setFilteredContent] = useState(followingContent);
    const [openModal, setOpenModal] = useState(false);
    const [menuActions, setMenuActions] = useState([]);

    const handleOpenMenuAction = (options) => {
        setMenuActions(options);
        setOpenModal(true);
    };

    useEffect(() => {
        setFilteredContent(followingContent);
    }, [title]);

    const onSearch = useCallback((search) => {
        if (search) {
            const filtered = followingContent.filter(
                ({ firstname, surname }) => `${firstname} ${surname}`.toLowerCase().includes(search.toLowerCase()));
            setFilteredContent(filtered);
        } else {
            setFilteredContent(followingContent);
        }

    }, [followingContent]);

    return (
        <>
            <DialogForm
                isMobile={isMobile}
                isOpen={isOpen}
                label={title}
                description=""
                onClose={onClose}
                closePosition="right"
            >
                <Container>
                    <Searchbar onSearch={onSearch} />
                    <FollowList>
                        {filteredContent.map((content) =>
                            <FollowerContent
                                key={`${title}-${content.firstname}-${content.surname}`}
                                isCurrentUser={userInfo.id === content.id}
                                user={content}
                                isFollowing={content.isfollowing || (isCurrentUser && title === 'Following')}
                                follow={follow}
                                unfollow={unfollow}
                                isMobile={isMobile}
                                onOpenMenuAction={handleOpenMenuAction}
                            />
                        )}
                    </FollowList>
                </Container>
            </DialogForm>
            {
                openModal &&
                <ActionMenuModal
                    options={menuActions}
                    onClose={() => {
                        setMenuActions([]);
                        setOpenModal(false);
                    }}
                />
            }
        </>
    );
}