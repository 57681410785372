import {useCallback, useContext, useState} from "react";
import { AuthContext } from "../../app/AuthContext";
import axios from "axios";
import { fromApi } from "../../common/models/comment";

export function useGetCommentsForPost(postId, type) {
    const { authContext } = useContext(AuthContext);
    const { token } = authContext;
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [commentList, setCommentList] = useState([]);

    const getComments = useCallback(() => {
        axios
            .get(`${process.env.REACT_APP_PUBLIC_API_URL}/comments/post/${type}/${postId}`, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'x-access-token': token,
                },
            })
            .then(({ data }) => {
                setCommentList((data ?? []).map(fromApi));
                setLoaded(true);
                setLoaded(false);
            })
            .catch(error => {
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
                setLoaded(true);
                setLoaded(false);
            });
    }, [postId, type]);

    if (!loaded && !loading && postId && type) {
        setLoading(true);
        getComments();
    }

    return {
        commentList,
        loading,
        getComments,
    };
}