
const required = (message) => ([field, value]) => {
    return !value.toString().trim() ? (message || `${field} is required.`) : '';
}

const shouldMatch = (message, sourceKey) => ([field, value], formData) => {
    return value.toString() !== formData[sourceKey].toString() ? message : '';
}

const allOf = (validations) => (entries, formData) => {
    let err = '';
    validations.every(validation => {
        err = validation(entries, formData);
        return !err;
    });
    return err;
}

export function validation() {
    return ({
        firstname: required('First name is required.'),
        surname: required('Last name is required.'),
        username: required('Email is required.'),
        password: required('Password is required.'),
        password2: allOf([
            required('Re-entering your password is required.'),
            shouldMatch('Passwords do not match.', 'password')
        ]),
        hasAgreed: required('You must agree to the Terms & conditions, and Privacy Policy.'),
    })
}
