import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter";

export function fromApi(data) {
    return ({
        id: data.id,
        assetId: data.asset_id,
        assetUuid: data.asset_uuid,
        userUuid: data.user_uuid,
        description: data.description,
        status: data.status,
        images: data.images,
        tags: data.tags,
        title: data.title,
        modifiedAt: data.modified_at ?? data.created_at,
        serialId: data.serial_id,
        likesCount: data.likes_count,
        likeId: data.user_like,
        commentsCount: data.comments_count,
        mostLikelySwapp: data.topcategories ?? [],
        user: {
            id: data.user_id,
            uuid: data.user_uuid,
            profilePicture: data.profilepicture ?? '',
            firstName: capitalizeFirstLetter(data.firstname ?? ''),
            lastName: capitalizeFirstLetter(data.surname ?? ''),
            displaylocation: data.displaylocation ?? '',
            topCategories: data.topcategories ?? [],
        }
    });
}