import { styled as styledMUI } from "@mui/material/styles";
import { DateField } from "@mui/x-date-pickers/DateField";

export const DateFormField = styledMUI(DateField)(() => ({
    width: '100%',
    '::before': {
        border: 'unset !important',
    },
    '.MuiInputBase-input': {
        padding: '12px',
    },
    '::after': {
        border: 'unset !important',
    },
    '.MuiInputBase-multiline': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        padding: '2px',
    },
    '.MuiInputBase-root': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        marginBottom: '0.7rem',
    }
}));