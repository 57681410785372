import axios from 'axios';
import {useState, useContext, useEffect} from 'react';
import { AuthContext } from '../app/AuthContext';
import {fromApi} from "../common/models/chat";
import _ from 'lodash';

const useFetchChatDetails = (serialId, history, handleUpdateMessageRead, handleDrawerClose) => {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const { authContext } = useContext(AuthContext);
    const { token } = authContext;
    const [chatDetails, setChatDetails] = useState({});

    useEffect(() => {
        if (!loaded && serialId && _.isEmpty(chatDetails)) {
            fetchChatDetailsBySerialId(serialId);
        }
    }, [loaded, serialId, chatDetails]);

    const handleChatChange = (id) => {
        setLoading(true);
        setChatDetails({});
        fetchChatDetailsBySerialId(id);
    }

    const updateReadMessages = (id) => {
        axios
            .put(`${process.env.REACT_APP_PUBLIC_API_URL}/chat/updateTotal`, {
                chatId: id,
            },{
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'x-access-token': token,
                },
            })
            .catch(error => {
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
            });
    };

    const fetchChatDetailsBySerialId = (id) => {
        setLoading(true);
        setLoaded(true);
        axios
            .get(`${process.env.REACT_APP_PUBLIC_API_URL}/chat/${id}`, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'x-access-token': token,
                },
            })
            .then(response => {
                setLoading(false);
                if (response.data[0].id) {
                    history.push(`/chat/${response.data[0].serial_id}`);
                    updateReadMessages(response.data[0].id);
                    setChatDetails(fromApi(response.data[0]));
                    handleUpdateMessageRead(response.data[0].serial_id);
                    handleDrawerClose();
                }
                return response.data;
            })
            .catch(error => {
                setLoading(false);
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
            });
    };

    return {
        chatDetails,
        fetchChatDetailsBySerialId,
        handleChatChange,
        setChatDetails,
        loading,
    };
};

export default useFetchChatDetails;
