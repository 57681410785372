import useUploadFinalImage from "../../common/hooks/useUploadFinalImage";

export default function useUploadAssetImages(asset, setAsset, setOpenModal, imageIndex) {
    const callback = (data) => {
        let updatedImages = asset.images ?? [];
        if (updatedImages.length > imageIndex) {
           updatedImages[imageIndex] = data.Location;
        } else {
           updatedImages.push(data.Location);
        }
        setAsset({
            ...asset,
            mainImage: imageIndex === 0 ? data.Location : asset.mainImage,
            images: updatedImages,
        });
        setOpenModal(null);
    }
    return useUploadFinalImage(callback);
};