import * as ContractUser from './contractUser';

function fromApi(data) {
    return ({
        id: data.id,
        chatId: data.chat_id,
        serialId: data.serial_id,
        status: data.status,
        requesterUserContract: ContractUser.fromApi(data.requester_user_contract[0]),
        requestedUserContract: ContractUser.fromApi(data.requested_user_contract[0]),
    })
}

export { fromApi }