import * as React from 'react';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import SwipeableEdgeDrawer from '@mui/material/SwipeableDrawer';

const drawerBleeding = 56;

const SwipeableDrawer = styled(SwipeableEdgeDrawer)(() => ({
    '.MuiDrawer-paperAnchorBottom': {
        borderTopLeftRadius: '3rem',
        borderTopRightRadius: '3rem',
        maxHeight: '95%',
    },
    '& .MuiDialogActions-root': {
        padding: '1.2rem 0.6rem',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        marginBottom: 12,
    },
}));

const Content = styled('div')(() => ({
    overflowY: 'auto',
}));

const Puller = styled('div')(() => ({
    width: 30,
    height: 6,
    backgroundColor: grey[300],
    borderRadius: 3,
    margin: '1rem auto',
}));

function DrawerForm({
     children,
    width,
    backgroundcolor,
    borderradius,
    open,
    onClose,
 }) {

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={onClose}
            onOpen={() => null}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={true}
            borderradius
        >
            <Puller />
            <Content>
                {children}
            </Content>
        </SwipeableDrawer>
    );
}

export default DrawerForm;