import styled from "styled-components";
import { VisibilitySharp } from "@material-ui/icons";
import PlaceIcon from '@mui/icons-material/LocationOnRounded';
import { Avatar, Button, Grid, Typography } from "@mui/material";
import {THEME_COLORS } from "../common/colors";
import Link from "@material-ui/core/Link";
import { Header18 } from "../common/Typography";
import Paper from "@mui/material/Paper";

export const PublicViewIcon = styled(VisibilitySharp)`
  fill: gray !important;
`;

export const LocationIcon = styled(PlaceIcon)`
  fill: #717273 !important;
  margin-top: -8px !important;
`;

export const SidePanel = styled(Grid)`
  padding: 0 36px !important;
  padding-bottom: 48px !important;
  background-color: #F4F5F4 !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: stretch !important;
`;

export const AssetList = styled.div`
  margin: 0 auto;
  margin-bottom: 12px;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 96%;
  p {
    font-size: 0.7rem !important;
  }

  img {
    width: 75px;
    height: 75px;
  }
`;

export const MiddlePanel = styled(Grid)`
  padding: 0 36px !important;
`;

export const ActionMenu = styled.div`
  display: flex;
  color: ${THEME_COLORS.primary};
`;

export const ProfilePicture = styled(Avatar)`
  margin: 0 auto !important;
  margin-bottom: 24px !important;
  width: 100% !important;
  min-height: 250px !important;
  height: 19vw !important;
  border-radius: 20px !important;
`;

export const Tag = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin: 4px;
  min-width: 100px;
  background-color: #FAFAFA;
`;

export const Field = styled.div`
  padding: 8px;
  margin: 4px 0;
  width: ${({ ismobile })=> ismobile === 'true' ? 100 : 50}%;
  background-color: #f4f5f4;
`;


export const PanelSection = styled.div`
  margin-bottom: 24px;
`;


export const UserDisplayName = styled(Header18)`
  display: inline-block;
  margin-right: 10px !important;
  color: #4E5152 !important;
  line-height: 16px;
`;

export const PanelHeader = styled(Typography)`
  font-weight: bold !important;
  text-align: center !important;
  font-size: 1.15rem !important;
  color: #4E5152 !important;
`;

export const MinimalLink = styled(Link)`
  margin: 0 2px !important;
  color: inherit;
  text-decoration: unset !important;
  align-self: center;
`;


export const UserCategoryList = styled(Typography)`
  color: ${THEME_COLORS.primary} !important;
`;

export const Location = styled(Typography)`
  display: block !important;
`;

export const ActionButtons = styled.div`
  max-width: 250px;
  margin-bottom: 24px;
  display: flex;
`;

export const TruncateButton = styled(Button)`
  text-transform: capitalize !important;
  color: ${THEME_COLORS.primary} !important;
  justify-content: flex-end !important;
  font-size: 1rem !important;
  font-weight: 300 !important;
  svg {
    fill: ${THEME_COLORS.primary} !important;
    font-size: 1rem !important;
  }
  padding-right: 0 !important;
  float: right !important;
  &:hover {
    background-color: unset !important;
  }
  span:first-child {
    margin-right: 0 !important;
  }
`;

export const Thumbnail = styled(Paper)`
  margin: 8px auto !important;
  width: 75px !important;
  box-shadow: unset !important;
  position: relative !important;
`;