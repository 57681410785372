import { styled as styledMUI } from "@mui/material/styles";
import { Button } from "@mui/material";
import { THEME_COLORS } from "../common/colors";

const PrimaryButton = styledMUI(Button)(() => ({
    color: '#fff',
    textTransform: 'uppercase',
    fontWeight: '700',
    fontSize: '14px',
    backgroundColor: THEME_COLORS.primary,
    borderRadius: '32px',
    width: '190px',
    boxShadow: 'unset',
    '&:hover': {
        backgroundColor: THEME_COLORS.primaryHover,
    },
}));

export default PrimaryButton;