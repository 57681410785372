import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import {validation} from "./validation";
import { capitalizeFirstLetter } from "../common/utils/capitalizeFirstLetter";

function useSignUpForm(callback) {
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    firstname: '',
    surname: '',
    username: '',
    password: '',
    password2: '',
    hasAgreed: '',
  });

  const [errors, setErrors] = useState({
    firstname: '',
    surname: '',
    username: '',
    password: '',
    password2: '',
    hasAgreed: '',
  });

  const { firstname, surname, username, password } = inputs;
  const handleSubmit = event => {
    event?.preventDefault();
    const validations = validation();
    const errorFields = Object.entries(inputs).reduce((err, entries) => {
      err[entries[0]] = validations[entries[0]](entries, inputs);
      return err;
    }, {});
    setErrors(errorFields);
    if (event && !Object.values(errorFields).some(v => v)) {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_PUBLIC_API_URL}/users`,
          {
            firstname: capitalizeFirstLetter(firstname),
            surname: capitalizeFirstLetter(surname),
            username: username.toLowerCase(),
            password,
            email: username.toLowerCase(),
          },
          {
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          },
        )
        .then(({ data }) => {
          if (data.created === true) {
            setLoading(false);
            callback(true);
          } else {
            data.errors.forEach(error => {
              setLoading(false);
              toast.error(error);
            });
          }
        });
    }
  };
  const handleInputChange = event => {
    event.persist();
    const newInput = {
      ...inputs,
      [event.target.name]: event.target.value,
    };
    setInputs(newInput);
  };

  const handleCheckboxChange = event => {
    event.persist();
    const newInput = {
      ...inputs,
      [event.target.name]: event.target.checked,
    };
    setInputs(newInput);
  }

  return {
    handleCheckboxChange,
    handleInputChange,
    handleSubmit,
    inputs,
    loading,
    errors,
  };
}

export default useSignUpForm;
