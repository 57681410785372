import styled from 'styled-components';
import HomeLogo from "./HomeLogo";
import React from "react";

const BackgroundImage = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: ${({ bg }) => `url(${bg})`};
  background-size: cover;
  background-position: center;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    ${({ $imgPosition }) => $imgPosition === 'left' &&
     `"Image Content"`
    }
    ${({ $imgPosition }) => $imgPosition === 'right' &&
     `"Content Image"`
    }
     ;
`;

const Image = styled.div`
  grid-area: Image;
`;

const Logo = styled.div`
  grid-area: Logo;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
`;

const Form = styled.div`
  grid-area: Form;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: stretch;
`;

const Content = styled.div`
  grid-area: Content;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  width: 80%;
  min-width: 485px;
  ${({ condensed }) => condensed && 'max-width: 475px;'}
  grid-template-areas:
  ${({ $logoPosition }) => $logoPosition === 'left' && `
     "Logo .    .   "
     "Form Form Form"
     "Form Form Form"
     "Form Form Form"
     "Form Form Form"
     "Form Form Form"
  `}
  ${({ $logoPosition }) => $logoPosition === 'right' && `
     ".    .    Logo"
     "Form Form Form"
     "Form Form Form"
     "Form Form Form"
     "Form Form Form"
     "Form Form Form"
  `}
;
  padding: 0 4rem;
`;

export function SplitImageLayoutForm({ condensed, children, image, imagePosition = 'left', $logoPosition = 'left' }) {
    return (
        <Container $imgPosition={imagePosition}>
            <Image>
                <BackgroundImage bg={image} />
            </Image>
            <Content $logoPosition={$logoPosition} condensed={condensed}>
                <Logo>
                    <HomeLogo noLink />
                </Logo>
                <Form>
                    <div style={{ width: '100%' }}>
                        {children}
                    </div>
                </Form>
            </Content>
        </Container>
    );
}