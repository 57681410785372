import React, {useState} from "react";
import PrimaryButton from "../../../common/PrimaryButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import styled from "styled-components";
import {Avatar, Box, Grid, Typography} from "@mui/material";
import PlaceIcon from "@mui/icons-material/LocationOnRounded";
import Link from "@material-ui/core/Link";
import IconButton from "@mui/material/IconButton";
import {useHistory} from "react-router-dom";
import {styled as muiStyled} from "@mui/material/styles";
import {ContentSection} from "../../../common/Mobile/ContentSection";

const FollowerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FollowSection = muiStyled(ContentSection)(() => ({
    margin: '1rem',
    '.MuiCardContent-root': {
        display: 'flex !important',
        justifyContent: 'space-between !important',
        paddingBottom: '16px !important',
    }
}));

const UserActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UserDetails = styled.div`
  ${({ isMobile }) => !isMobile && 'margin: 1.5rem 0 !important;'}
  display: flex;
  align-items: center;
`;

const Location = styled(Typography)`
  font-size: 0.7rem !important;
  margin-top: 4px !important;
  margin-bottom: 8px !important;
  display: flex !important;
  align-items: center !important;
`;

const Username = styled(Typography)`
  font-weight: 700 !important;
`;

const LocationIcon = styled(PlaceIcon)`
  fill: #717273 !important;
  font-size: 1rem !important;
`;

const UserDescription = styled(Grid)`
  color: #717273;
`;

const MinimalLink = styled(Link)`
  color: #4e5152 !important;
  text-decoration: unset !important;
`;

const UserProfile = styled(Avatar)`
  width: 54px !important;
  height: 54px !important;
  margin-right: 1rem !important;
`;

const MenuContainer = styled(IconButton)`
  padding: 0;
`;

function getMenuActions(history, userUuid, userId, alreadyFollows, onFollow, onUnfollow) {
    return [{
        label: 'View profile',
        action: () => history.push(`/profile/${userUuid}`),
    }, {
        label: alreadyFollows ? 'Unfollow' : 'Follow',
        action: alreadyFollows ? onUnfollow: onFollow,
    }, {
        label: 'Help',
        action: () => history.push('/contact-us'),
    }]
}

export function FollowerContent({ isCurrentUser, user, isFollowing, follow, unfollow, isMobile, onOpenMenuAction }) {
    const history = useHistory();
    const [alreadyFollows, setAlreadyFollows] = useState(isFollowing);
    const Component = isMobile ? FollowSection : FollowerContainer;
    const onFollow = () => {
        follow(user.id, () => setAlreadyFollows(true));
    }

    const onUnfollow = () => {
        unfollow(user.id, () => setAlreadyFollows(false));
    }

    return (
        <Component>
            <MinimalLink href={`/profile/${user.uuid}`}>
                <UserDetails isMobile={isMobile}>
                    <UserProfile alt="user-profile-picture" src={user.profilepicture} />
                    <UserDescription>
                        <Username variant="body1">by {user.firstname} {user.surname}</Username>
                        <Location>
                            <LocationIcon />
                            {user.displaylocation || 'No Location'}
                        </Location>
                    </UserDescription>
                </UserDetails>
            </MinimalLink>
            {
                !isCurrentUser && !isMobile &&
                <UserActions>
                    {!alreadyFollows &&
                        <PrimaryButton
                            onClick={onFollow}
                        >
                            Follow
                        </PrimaryButton>
                    }
                    {alreadyFollows &&
                        <PrimaryButton
                            onClick={onUnfollow}
                        >
                            Unfollow
                        </PrimaryButton>
                    }
                </UserActions>
            }
            {
                !isCurrentUser && isMobile && onOpenMenuAction &&
                <MenuContainer
                    onClick={() => onOpenMenuAction(getMenuActions(history, user.uuid, user.id, alreadyFollows, onFollow, onUnfollow))}
                    type="button"
                    aria-label="active-menu-action"
                >
                    <MoreVertIcon />
                </MenuContainer>
            }
        </Component>
    );
}