import { styled as styledMUI } from "@mui/material/styles";
import { Button } from "@mui/material";
import { THEME_COLORS } from "./colors";

const PrimaryButton = styledMUI(Button)(({ expanded }) => ({
    color: 'white',
    textTransform: 'capitalize',
    fontWeight: '400',
    backgroundColor: THEME_COLORS.primary,
    borderRadius: 16,
    width: expanded ? '100%' : '50%',
    boxShadow: 'unset',
    '&:hover': {
        backgroundColor: THEME_COLORS.primaryHover,
    },
    '&.condensed': {
        minWidth: 75,
        width: 'unset',
        lineHeight: 'unset',
    }
}));

export default PrimaryButton;