import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { SecondaryButton } from "./SecondaryButton";
import useSignUpForm from "./useSignup";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { styled as muiStyled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";

const errorStyling = { color: '#D42F2F' };

const TermsAndConditionPrivacyPolicyContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 4px;
`;

const CustomCheckbox = muiStyled(Checkbox)(() => ({
    '&': {
        padding: 0,
        paddingRight: 6,
    },
}));

const PasswordIcon = styled(IconButton)`
  position: absolute !important;
  right: 24px !important;
  bottom: 0 !important;
`

const MinimalLink = styled(Link)`
  color: #4e5152 !important;
  text-decoration: unset !important;
  align-self: center;
`;

const TextInput = styled(TextField)`
  margin: 4px 0;
`;

function ErrorFieldMessage({ message, isVisible }) {
    return (
        isVisible ?
            <div>
                <Typography component="p" sx={{...errorStyling, fontSize: '0.75rem'}}>
                    {message}
                </Typography>
            </div> :
            null
    )
}

export function SignupForm() {
    const signup = success => {
        if (success) {
            window.location = '/login?message=signup_success';
        }
    };

    const { errors, inputs, handleCheckboxChange, handleInputChange, handleSubmit } = useSignUpForm(signup);
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div>
            <form>
                <TextInput
                    error={!!errors.firstname}
                    helperText={errors.firstname}
                    fullWidth
                    margin="normal"
                    htmlFor="firstname"
                    type="text"
                    name="firstname"
                    onChange={handleInputChange}
                    value={inputs.firstname}
                    id="firstname"
                    label="First name"
                    variant="standard"
                />
                <TextInput
                    error={!!errors.surname}
                    helperText={errors.surname}
                    fullWidth
                    margin="normal"
                    htmlFor="surname"
                    type="text"
                    name="surname"
                    onChange={handleInputChange}
                    value={inputs.surname}
                    id="surname"
                    label="Last name"
                    variant="standard"
                />
                <TextInput
                    error={!!errors.username}
                    helperText={errors.username}
                    fullWidth
                    margin="normal"
                    htmlFor="username"
                    type="text"
                    name="username"
                    onChange={handleInputChange}
                    value={inputs.username}
                    id="username"
                    label="Email"
                    variant="standard"
                />
                <TextInput
                    error={!!errors.password}
                    helperText={errors.password}
                    fullWidth
                    margin="normal"
                    htmlFor="password"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    onChange={handleInputChange}
                    value={inputs.password}
                    id="password"
                    label="Password"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <PasswordIcon
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                onMouseDown={() => setShowPassword(!showPassword)}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </PasswordIcon>
                        )
                    }}
                />
                <TextInput
                    error={!!errors.password2}
                    helperText={errors.password2}
                    fullWidth
                    margin="normal"
                    htmlFor="password2"
                    type={showPassword ? 'text' : 'password'}
                    name="password2"
                    onChange={handleInputChange}
                    value={inputs.password2}
                    id="password2"
                    label="Re-enter password"
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <PasswordIcon
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                onMouseDown={() => setShowPassword(!showPassword)}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </PasswordIcon>
                        )
                    }}
                />
                <TermsAndConditionPrivacyPolicyContainer>
                    <CustomCheckbox
                        size="small"
                        sx={errors.hasAgreed ? errorStyling : {}}
                        value={inputs.hasAgreed}
                        name="hasAgreed"
                        onChange={handleCheckboxChange}
                    />
                    <Typography component="span" variant="body2">
                        By signing up, I have read and agree to TheSwapp's{' '}
                        <MinimalLink href="/terms-and-conditions" target="_blank" variant="body2">
                            <b>Terms & conditions, and Privacy Policy</b>
                        </MinimalLink>
                    </Typography>
                    <ErrorFieldMessage message={errors.hasAgreed} isVisible={errors.hasAgreed}/>
                </TermsAndConditionPrivacyPolicyContainer>
                <SecondaryButton onClick={handleSubmit}>Continue</SecondaryButton>
            </form>
            <MinimalLink href="/login" variant="body1">
                Already have an account? <b>Sign In</b>
            </MinimalLink>
        </div>
    );
}