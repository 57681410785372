import React from 'react';
import DialogForm from "../DialogForm";
import { ImageCropForm } from "./ImageCropForm";
import { DialogContent } from "@material-ui/core";

const ImageCrop = ({
   loading,
   setShowModal,
   imageToSave,
   fileInputRef,
   setImage,
   onClose,
   filename,
   aspectRatio,
   isOpen,
   sendCroppedImageServer,
   isMobile,
}) => {
    return (
        <DialogForm
            label="Crop your image"
            description=""
            isOpen={isOpen}
            isMobile={isMobile}
            onClose={onClose}
        >
            <DialogContent>
                <ImageCropForm
                    loading={loading}
                    setShowModal={setShowModal}
                    imageToSave={imageToSave}
                    fileInputRef={fileInputRef}
                    setImage={setImage}
                    filename={filename}
                    isMobile={isMobile}
                    aspectRatio={aspectRatio}
                    sendCroppedImageServer={sendCroppedImageServer}
                />
            </DialogContent>
        </DialogForm>
    );
};

export default ImageCrop;
