import moment from "moment";

const required = (message) => ([field, value]) => {
    return !value?.toString().trim() ? (message || `${field} is required.`) : '';
}

const requiredNumeral = (message) => ([field, value]) => {
    return isNaN(value) || !value ? (message || `${field} is required.`) : '';
}

const requiredSubField = (message, name) => ([field, value]) => {
    return !value || !value[name] ? (message || `${field} is required.`) : '';
}

const shouldBeAfterTomorrow = (message) => ([field, value]) => {
    if (!value || !moment(value).isValid()) {
        return 'The completion date is required';
    }
    return moment().format('YYYYMMDD') >=
    moment(value).format('YYYYMMDD') ? (message || `${field} has to be after the next day.`) : '';
}


const allOf = (validations) => (value) => {
    let err = '';
    validations.every(validation => {
        err = validation(value);
        return !err;
    });
    return err;
}

export function validation() {
    return ({
        asset: requiredSubField('An asset is required.', 'id'),
        description: required('A description is required.'),
        dateOfCompletion: shouldBeAfterTomorrow('The completion date has to be after the next day.'),
        estimatedCost: required('Estimated cost is required.'),
    })
}