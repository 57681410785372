
const required = (message) => ([field, value]) => {
    return !value.toString().trim() ? (message || `${field} is required.`) : '';
}

const requiredNumeral = (message) => ([field, value]) => {
    return isNaN(value) || !value ? (message || `${field} is required.`) : '';
}

const maxLength = (maxLength, message) => ([field, value]) => {
    return value && value.length > maxLength ? (message || `${field} is .`) : '';
}

const requiredList = (message) => ([field, value]) => {
    return !value || value.length === 0 ? (message || `${field} is required.`) : '';
}

const allOf = (validations) => (formData) => {
    let errorMessage = '';
    validations.some(validation => {
        errorMessage = validation(formData);
        console.log('errorMessage', errorMessage)
        return !!errorMessage;
    });
    console.log('errorMessage', errorMessage)
    return errorMessage;
}
export function validation() {
    return ({
        title: required('Title is required.'),
        description: allOf([
            required('Description is required.'),
            maxLength(500, 'Description must be 500 characters or less.')
        ]),
        condition: requiredNumeral('Condition is required.'),
        type: required('Category type is required.'),
        category: required('Category is required.'),
        mostLikelySwapp: requiredList('Most likely to swapp for is required.'),
        mainImage: required('At least one image must be uploaded.'),
        deliveryMethods: requiredList('At least one delivery method is required.'),
    })
}