import { useContext, useState } from "react";
import { AuthContext } from "../app/AuthContext";
import axios from "axios";
import {genericSuccess, genericUpdateError} from "../toaster/utils";

const PREDEFINED_CREATE_REVIEW_MESSAGE = ' has submitted a review.';

const PREDEFINED_UPDATE_REVIEW_MESSAGE = ' has updated the review.';

export default function useEditReview(onReviewSubmission, currentUser) {
    const { authContext } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);


    const updateReview = (formData) => {
        setIsLoading(true);
        axios
            .put(`${process.env.REACT_APP_PUBLIC_API_URL}/reviews/${formData.id}`,
                formData,
                {
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'x-access-token': authContext.token,
                    },
                })
            .then(response => {
                const message = '[PREDEFINED]' + currentUser.firstName + PREDEFINED_UPDATE_REVIEW_MESSAGE;
                genericSuccess('You successfully edited your review.')
                onReviewSubmission(message);
                setIsLoading(false);
            })
            .catch(error => {
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
                genericUpdateError(`Failed to update your review: ${error}`);
                setIsLoading(false);
            });
    };

    const createReview = (formData) => {
        setIsLoading(true);
        axios
            .post(`${process.env.REACT_APP_PUBLIC_API_URL}/reviews`,
                formData,
                {
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'x-access-token': authContext.token,
                    },
                })
            .then(response => {
                const message = '[PREDEFINED]' + currentUser.firstName + PREDEFINED_CREATE_REVIEW_MESSAGE;
                genericSuccess('You successfully posted your review.')
                onReviewSubmission(message);
                setIsLoading(false);
            })
            .catch(error => {
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
                genericUpdateError(`Failed to post your review: ${error}`);
                setIsLoading(false);
            });
    };

    return { createReview, updateReview, isLoading }
}