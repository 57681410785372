import React from 'react';
import styled from 'styled-components';


const BackgroundImage = styled.div`
  position: relative;
  width: 100%;
  overflow-y: hidden;
  height: 100vh; // Adjust as needed
  background-image: ${({ background }) => `url(${background})`};
  background-size: cover;
  background-position: center;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: rgba(0, 0, 0, 0.5); // Semi-transparent overlay
  display: flex;
  align-items: center; // Vertically center
  padding: 3px; // Padding on the left side
`;


export function OverlayComponent(props) {
  const { background, children } = props;
  return (
    <BackgroundImage background={background}>
      <Overlay>{children}</Overlay>
    </BackgroundImage>
  );
}
