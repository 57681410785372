import React, { useState } from "react";
import useGetCategories from "../../common/hooks/useGetCategories";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import { DELIVERY_OPTIONS } from "../../common/constants";
import { FILTERED_CONDITION_OPTIONS } from "../constants";
import { Ratings } from "../../common/RatingDisplays";
import styled from "styled-components";
import {Button, Typography} from "@mui/material";
import { THEME_COLORS } from "../../common/colors";
import FormCheckbox from "../../common/Form/FormCheckbox";

const FilterContainer = styled.div`
  margin-bottom: 24px;
`;

const Container = styled.div`
  padding-left: 4rem;
  min-width: 230px
;
`;

const FilterLabel = styled(Typography)`
  font-size: 0.775rem !important;
  display: inline-flex !important;
  align-items: center !important;
`;

const FilterHeader = styled(Typography)`
  font-size: 1rem !important;
  font-weight: 800 !important;
  margin-bottom: 0.7rem !important;
`;

const FilterCheckbox = styled(FormCheckbox)`
  padding: 0 !important;
  padding-right: 4px !important;
`;

const FilterSection = styled.div`
  height: 178px;
  overflow-y: auto;
`;

const TruncateButton = styled(Button)`
  text-transform: capitalize !important;
  color: ${THEME_COLORS.primary} !important;
  justify-content: flex-end !important;
  font-size: 0.83rem !important;
  font-weight: 300 !important;
  svg {
    fill: ${THEME_COLORS.primary} !important;
    font-size: 0.83rem !important;
  }
  padding-right: 0 !important;
  float: right !important;
  &:hover {
    background-color: unset !important;
  }
  span:first-child {
    margin-right: 0 !important;
  }
`;

const CategoryFilter = ({ onFilterChange, values, name }) => {
    const { categories, loading: loadingCategories } = useGetCategories(true);
    const [showAll, setShowAll] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState(values);
    const categoryFilters = showAll ? categories : categories.slice(0, 9);

    const onChange = (e) => {
        e.preventDefault();
        const newCategoryFilter = new Set(selectedCategories);
        if (e.target.checked) {
            newCategoryFilter.add(e.target.value);
        } else {
            newCategoryFilter.delete(e.target.value);
        }
        setSelectedCategories([...newCategoryFilter]);
        onFilterChange({
            [name]: [...newCategoryFilter],
        })
    }

    return (
        <FilterContainer>
            <FilterHeader variant="body1">
                Categories
            </FilterHeader>
            <FilterSection>
                {
                    categoryFilters.map((category) => (
                        <div key={`category-filter-${category.value}`}>
                            <FilterCheckbox
                                size="small"
                                value={category.value}
                                checked={values.includes(category.value)}
                                onClick={onChange}
                            />
                            <FilterLabel variant="body2">
                                {category.label}
                            </FilterLabel>
                        </div>
                    ))
                }
            </FilterSection>
            {categories?.length > 9 && <TruncateButton
                size="small"
                onClick={() => setShowAll(!showAll)}
                startIcon={showAll ? <RemoveIcon/> : <AddIcon/>}
            >
                {showAll ? 'Less' : 'More'}
            </TruncateButton>}
        </FilterContainer>
    );
}

const DeliveryMethodFilter = ({ onFilterChange, values, name }) => {
    const [selectedDeliveryMethods, setSelectedDeliveryMethods] = useState(values);
    const onChange = (e) => {
        e.preventDefault();
        const newDeliveryMethodFilter = new Set(selectedDeliveryMethods);
        if (e.target.checked) {
            newDeliveryMethodFilter.add(e.target.value);
        } else {
            newDeliveryMethodFilter.delete(e.target.value);
        }
        setSelectedDeliveryMethods([...newDeliveryMethodFilter]);
        onFilterChange({
            [name]: [...newDeliveryMethodFilter],
        })
    }

    return (
        <FilterContainer>
            <FilterHeader variant="body1">
                Delivery Methods
            </FilterHeader>
            <div>
                {
                    DELIVERY_OPTIONS.map((deliveryMethod) => (
                        <div key={`delivery-method-filter-${deliveryMethod.value}`}>
                            <FilterCheckbox
                                size="small"
                                value={deliveryMethod.value}
                                checked={values.includes(deliveryMethod.value)}
                                onClick={onChange}
                            />
                            <FilterLabel variant="body2">
                                {deliveryMethod.label}
                            </FilterLabel>
                        </div>
                    ))
                }
            </div>
        </FilterContainer>
    );
}

const ConditionFilter = ({ onFilterChange, values, name }) => {
    const [selectedConditions, setSelectedConditions] = useState(values);

    const onChange = (e) => {
        e.preventDefault();
        const newConditionFilter = new Set(selectedConditions);
        if (e.target.checked) {
            newConditionFilter.add(e.target.value);
        } else {
            newConditionFilter.delete(e.target.value);
        }
        setSelectedConditions([...newConditionFilter]);
        onFilterChange({
            [name]: [...newConditionFilter],
        })
    }

    return (
        <FilterContainer>
            <FilterHeader variant="body1">
                Condition or Expertise
            </FilterHeader>
            <div>
                {
                    FILTERED_CONDITION_OPTIONS.map((level) => (
                        <div key={`level-filter-${level.value}`} style={{ display: 'flex', alignItems: 'center' }}>
                            <FilterCheckbox
                                size="small"
                                value={level.value}
                                checked={values.includes(level.value.toString())}
                                onClick={onChange}
                            />
                            <FilterLabel variant="body2">
                                {level.label}
                            </FilterLabel>
                        </div>
                    ))
                }
            </div>
        </FilterContainer>
    );
}
export function LeftPanelFilters({ filters, onFilterChange }) {
    return(
        <Container>
            <CategoryFilter
                onFilterChange={onFilterChange}
                values={filters.category}
                name="category"
            />
            <DeliveryMethodFilter
                onFilterChange={onFilterChange}
                values={filters.delivery_method}
                name="delivery_method"
            />
            <ConditionFilter
                onFilterChange={onFilterChange}
                values={filters.level}
                name="level"
            />
        </Container>
    );
}