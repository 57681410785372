import { styled as styledMUI } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

export const TextFormField = styledMUI(TextField)(({ isMobile, noLeftPadding }) => ({
    width: '100%',
    '::before': {
        border: 'unset !important',
    },
    '.MuiInputBase-input': {
        padding: isMobile ? '6px' : '12px',
        ...(noLeftPadding ? { paddingLeft: 0 } : {}),
    },
    '::after': {
        border: 'unset !important',
    },
    '.MuiInputBase-multiline': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        ...(isMobile ? { borderRadius: 4} : {}),
        padding: '2px',
    },
    '.MuiInputBase-root': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        ...(isMobile ? { borderRadius: 4} : {}),
        marginBottom: '0.7rem',
    }
}));