import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter";

function fromApi(data) {
    return ({
        id: data.id,
        uuid: data.uuid,
        activeassetcount: Number(data.activeassetcount || 0),
        assetcount: Number(data.assetcount || 0),
        userinvitationkey: data.userinvitationkey,
        firstname: capitalizeFirstLetter(data.firstname ?? ''),
        surname: capitalizeFirstLetter(data.surname ?? ''),
        username: data.username ?? '',
        email: data.email ?? '',
        description: data.description ?? '',
        validated: data.validated,
        suspended: data.suspended,
        gender: data.gender,
        sexualorientation: data.sexualorientation || [],
        interests: data.interests || [],
        images: data.images || [],
        profilepicture: data.profilepicture || '',
        location: data.location || [],
        notificationmail: data.notificationmail,
        notificationpush: data.notificationpush,
        lastvisit: data.lastvisit,
        popularityrate: data.popularityrate,
        birthdate: data.birthdate,
        pronouns: data.pronouns || [],
        ethnicity: data.ethnicity || [],
        topcategories: data.topcategories || [],
        profilevalues:  data.profilevalues || [],
        phone: data.phone || '',
        displaylocation: data.displaylocation ?? '',
        show_savings: !!data.show_savings,
        top_my_asset_categories: data.top_my_asset_categories || [],
        top_my_assets: data.top_my_assets || [],
    });
}

export { fromApi };
