import axios from 'axios';
import {useContext, useState} from 'react';
import {AuthContext} from "../app/AuthContext";
import {genericUpdateError} from "../toaster/utils";

function useContactSwappMember(history, setContactUsForm) {
    const [isLoading, setIsLoading] = useState(false);
    const { authContext } = useContext(AuthContext);
    const { token } = authContext;
    const sendMessage = (contactUsForm) => {

        setIsLoading(true);
        axios
            .post(`${process.env.REACT_APP_PUBLIC_API_URL}/report/contactUs`,
                contactUsForm,
                {
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'x-access-token': token,
                    },
                })
            .then(({ data }) => {
                setIsLoading(false);
                setContactUsForm({
                    ...contactUsForm,
                    message: '',
                });
                if (data.success) {
                    contactUsSuccessful();
                }
            })
            .catch(error => {
                setIsLoading(false);
                genericUpdateError(`Failed to send your message: ${error}`);
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
            });
    };

    return { isLoading, sendMessage }
};

export default useContactSwappMember;