import styled from "styled-components";
import { Subheader14 } from "../../common/Typography";
import { Typography } from "@mui/material";
import { THEME_COLORS } from "../../common/colors";

export const Editable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const NoDataYet = styled(Typography).attrs({
    variant: 'body1',
})`
  color: ${THEME_COLORS.primary};
  margin-top: 4px;
`;
export const TagDisplay = styled.div`
  margin-bottom: 3rem;
`;

export const Tag = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin: 4px;
  min-width: 100px;
  background-color: #FAFAFA;
`;

export const SubHeader = styled(Subheader14)`
  font-weight: bold !important;
  color: #4E5152 !important;
  line-height: 14px;
`;