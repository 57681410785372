import moment from "moment";

export function fromApi(data) {
    return ({
        id: data.id,
        postType: data.post_type,
        parentCommentId: data.parent_comment_id,
        description: data.description,
        createdAt: moment(data.created_at).format('MMM DD, YYYY'),
        postUuid: data.post_uuid,
        likesCount: data.likes_count,
        likeId: data.user_like,
        user: {
            profilePicture: data.profilepicture,
            firstName: data.firstname,
            lastName: data.surname,
        },
    });
}