import React from "react";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {styled as styledMUI} from "@mui/material/styles";
import styled from "styled-components";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import {THEME_COLORS} from "../colors";
import Checkbox from "@mui/material/Checkbox";
import {ListItemText} from "@mui/material";
import TextField from "@mui/material/TextField";
import {TextFormField} from "./TextFormField";

const filter = createFilterOptions();

const AutocompleteField = styledMUI(Autocomplete)(() => ({
    width: '100%',
    '::before': {
        border: 'unset !important',
    },
    '.MuiInputBase-input': {
        padding: '12px',
    },
    '::after': {
        border: 'unset !important',
    },
    '. MuiAutocomplete-inputRoot': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        marginBottom: '0.7rem',
    }
}));

const FormField = styled(TextFormField)`
  .MuiInputBase-root {
    margin-bottom: 0.1rem !important;
  } 
  .Mui-error {
    margin-left: 0 !important;
  }
  .MuiFilledInput-root {
    padding: 4px !important;
    padding-right: 2rem !important;
  }
`

const MultiSelectMenuItem = styled(MenuItem)`
  .Mui-selected {
    background-color: ${THEME_COLORS.primary}50 !important;
  }

  span.Mui-checked {
    color: ${THEME_COLORS.primary} !important;
  }
`;

export default function MultipleAutocompleteSelect({ values, onChange, options, ...rest }) {
    return (
        <AutocompleteField
            {...rest}
            multiple
            disableCloseOnSelect
            defaultValue={values}
            getOptionLabel={(option) => option.label}
            size="small"
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    onChange(newValue);
                } else if (newValue && newValue.inputValue) {
                    onChange(newValue);
                } else {
                    onChange(newValue);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.title);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        value: inputValue,
                        label: `Add #${inputValue}`,
                    });
                }

                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={options}

            renderOption={(props, option, { selected }) => (
                <MultiSelectMenuItem key={option.value} {...props}>
                    {!(option.label ?? '').includes('Add #') &&
                        <Checkbox checked={selected} />
                    }
                    <ListItemText primary={option.label} />
                </MultiSelectMenuItem>
            )}
            renderInput={(params) => (
                <FormField
                    variant="filled"
                    color="warning"
                    {...params}
                />
            )}
        />
    );
}