import React from 'react';
import {Avatar, Grid, Typography, styled as muiStyled, Box} from "@mui/material";
import styled from "styled-components";
import moment from "moment";
import Badge from "@mui/material/Badge";
import {THEME_COLORS} from "../../common/colors";
import { ContentSection } from "../../common/Mobile/ContentSection";

const UserDetails = styled(Typography)`
  font-size: 0.88rem !important;
  font-weight: 500;
`;

const MessageDetails = styled(Typography)`
  font-size: 0.7rem !important;
  max-width: 80%;
`;


const MessageDetailsContainer = styled.div`
  margin-left: 12px;
  color: #717273;
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

const ChatHeader = styled.div`
  margin-left: 12px;
  color: #717273;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


const ChatDetails = styled(Grid)`
  display: flex !important;
  max-height: 75px !important;
  cursor: pointer !important;
  margin-bottom: 1.5rem !important;
`;

function displayMessageDateTime(date) {
    if (moment(date).isSame(moment(), 'day')) {
        return moment(date).format('hh:mma');
    }
    return moment(date).format('MMM DD');
}

const UnreadBadge = muiStyled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: THEME_COLORS.primary,
        transform: 'unset',
        position: 'relative',
    },
}));

const Section = muiStyled(ContentSection)(() => ({
    margin: '1rem',
    '.MuiCardContent-root': {
        paddingBottom: '16px !important',
    }
}));

function truncateMessage(msg, length) {
    return msg.length > length ? `${msg.slice(0, length)}[...]` : msg;
}

function ChatPreview({ chatInfo, onClick, isMobile }) {
    const Component = isMobile ? Section : Box;

    return (
        <Component>
            <ChatDetails item xs={12} sm={12} lg={12} onClick={() => onClick(chatInfo.serialId)}>
                <Avatar alt="user-profile-picture" src={chatInfo.user.profilePicture} />
                <div style={{ width: '100%'}}>
                    <ChatHeader>
                        <UserDetails variant="h6">{chatInfo.user.firstName} {chatInfo.user.lastName}</UserDetails>
                        <MessageDetails>{displayMessageDateTime(chatInfo.latestMessage.sentAt)}</MessageDetails>
                    </ChatHeader>
                    <MessageDetailsContainer>
                        <MessageDetails>
                            {truncateMessage(chatInfo.latestMessage.content, isMobile ? 70 : 45)}
                        </MessageDetails>
                        {
                            !!chatInfo.unreadMessageCount &&
                            <UnreadBadge badgeContent={chatInfo.unreadMessageCount} color="error"/>
                        }
                    </MessageDetailsContainer>
                </div>
            </ChatDetails>
        </Component>
    );
}

export default ChatPreview;