import React from "react";
import { Typography } from "@mui/material";

export function ErrorFieldMessage({ message, isVisible, offsetMargin = true }) {
    return (
        isVisible ?
            <div>
                <Typography
                    component="p"
                    sx={{
                        color: '#D42F2F',
                        fontSize: '0.75rem !important',
                        marginLeft: `${offsetMargin ? 1.5 : 0}rem !important`,
                        marginTop: `${offsetMargin ? -1.5 : 0}rem !important`,
                        marginBottom: '1.5rem !important',
                    }}
                >
                    {message}
                </Typography>
            </div> :
            null
    )
}