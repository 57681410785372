import * as ContractUser from "./contractUser";

export function fromApi(data) {
    return ({
        id: data.id,
        contractId: data.contract_id,
        user_id: data.user_id,
        user_uuid: data.user_uuid,
        modifiedAt: data.created_at,
        likeId: data.user_like,
        likesCount: data.likes_count,
        commentsCount: data.comments_count,
        requesterUserContract: ContractUser.fromApi(data.requester_user_contract[0]),
        requestedUserContract: ContractUser.fromApi(data.requested_user_contract[0]),
    });
}