import * as React from 'react';
import styled from "styled-components";
import { styled as muiStyled } from '@mui/material/styles';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import PrimaryButton from "./PrimaryButton";
import { Body10, Header16 } from "./Typography";
import { THEME_COLORS } from "./colors";
import SecondaryGrayButton from "./SecondaryGrayButton";
import MobileDrawerForm from "./DrawerForm";

const CloseButton = styled(SecondaryGrayButton)`
    margin-right: 4px !important;
`;

const ButtonGroup = styled(DialogActions)`
    max-width: 370px !important;
    margin-bottom: 0 !important;
`;

const StyledDialog = muiStyled(MuiDialog)(({ theme, backgroundcolor, width, maxWidth }) => ({
    '& .MuiPaper-root': {
        borderRadius: '36px',
        border: '4px solid #D9D9D9',
        overflowY: 'hidden',
        width,
        maxWidth,
    },
    '& .MuiDialogContent-root': {
        maxHeight: 568,
        minHeight: 200,
        backgroundColor: backgroundcolor === 'subdued' ? '#F4F5F4' : '#FFFFFF',
        border: 'unset',
    },
    '& .MuiDialogTitle-root': {
        textAlign: 'center',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        marginBottom: 12,
        width: '60%',
    },
}));

const Label = styled(Header16)`
  margin-bottom: 0.5rem !important;
  text-align: center !important;
`;

const MobileHeader = styled.div`
  margin: 0 1rem;
`;

const Description = styled(Body10)`
  color: ${THEME_COLORS.primary};
  text-align: center;
  margin-bottom: 1rem;
`;

export default function DialogForm({
   children,
   disabled,
   theme,
   radius,
   buttonLabel,
   closeLabel,
   isOpen,
   hideCloseButton,
   width,
   maxWidth,
   label,
   description,
   onClose,
   onSecondaryAction,
   onSave,
   isMobile,
}) {

    if (isMobile) {
        return (
            <MobileDrawerForm
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={!!isOpen || isOpen === undefined}
                width={width ?? 540}
                backgroundcolor={theme}
                borderradius={radius}
            >
                {label &&
                    <MobileHeader>
                        <Label id="customized-dialog-title">
                            {label}
                        </Label>
                        {
                            description &&
                            <Description>
                                {description}
                            </Description>
                        }
                    </MobileHeader>
                }
                {children}
                {onSave &&
                    <ButtonGroup>
                        {!hideCloseButton && onClose &&
                            <CloseButton onClick={onClose}>
                                {closeLabel || 'Done'}
                            </CloseButton>
                        }
                        <PrimaryButton autoFocus disabled={disabled} onClick={onSave}>
                            {buttonLabel || 'Done'}
                        </PrimaryButton>
                    </ButtonGroup>
                }
            </MobileDrawerForm>
        );
    }
    return (
        <StyledDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={!!isOpen || isOpen === undefined}
            width={width ?? 540}
            maxWidth={maxWidth ?? 700}
            backgroundcolor={theme}
            borderradius={radius}
        >
            {label &&
                <>
                    <Label id="customized-dialog-title">
                        {label}
                    </Label>
                    {
                        description &&
                        <Description>
                            {description}
                        </Description>
                    }
                </>
            }
            {children}
            {onSave &&
                <ButtonGroup>
                    {!hideCloseButton && onClose &&
                        <CloseButton onClick={onSecondaryAction ?? onClose}>
                            {closeLabel || 'Done'}
                        </CloseButton>
                    }
                    <PrimaryButton autoFocus disabled={disabled} onClick={onSave}>
                        {buttonLabel || 'Done'}
                    </PrimaryButton>
                </ButtonGroup>
            }
        </StyledDialog>
    );
}