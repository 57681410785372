import * as React from 'react';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/SearchRounded';
import styled from "styled-components";
import {useState} from "react";
import {Typography} from "@mui/material";

const InputContainer = styled.div`
  border-radius: 24px;
  border: 1px gray solid;
  padding: 2px 16px;
  background-color: #fff;
`;

const SearchInput = styled(InputBase)`
  font-size: 12px !important;
  width: 85% !important;
`;

const SearchButton = styled(IconButton)`
  float: right !important;
  padding: 0 !important;
  right: -8px !important;
  top: 3px !important;
`;

const SearchResults = styled(Typography)`
  margin: 12px !important;
`;

export default function Searchbar({ onSearch, showResults }) {
    const [searchQuery, setSearchQuery] = useState('');
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            onSearch(searchQuery.trim());
        }
    };

    return (
        <div>
            <InputContainer>
                <SearchInput
                    placeholder="Search"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    inputProps={{ 'aria-label': 'search my assets' }}
                    size="small"
                    onKeyDown={handleKeyPress}
                />
                <SearchButton
                    onClick={() => onSearch(searchQuery.trim())}
                    type="button"
                    aria-label="search"
                >
                    <SearchIcon fontSize="small" />
                </SearchButton>
            </InputContainer>
            {showResults && searchQuery && <SearchResults variant="body2" gutterBottom>
                Search results for <b>"{searchQuery}"</b>
            </SearchResults>}
        </div>
    );
}