import React from 'react';
import Lottie from 'react-lottie';
import NoData from '../../assets/lotties/no-data.json';
import styled from "styled-components";
import {Box} from "@mui/material";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Details = styled(Box)`
  position: relative !important;
  top: -6rem !important;
  width: ${({ size }) => (size ?? 200) + 140}px !important;
  max-width: calc(100vw - 90px) !important;
`;

const Message = styled.p`
  font-size: ${({ fontsize }) => fontsize ?? 1.2}rem !important;
  margin-bottom: 1rem;
`;

export function NoDataContainer({ message, actionButton , size, fontSize }) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: NoData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <Container>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Lottie
                    options={defaultOptions}
                    height={size ?? 200}
                    width={size ?? 200}
                />
                <Details size={size}>
                    <Message fontsize={fontSize} >{message}</Message>
                    {actionButton}
                </Details>
            </Box>
        </Container>
    );
}