import axios from 'axios';
import { useContext, useState } from 'react';
import { AuthContext } from "../../app/AuthContext";
import { validation } from "./validation";
import {genericSuccess, genericUpdateError} from "../../toaster/utils";

function useUpdateContract(setOpenContractModal, onUpdateSwapContract) {
    const { authContext } = useContext(AuthContext);
    const [loaded, setLoaded] = useState(false);
    const [errors, setErrors] = useState({
        description: '',
        estimatedCost: '',
        dateOfCompletion: '',
        asset: '',
    });

    const updateContract = (formData, message) => {
        const validations = validation();
        const errorFields = Object
            .entries(formData)
            .filter(([key]) => Object.keys(errors).includes(key))
            .reduce((err, entries) => {
            err[entries[0]] = validations[entries[0]](entries);
            return err;
        }, {});
        setErrors(errorFields);
        if (!Object.values(errorFields).some(v => v)) {
            axios
                .put(`${process.env.REACT_APP_PUBLIC_API_URL}/contracts/user/${formData.id}`,
                    formData,
                    {
                        headers: {
                            'Content-type': 'application/json; charset=UTF-8',
                            'x-access-token': authContext.token,
                        },
                    })
                .then(response => {
                    onUpdateSwapContract(message)
                    setLoaded(true);
                    setOpenContractModal(false);
                    genericSuccess('You successfully edited your swapp agreement.')
                    return response.data;
                })
                .catch(error => {
                    if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
                    genericUpdateError(`Failed to update your swapp agreement: ${error}`);
                });
        }
    };

    return { updateContract, errors };
};

export default useUpdateContract;
