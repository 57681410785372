import React from "react";
import { Avatar, Grid, Typography } from "@mui/material";
import SecondaryGrayButton from "../../common/SecondaryGrayButton";
import styled from "styled-components";
import PlaceIcon from '@mui/icons-material/LocationOnRounded';
import Link from "@material-ui/core/Link";

const UserDetails = styled.div`
  margin: 1.5rem 0 !important;
  display: flex;
  align-items: center;
`;

const Location = styled(Typography)`
  font-size: 0.7rem !important;
  margin-top: 4px !important;
  margin-bottom: 8px !important;
  display: flex !important;
  align-items: center !important;
`;

const Username = styled(Typography)`
  font-size: 0.94rem !important;
  font-weight: 700 !important;
`;

const LocationIcon = styled(PlaceIcon)`
  fill: #717273 !important;
  font-size: 1rem !important;
`;

const UserDescription = styled(Grid)`
  color: #717273;
`;

const MinimalLink = styled(Link)`
  color: #4e5152 !important;
  text-decoration: unset !important;
`;

const FollowButton = styled(SecondaryGrayButton)`
  padding: 2px !important;
`;

const UserProfile = styled(Avatar)`
  width: 54px !important;
  height: 54px !important;
  margin-right: 1rem !important;
`;

export function SuggestFollowUser({ user, follow, unfollow, isFollowingList }) {
    return (
        <UserDetails>
            <UserProfile alt="user-profile-picture" src={user.profilepicture} />
            <UserDescription>
                <MinimalLink href={`/profile/${user.uuid}`}>
                    <Username variant="body1">{user.firstname} {user.surname}</Username>
                </MinimalLink>
                <Location>
                    <LocationIcon fontSize="small" />
                    {user.displaylocation || 'No Location'}
                </Location>
                {!isFollowingList.includes(user.id) &&
                    <FollowButton
                        onClick={() => follow(user.id)}
                    >
                        Follow
                    </FollowButton>
                }
                {isFollowingList.includes(user.id) &&
                    <FollowButton
                        onClick={() => unfollow(user.id)}
                    >
                        Unfollow
                    </FollowButton>
                }
            </UserDescription>
        </UserDetails>
    )
}