import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import useForgotPasswordForm from './forgotpassword-container';
import {THEME_COLORS} from "../common/colors";
import styled from "styled-components";
import PrimaryButton from "../common/PrimaryButton";
import {FormInput} from "../common/Form/FormInput";

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: THEME_COLORS.primary,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const MinimalLink = styled(Link)`
  color: #4e5152 !important;
  text-decoration: unset !important;
  align-self: center;
`;

const ResetButton = styled(PrimaryButton)`
  width: 80%;
  margin: 0 auto;
`;


const ForgotPassword = () => {
  const forgotPassword = success => {
    if (success) {
      window.location = '/?message=forgotPassword_success';
    }
  };
  const { inputs, handleInputChange, handleSubmit, loading } = useForgotPasswordForm(
    forgotPassword,
  );
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot your password?
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <FormInput
              htmlFor="email"
              type="email"
              name="email"
              color="warning"
              style={{ width: '100%' }}
              onChange={handleInputChange}
              value={inputs.email}
              id="email"
              required
              label="Email"
          />
          <ResetButton
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submit}
            disabled={loading}
          >
            Reset password
          </ResetButton>
          <Grid container>
            <Grid item xs>
              <MinimalLink href="/login" variant="body2">
                Login
              </MinimalLink>
            </Grid>
            <Grid item>
              <MinimalLink href="/signup" variant="body2">
                Don't have an account? Sign Up
              </MinimalLink>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default ForgotPassword;
