import React from "react";
import styled from "styled-components";
import { FollowUserHeader } from "../../follow/components/FollowUserHeader";
import { ContentSection } from "../../common/Mobile/ContentSection";
import { PostActionBar } from "./PostActionBar";
import { Comments } from "../comments";
import Placeholder from "../../../assets/images/placeholderImage.png";
import {ImageCarousel} from "../../common/ImageCarousel";

const AssetImage = styled.div`
  max-width: 100%;
  max-height: 600px;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    flex-shrink: 0;
    width: 115%;
    min-height: 100%;
  }
`;

const PostContainer = styled.div`
  border-bottom: 1px solid #F4F5F4;
`;

export function AssetPost({ currentUser, follow, unfollow, isFollowing, post, isMobile, onOpenMenuAction, onShowComments }) {
    const Component = isMobile ? ContentSection : PostContainer;
    return (
        <Component>
            <FollowUserHeader
                post={post}
                currentUser={currentUser}
                user={post.user}
                follow={follow}
                unfollow={unfollow}
                isFollowing={isFollowing}
                title={post.title}
                isMobile={isMobile}
                onOpenMenuAction={onOpenMenuAction}
            />
            <div style={{ margin: '1.5rem 0 0.5rem 0' }}>
                <ImageCarousel
                    animation="slide"
                    autoPlay={false}
                    indicators={post.images.length > 1}
                >
                    {
                        post.images.map((img, i) => {
                            return (
                                <AssetImage>
                                    <img src={img || Placeholder} alt={`${post.title} image ${i + 1}`} />
                                </AssetImage>
                            );
                        })
                    }
                </ImageCarousel>
            </div>
            <PostActionBar
                postId={post.id}
                type="ASSET"
                onShowComments={onShowComments}
                currentLikeNum={post.likesCount}
                description={post.description}
                userFirstname={post.user.firstName}
                likeId={post.likeId}
                assetUuid={post.assetUuid}
                postMainImage={post.images[0]}
                assetTitle={post.title}
                isMobile={isMobile}
            />
            <Comments
                isMobile={isMobile}
                type="ASSET"
                postId={post.id}
                commentInitNum={post.commentsCount}
                onShowComments={onShowComments}
            />
        </Component>
    );
}