const PRODUCT_DELIVERY_OPTIONS = [
    {
        label: 'Digital',
        value: 'digital',
        type: 'PRODUCT',
    },
    {
        label: 'Local pick up',
        value: 'localPickup',
        type: 'PRODUCT',
    },
    {
        label: 'Local delivery',
        value: 'localDelivery',
        type: 'PRODUCT',
    },
    {
        label: 'Shipping',
        value: 'shipping',
        type: 'PRODUCT',
    },
]

const SERVICE_DELIVERY_OPTIONS = [
    {
        label: 'In-person',
        value: 'inPerson',
        type: 'SERVICE',
    },
    {
        label: 'Virtual',
        value: 'virtual',
        type: 'SERVICE',
    },
]

const DELIVERY_OPTIONS = [...PRODUCT_DELIVERY_OPTIONS, ...SERVICE_DELIVERY_OPTIONS];

export { DELIVERY_OPTIONS, PRODUCT_DELIVERY_OPTIONS, SERVICE_DELIVERY_OPTIONS }