import styled from "styled-components";
import { CommentForm } from "./CommentForm";
import { Body12 } from "../../common/Typography";
import {useGetCommentsForPost} from "./useGetCommentsForPost";
import {useCallback, useState} from "react";

const CommentFormContainer = styled.div`
    margin-top: 0.8rem;
`;

const MinimalLink = styled.div`
  color: #4e5152 !important;
  cursor: pointer;
  margin-bottom: 0.5rem;
`;

export function Comments({ type, isMobile, postId, parentCommentId, commentInitNum, onShowComments }) {
    const [commentNum, setCommentNum] = useState(commentInitNum ?? 0);
    const handlePostComments = useCallback(() => {
        setCommentNum(commentNum + 1);
    }, [commentNum]);

    return (
        <CommentFormContainer>
            { !!commentNum && !!onShowComments &&
                <MinimalLink onClick={onShowComments}>
                    <Body12>View {commentNum > 1 ? 'all ': ''}{commentNum ?? 0} comment{commentNum > 1 ? 's': ''}</Body12>
                </MinimalLink>
            }
            <CommentForm
                postId={postId}
                postType={type}
                isMobile={isMobile}
                getComments={handlePostComments}
                parentCommentId={parentCommentId}
            />
        </CommentFormContainer>
    )
}