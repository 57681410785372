import axios from 'axios';
import { useState } from 'react';

function useFetchInvitationKey(token) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userId, setUserId] = useState();

    if (token && !userId) {
        axios
            .get(`${process.env.REACT_APP_PUBLIC_API_URL}/validation/newinvitation/${token}`, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
            .then(({ data }) => {
                if (!data.success) {
                    window.location = '/signup/?message=invalid_invite_token';
                }
                setUserId(data[0]);
                setIsLoaded(true);
            })
            .catch(error => {
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
                window.location = '/signup/?message=invalid_invite_token';
            });
    }

    return { isLoaded, userId }
};

export default useFetchInvitationKey;
