import { useContext, useState } from "react";
import { AuthContext } from "../app/AuthContext";
import axios from "axios";
import { followSuccess } from "../toaster/utils";

export function useFollow(callback) {
    const [loading, setLoading] = useState(false);
    const { authContext } = useContext(AuthContext);
    const { token } = authContext;

    const follow = (id, innerCallback) => {
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_PUBLIC_API_URL}/follow/followUser`, {
                userId: id,
            },{
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'x-access-token': token,
                },
            })
            .then((response) => {
                if (response.data.created) {
                    followSuccess('followed');
                    callback && callback(id, true);
                    innerCallback && innerCallback();
                }
                setLoading(false);
            })
            .catch(error => {
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
                setLoading(false);
            });
    };

    const unfollow = (id, innerCallback) => {
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_PUBLIC_API_URL}/follow/unfollowUser`, {
                userId: id,
            },{
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'x-access-token': token,
                },
            })
            .then((response) => {
                if (response.data.deleted) {
                    followSuccess('unfollowed');
                    callback && callback(response.data, false);
                    innerCallback && innerCallback();
                }
                setLoading(false);
            })
            .catch(error => {
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
                setLoading(false);
            });
    };

    return { follow, unfollow, loading }
}