import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter";

export function fromApi(data) {
    return ({
        userReviewAverage: data?.user_review_average ?? 0,
        userReviewTotal: data?.user_review_total ?? 0,
        assetReviewAverage: data?.asset_review_average ?? 0,
        assetReviewTotal: data?.asset_review_total ?? 0,
        reviews: data?.reviews?.map(review => ({
            id: review?.id,
            userId: review?.user_id,
            assetId: review?.asset_id,
            contractId: review?.contract_id,
            userRating: review?.user_rating,
            assetRating: review?.asset_rating,
            reviewText: review?.review_text,
            createdAt: review?.created_at,
            reviewer: {
                id: review?.reviewer_id,
                firstName: capitalizeFirstLetter(review?.reviewer_first_name),
                surname: capitalizeFirstLetter(review?.reviewer_surname),
                profilePicture: review?.reviewer_profile_picture,
            },
        })) ?? [],
        userContracts: (data?.user_contract ?? [])
            .map((contract) => ({
                id: contract?.id,
                contractId: contract?.contract_id,
                userId: contract?.user_id,
                assetTitle: contract?.asset_title,
                assetId: contract?.asset_id,
                assetMainImage: contract?.asset_main_image,
                assetQuantity: contract?.asset_quantity,
            })),
        reviewerContracts: (data?.reviewer_contract ?? [])
            .map((contract) => ({
                id: contract?.id,
                contractId: contract?.contract_id,
                userId: contract?.user_id,
                assetTitle: contract?.asset_title,
                assetId: contract?.asset_id,
                assetMainImage: contract?.asset_main_image,
                assetQuantity: contract?.asset_quantity,
            })),
    });
}