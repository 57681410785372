const FILTERED_CONDITION_OPTIONS = [
    {
        label: 'For Parts or Novice',
        value: 1,
    },
    {
        label: 'Acceptable or Intermediate',
        value: 2,
    },
    {
        label: 'Good or Proficient',
        value: 3,
    },
    {
        label: 'Like-New or Advanced',
        value: 4,
    },
    {
        label: 'Brand-New or Expert',
        value: 5,
    },
]

export { FILTERED_CONDITION_OPTIONS };
