import * as React from 'react';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";
import { ListItemText } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { THEME_COLORS } from "../colors";
import { styled as styledMUI } from "@mui/material/styles";


const SelectField = styledMUI(Select)(() => ({
    width: '100%',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    '&.MuiInputBase-root::before': {
        border: 'unset !important',
    },
    '.MuiSelect-select': {
        padding: '8px',
        paddingLeft: '12px',
    },
    '&.MuiInputBase-root::after': {
        border: 'unset !important',
    },
    '.MuiInputBase-root': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        marginBottom: '0.7rem',
    }
}));

const SelectChip = styled(Chip)`
  border-radius: 0 !important;
  padding: 0 !important;
  height: 24px !important;
`;

const SelectedValueContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const MultiSelectMenuItem = styled(MenuItem)`
  &.Mui-selected {
    background-color: ${THEME_COLORS.primary}50 !important;
  }

  span.Mui-checked {
    color: ${THEME_COLORS.primary} !important;
  }
`;

export default function MultipleSelect({ values, onChange, options, prefix = '', ...rest }) {
    return (
        <SelectField
            {...rest}
            multiple
            value={values}
            onChange={onChange}
            renderValue={(selected) => (
                <SelectedValueContainer>
                    {
                        options
                        .filter((option) => selected.includes(option.value))
                        .map((option) => (
                            <SelectChip key={option.value} label={`${prefix}${option.label}`} />
                        ))
                    }
                </SelectedValueContainer>
            )}
        >
            {options.map((option) => (
                <MultiSelectMenuItem key={option.value} value={option.value}>
                    <Checkbox checked={values.indexOf(option.value) > -1} />
                    <ListItemText primary={option.label} />
                </MultiSelectMenuItem>
            ))}
        </SelectField>
    );
}