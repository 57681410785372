import React from "react";
import styled from 'styled-components';
import HomeLogo from "../../common/HomeLogo";
import { NavBar } from "../../common/NavBar";
import { Header16, Body10 } from "../../common/Typography";

const Container = styled.div`
  display: grid;
  grid-template-rows: 0.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  min-height: 100vh;
  width: 100%;
  grid-template-areas:
     "Header"
     "Form"
     "Form"
     "Form"
     "Form"
     "Form"
     "Form";
`;

const Header = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.7fr 1.3fr;
  ${({ type }) => type === 'newAccount' && `
    grid-template-columns: 1.1fr 0.9fr 1fr 0.7fr 1.3fr;
  `}
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: "Logo Navigation Navigation Navigation RightHeader";
`;

const HeaderContainer = styled.div`
  grid-area: Header;
  display: flex;
  align-items: flex-end;
  min-height: 100px;
`;

const Logo = styled.div`
  grid-area: Logo;
  display: flex;
  align-items: flex-end;
  padding-left: ${({ type }) =>  type === 'newAccount' ? 5 : 2 }rem;
  ${({ type }) =>  type !== 'newAccount' && 'background-color: #F4F5F4;'}
`;

const Navigation = styled.div`
  grid-area: Navigation;
  display: flex;
  align-items: flex-end;
  justify-content: stretch;
  padding: 0 7%;
  ${({ type }) =>  type === 'newAccount' && 'padding: 0 9%;'}
`;

const RightHeader = styled.div`
  grid-area: RightHeader;
  text-align: center;
  display: flex;
  align-items: flex-end;
  background-color: #F4F5F4;
`;

const Form = styled.div`
  grid-area: Form;
  width: 100%;
  display: grid;
  height: 100%;
  margin-bottom: 100px;
  grid-template-columns: 1fr 1fr 1fr 0.7fr 1.3fr;
  ${({ type }) => type === 'newAccount' && `
    grid-template-columns: 1.1fr 0.9fr 1fr 0.7fr 1.3fr;
  `}
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: "LeftPanel MiddlePanel MiddlePanel MiddlePanel RightPanel";
`;

const Section = styled.div`
  min-height: 50px;
  width: 100%;
  ${({ type }) =>  type === 'view' && `
    margin: 0 auto;
    max-width: 640px;
  `}
`;

const Subheader = styled.div`
  width: 80%;
  text-align: center;
  margin: 0 auto;
`;

export function ProfileLayout({ rightPanelHeader, type, children }) {
    return (
        <Container>
            <HeaderContainer>
            <Header type={type}>
                    <Logo type={type}>
                <Section>
                        <HomeLogo position="absolute" />
                </Section>
                    </Logo>
                    <Navigation type={type}>
                     <Section type={type}>
                        <NavBar condense={type === 'newAccount'} />
                     </Section>
                    </Navigation>
                    <RightHeader>
                <Section>
                        <Header16>
                            {rightPanelHeader}
                        </Header16>
                    {
                        type === 'editAccount' &&
                        <Subheader>
                            <Body10>You deserve to do what you love most</Body10>
                        </Subheader>
                    }
                </Section>
                    </RightHeader>
            </Header>
                </HeaderContainer>
            <Form type={type}>
                { children }
            </Form>
        </Container>
    );
}