export function autoCopy(shareUrl, setCopySuccess) {
    navigator.permissions.query({ name: "clipboard-write" })
        .then(({state}) => {
            if (state === "granted") {
                const data = [new ClipboardItem({ "text/plain": new Blob([shareUrl], { type: "text/plain" }) })];
                navigator.clipboard.write(data).then(
                    () => {
                        setCopySuccess(true);
                    });
            }
        });
}