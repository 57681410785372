import moment from "moment/moment";
import {capitalizeFirstLetter} from "../utils/capitalizeFirstLetter";

function findLatestMessage(messages) {
    return messages.reduce((latest, message) => {
        if (!latest) return message;
        return moment(message.sent_at).isAfter(moment(latest.sent_at)) ? message : latest;
    }, null);
}

function getMessagePreview(message) {
    let msg = message;
    if (msg.includes('[PREDEFINED]')) {
        msg = message.replace('[PREDEFINED]', '');
    }

    return msg;
}
function fromApi(data = { other_user: {}, messages: [] }) {
    const latestMessage = findLatestMessage(data.messages);
    return ({
        id: data.id,
        serialId: data.serial_id,
        unreadMessageCount: Number(data.unread_messages ?? 0),
        latestMessage: {
            id: latestMessage.id,
            attachments: latestMessage.attachments,
            content: getMessagePreview(latestMessage.message),
            readAt: latestMessage.read_at,
            recipientId: latestMessage.recipient_id,
            senderId: latestMessage.sender_id,
            sentAt: latestMessage.sent_at,
        },
        user: {
            id: data.other_user[0].id,
            uuid: data.other_user[0].uuid,
            profilePicture: data.other_user[0].profilepicture ?? '',
            firstName: capitalizeFirstLetter(data.other_user[0].firstname ?? ''),
            lastName: capitalizeFirstLetter(data.other_user[0].surname ?? ''),
            displayLocation: data.other_user[0].displaylocation ?? '',
        }
    });
}

export { fromApi };