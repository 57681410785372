import styled from "styled-components";
import Popper from "@mui/material/Popper";
import Backdrop from '@mui/material/Backdrop';

const Pop = styled(Popper)`
`;

export function Popover({ open, keepOpen, onClose, children, ...rest }) {
    return (
        <div>
            <Backdrop
                open={open}
                onClick={onClose}
                invisible={true}
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer,
                }}
            />
            <Pop
                open={open}
                sx={{
                    backgroundColor: 'transparent',
                    zIndex: (theme) => theme.zIndex.drawer + 5,
                }}
                disablePortal={true}
                {...rest}
            >
                <div onClick={keepOpen}>
                    {children}
                </div>
            </Pop>
        </div>
    );
}