import React from "react";
import Button from "@mui/material/Button";
import styled from 'styled-components';

const SecondaryBtn = styled(Button)`
   text-transform: unset !important;
   box-shadow: unset !important;
   border-radius: unset !important;
   font-weight: bold !important;
   font-size: 1.25rem !important;
   margin: 22px 0 !important;
   color: #fff !important;
   padding: 4px !important;
   background-color: #A7ADAF !important;
`;

export function SecondaryButton({ children, loading, onClick }) {
    return (
        <SecondaryBtn
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={onClick}
            fullWidth
        >
            { children }
        </SecondaryBtn>
    );
}