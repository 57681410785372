import React from "react";
import ImageIcon from "@mui/icons-material/PhotoCamera";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";

const ACCEPTED_IMAGE_FORMATS = '.jpg, .jpeg, .png, .gif, .bmp, .tiff, .webp';

const EditImageIconContainer = styled.label`
  position: absolute;
  right: 8px;
`;

const EditImageIcon = styled(IconButton)`
  position: absolute !important;
  right: -4px !important;
  bottom: 28px !important;
  background-color: #ffffff70 !important;
`;

export function EditImageButton({ fileInputRef, onClick }) {
    const handleIconClick = () => {
        fileInputRef.current.click();
    };

    return (
        <EditImageIconContainer htmlFor="fileInput" onClick={handleIconClick}>
            <EditImageIcon>
                <ImageIcon />
            </EditImageIcon>
            <input
                type="file"
                id="fileInput"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={onClick}
                accept={ACCEPTED_IMAGE_FORMATS}
            />
        </EditImageIconContainer>
    );
}