import styled from "styled-components";
import Carousel from "react-material-ui-carousel";
import { THEME_COLORS } from "./colors";

export const StyledCarousel = styled(Carousel)`
  .indicatorButton {
    svg {
      fill: #afafaf !important;
    }
  }
  
  .navButtons {
    top: 50% !important;
    background-color: #49494920;
  }
  
  &.ksuana {
    height: 100%;
  }

  .activeIndicatorButton {
    svg {
      fill: ${THEME_COLORS.primary} !important;
    }
  }
`;

export function ImageCarousel({ children, ...rest }) {
    return (
        <StyledCarousel
            navButtonsProps={{
                className: 'navButtons',
            }}
            indicatorIconButtonProps={{
                className: 'indicatorButton',
            }}
            swipe={false}
            activeIndicatorIconButtonProps={{
                className: 'activeIndicatorButton',
            }}
            {...rest}
        >
            {children}
        </StyledCarousel>
    )
}