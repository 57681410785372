import styled from "styled-components";
import { Subheader14 } from "../../common/Typography";

export const Editable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const TagDisplay = styled.div`
  margin-bottom: 3rem;
`;

export const Tag = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  background-color: #FAFAFA;
  padding: ${({ isMobile }) => isMobile ? '4px 12px' : '8px 24px'};
  ${({ isMobile }) => isMobile && 'font-size: 0.8rem;' }
`;

export const SubHeader = styled(Subheader14)`
  font-weight: bold !important;
  color: #4E5152 !important;
  line-height: 14px;
`;