import axios from 'axios';
import _ from 'lodash';
import {useContext, useState} from 'react';
import {fromApi} from "../../common/models/asset";
import {AuthContext} from "../../app/AuthContext";

function useFetchUserAssets(userData = {}) {
  const { authContext } = useContext(AuthContext);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userAssets, setUserAssets] = useState([]);
  const fetchAssets = (search) => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API_URL}/assets/user/${userData.uuid}${search ? `?search=${search}` : ''}`, {
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'x-access-token': authContext.token,
        },
      })
      .then(response => {
        setUserAssets(response.data.map(fromApi));
        setLoaded(true);
        setLoading(false);
        return response.data;
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        setLoaded(true);
        setLoading(false);
      });
  };

  if (userData.uuid && _.isEmpty(userAssets) && !loading && !loaded) {
    setLoading(true);
    fetchAssets();
  }

  return { userAssets, loaded, fetchAssets };
};

export default useFetchUserAssets;
