import {useState, useContext, useCallback, useEffect} from 'react';
import _ from 'lodash';
import { AuthContext } from '../../app/AuthContext';
import moment from "moment";
import { fromApi } from '../../common/models/message';

function useChatRoom(chatDetails, currentUserId, otherUserId, onUpdateMessageRead) {
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const { socketContext } = useContext(AuthContext);
    const [chatroomInfo, setChatroomInfo] = useState({});
    const [message, setMessage] = useState('');

    useEffect(() => {
        socketContext.socket.on('chat message', (msg) => {
            const key = moment().format('YYYYMMDD');
            if (msg.id) {
                setChatroomInfo({
                    ...chatroomInfo,
                    messages: {
                        ...chatroomInfo.messages,
                        [key]: [...(chatroomInfo.messages[key] ?? []), fromApi(msg)]
                    },
                });
            }
        });
    }, [chatroomInfo, setChatroomInfo]);


    socketContext.socket.on('redirect', msg => {
        window.location = `/?message=${msg}`;
    });

    const handleMessage = (e) => {
        setMessage(e.target.value)
    }

    const sendMessage = (message) => {
        socketContext.socket.emit('chat message', message, chatDetails.id, chatDetails.serialId, otherUserId);
    }

    const sendChatMessage = useCallback(() => {
        if (message !== '') {
            sendMessage(message)
            setMessage('');
        }
    }, [message, setMessage]);

    if (_.isEmpty(chatroomInfo) && chatDetails.id) {
        setChatroomInfo(chatDetails);
        socketContext.socket.emit('joinchatroom', chatDetails.id, chatDetails.serialId, otherUserId);
    }

    return {
        chatroomInfo,
        loaded,
        sendChatMessage,
        sendMessage,
        handleMessage,
        message,
    };
};

export default useChatRoom;
