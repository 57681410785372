import React, { useState } from "react";
import styled from "styled-components";
import { TextFormField } from "../../common/Form/TextFormField";
import { Button, InputAdornment } from "@mui/material";
import { styled as styledMUI } from "@mui/material/styles";
import { THEME_COLORS } from "../../common/colors";
import {usePostComment} from "./usePostComment";

const CommentFormContainer = styled.div`
    ${ ({ isMobile }) => isMobile && `margin-bottom: -2rem;` }
    ${ ({ isMobile }) => !isMobile && `margin-top: -8px;` }
`;

export const PostButton = styledMUI(Button)(() => ({
    color: THEME_COLORS.primary,
    fontWeight: 'bold',
    fontSize: '0.83rem',
    textTransform: 'capitalize',
    paddingRight: 0,
    justifyContent: 'flex-end',
}));

export function CommentForm({ postId, postType, parentCommentId, isMobile, getComments }) {
    const [message, setMessage] = useState('');
    const { createComment } = usePostComment(setMessage, getComments);
    const handleChangeComment = (e) => {
        setMessage(e.target.value);
    }

    const handlePostComment = () => {
        createComment(postId, postType, parentCommentId, message);
    }

    return (
        <CommentFormContainer isMobile={isMobile}>
            <TextFormField
                color="warning"
                id={`form-comment-${postId}`}
                variant="standard"
                placeholder="Add a comment..."
                noLeftPadding={true}
                onChange={handleChangeComment}
                value={message}
                InputProps={{
                    endAdornment: (
                        message ?
                            <InputAdornment position="end">
                                <PostButton onClick={handlePostComment}>
                                Post
                                </PostButton>
                            </InputAdornment> :
                            undefined
                    )
                }}

            />
        </CommentFormContainer>
    );
}