import { useContext, useState } from "react";
import { AuthContext } from "../../app/AuthContext";
import axios from "axios";
import { validation } from "../validation";
import {fromApi} from "../../common/models/asset";
import {genericUpdateError, updateAssetSuccessful} from "../../toaster/utils";

const INITIAL_ERROR_STATE = {
    title: '',
    description: '',
    condition: '',
    category: '',
    mainImage: '',
    deliveryMethods: '',
}

export default function useEditAsset(onClose, refetchAssets) {
    const { authContext } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const validations = validation();

    const [errors, setErrors] = useState(INITIAL_ERROR_STATE);

    const resetErrors = () => {
        setErrors(INITIAL_ERROR_STATE);
    }

    const validateAsset = (inputs) => {
        const errorFields = Object
            .entries(inputs)
            .filter(([key]) => Object.keys(errors).includes(key))
            .reduce((err, entries) => {
            err[entries[0]] = validations[entries[0]](entries);
            return err;
        }, {});

        setErrors(errorFields);
        return !Object.values(errorFields).some(v => v);
    }

    const updateAsset = (formData, setAsset, closeOnSave, clearForm) => {
        if (validateAsset(formData)) {
            setLoading(true);
            axios
                .put(`${process.env.REACT_APP_PUBLIC_API_URL}/assets/${formData.id}`,
                    formData,
                    {
                        headers: {
                            'Content-type': 'application/json; charset=UTF-8',
                            'x-access-token': authContext.token,
                        },
                    })
                .then(response => {
                    setLoading(false);
                    updateAssetSuccessful('updated');
                    refetchAssets && refetchAssets();

                    if (clearForm) {
                        setAsset(fromApi({}));
                    }

                    if (closeOnSave) {
                        onClose();
                        resetErrors();
                    }
                })
                .catch(error => {
                    if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
                    genericUpdateError(`Failed to update your asset: ${error}`);
                    setLoading(false);
                });
        }
    };

    const deleteAsset = (id) => {
        setLoading(true);
        axios
            .put(`${process.env.REACT_APP_PUBLIC_API_URL}/assets/${id}/status`,
                {
                    id,
                    status: 'ARCHIVED',
                },
                {
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'x-access-token': authContext.token,
                    },
                })
            .then(response => {
                setLoading(false);
                updateAssetSuccessful('deleted');
                refetchAssets();
            })
            .catch(error => {
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
                genericUpdateError(`Failed to delete your asset: ${error}`);
                setLoading(false);
            });
    };

    const createAsset = (formData, setAsset, closeOnSave) => {
        if (validateAsset(formData)) {
            setLoading(true);
            axios
                .post(`${process.env.REACT_APP_PUBLIC_API_URL}/assets`,
                    formData,
                    {
                        headers: {
                            'Content-type': 'application/json; charset=UTF-8',
                            'x-access-token': authContext.token,
                        },
                    })
                .then(response => {
                    setLoading(false);
                    updateAssetSuccessful('created');
                    setAsset(fromApi({}));
                    refetchAssets();

                    if (closeOnSave) {
                        onClose();
                    }
                })
                .catch(error => {
                    if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
                    genericUpdateError(`Failed to create your asset: ${error}`);
                    setLoading(false);
                });
        }
    };

    return { createAsset, errors, resetErrors, updateAsset, deleteAsset, loading }
}