import React from "react";
import Placeholder from "../../assets/images/placeholderImage.png";
import styled from "styled-components";
import { Avatar } from "@mui/material";

const LeftPanel = styled.div`
  grid-area: LeftPanel;
  background-color: #F4F5F4;
  padding: 0 2rem;
  padding-bottom: 100px;
`;

const ProfilePicture = styled(Avatar)`
  margin: 32px auto !important;
  width: 100% !important;
  height: auto !important;
  border-radius: 20px !important;
`;

export function LeftSection({ user }) {
    return (
        <LeftPanel>
            <ProfilePicture
                variant="rounded"
                src={user.profilepicture || Placeholder}
            />
        </LeftPanel>
    );
}