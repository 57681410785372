import React, {useEffect, useRef, useState} from 'react';
import DialogForm from "../../common/DialogForm";
import TagOptionsForm from "../components/TagOptionsForm";
import { getCategoryOptions, PronounOptions, EthnicityOptions, ValueOptions } from "../constants";
import ImageCrop from "../../common/ImageCropModal/ImageCropModal";
import useFetchProfile from "../hooks/useFetchProfile";
import { readFile } from "../../common/utils";
import useEditProfile from '../hooks/useEditProfile';
import useFetchAssets from "../../assets/hooks/useFetchUserAssets";
import useGetCategories from "../../common/hooks/useGetCategories";
import { ProfileLayout } from "../components/ProfileLayout";
import { LeftFormSection } from "./LeftFormSection";
import { MiddleFormSection } from "./MiddleFormSection";
import { RightFormSection } from "./RightFormSection";
import useEditAsset from "../../assets/hooks/useEditAsset";
import {DialogContent} from "@material-ui/core";
import Searchbar from "../../common/Searchbar";
import {EditProfileMobile} from "./mobile";
import FORMAT_SIZES from "../../common/constants/FormatSizes";
import { AssetForm } from "../../assets/components/AssetForm";

const DEFAULT_DIALOG_WIDTH = 445;



export default function EditProfileForm({ autoOpenHowTo, isProfileCompleted, userInfo, format }) {
    const { categories } = useGetCategories();
    const fileInputRef = useRef(null);

    const [openModal, setOpenModal] = useState(null);
    const [imageToSave, setImageToSave] = useState(null);
    const [newTags, setNewTags] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState('');
    const [draftTimestamp, setDraftTimestamp] = useState(null);

    const { userInfo: user, setUserInfo } = useFetchProfile(JSON.parse(localStorage.getItem(`draft-profile-${userInfo.uuid}`)) || userInfo || {});

    const { userAssets, fetchAssets } = useFetchAssets(userInfo);

    useEffect(() => {
        if (!isProfileCompleted) {
            autoOpenHowTo();
        }
    }, []);

    const { loading, loadingImage, updateProfile, uploadFinalImage, errors } = useEditProfile(user, setUserInfo, setOpenModal, userAssets, setDraftTimestamp);
    const categoryOptions = getCategoryOptions(categories);

    const userLookingForList = categoryOptions.options
        .filter(({ value }) => user.topcategories.includes(value));

    const topMyAssetCategoriesList = categoryOptions.options
        .filter(({ value }) => user.top_my_asset_categories.includes(value));

    const [assetId, setAssetId] = useState(null);
    const onCloseAsset = ()=> {

        setOpenModal(null);
        setAssetId(null);
    };

    const { updateAsset, createAsset, loading: updating, resetErrors, errors: assetErrors } = useEditAsset(onCloseAsset, fetchAssets);

    const type = isProfileCompleted ? 'editAccount' : 'newAccount';

    const handleFieldsChange = (values) => {
        setUserInfo({
            ...user,
            ...values,
        });
    }

    const handleFieldChange = (field) => (e) => {
        handleFieldsChange({ [field]: e.target.value });
    }

    const handleToggleChange = (field) => (e) => {
        handleFieldsChange({ [field]: e.target.checked });
    }


    const handleSubmit = () => {
        updateProfile(user);
    }

    const handleTagUpdate = (field) => () => {
        handleFieldsChange({ [field]: newTags })
        setNewTags([]);
        setOpenModal(null);
    }

    const handleFileUpload = async event => {
        if (event.target.files[0]) {
            const imageDataUrl = await readFile(event.target.files[0]);
            setOpenModal('UPLOAD_PROFILE_IMAGE');
            setImageToSave(imageDataUrl);
        }
    };

    return (
        <>
            {
               format === FORMAT_SIZES.MOBILE &&
                <EditProfileMobile
                    type={type}
                    userLookingForList={userLookingForList}
                    topMyAssetCategoriesList={topMyAssetCategoriesList}
                    fileInputRef={fileInputRef}
                    handleFileUpload={handleFileUpload}
                    categories={categories}
                    errors={errors}
                    handleFieldChange={handleFieldChange}
                    handleFieldsChange={handleFieldsChange}
                    handleSubmit={handleSubmit}
                    user={user}
                    userAssets={userAssets}
                    saving={loading}
                    setOpenModal={setOpenModal}
                    setNewTags={setNewTags}
                    setAssetId={setAssetId}
                    openModal={openModal}
                    refetchAssets={fetchAssets}
                    onUserFieldChange={handleToggleChange}
                    loadingImage={loadingImage}
                    draftTimestamp={draftTimestamp}
                    setDraftTimestamp={setDraftTimestamp}
                />
            }
            {
                format !== FORMAT_SIZES.MOBILE &&
                <ProfileLayout
                    type={type}
                    rightPanelHeader={ type === 'newAccount' ? '' : 'My fav assets to Swapp' }
                >
                    <LeftFormSection
                        type={type}
                        user={user}
                        userLookingForList={userLookingForList}
                        topMyAssetCategoriesList={topMyAssetCategoriesList}
                        errors={errors}
                        fileInputRef={fileInputRef}
                        handleFileUpload={handleFileUpload}
                        setOpenModal={setOpenModal}
                        loadingImage={loadingImage}
                        onUserFieldChange={handleToggleChange}
                    />
                    <MiddleFormSection
                        type={type}
                        categories={categories}
                        errors={errors}
                        handleFieldChange={handleFieldChange}
                        handleFieldsChange={handleFieldsChange}
                        handleSubmit={handleSubmit}
                        user={user}
                        userAssets={userAssets}
                        saving={loading}
                        setOpenModal={setOpenModal}
                        setNewTags={setNewTags}
                        draftTimestamp={draftTimestamp}
                        setDraftTimestamp={setDraftTimestamp}
                    />
                    <RightFormSection
                        type={type}
                        userAssets={userAssets}
                        setOpenModal={setOpenModal}
                        setAssetId={setAssetId}
                        user={user}
                    />
                </ProfileLayout>
            }
            <DialogForm
                isMobile={format === FORMAT_SIZES.MOBILE}
                isOpen={['LOOKING_FOR_CATEGORIES', 'IN_RETURN_CATEGORIES'].includes(openModal)}
                label={openModal === 'LOOKING_FOR_CATEGORIES' ? "What are you looking for?" : "What can you exchange in return?"}
                closePosition="right"
                width={700}
                description={openModal === 'LOOKING_FOR_CATEGORIES' ? "Products, services or skills I want to get. Select as many as you want." : "Skills, products or services I am able to provide. Select as many as you want."}
                onSave={openModal === 'LOOKING_FOR_CATEGORIES' ? handleTagUpdate('topcategories') : handleTagUpdate('top_my_asset_categories')}
                onClose={()=> setOpenModal(null)}
                hideCloseButton={true}
            >
                <DialogContent>
                    <Searchbar onSearch={(value) => setFilteredCategories(value)} />
                    <TagOptionsForm
                        options={categoryOptions.options.filter((option) => option.tag.toLowerCase().includes(filteredCategories.toLowerCase()))}
                        currentValues={openModal === 'LOOKING_FOR_CATEGORIES' ? user.topcategories : user.top_my_asset_categories }
                        label={categoryOptions.label}
                        maxOptions={categoryOptions.maxCounter}
                        onChange={setNewTags}
                    />
                </DialogContent>
            </DialogForm>
            <DialogForm
                isMobile={format === FORMAT_SIZES.MOBILE}
                isOpen={openModal === 'ETHNICITIES'}
                label="What's your ethnicity?"
                closePosition="right"
                description=""
                onSave={handleTagUpdate('ethnicity')}
                onClose={()=> setOpenModal(null)}
                hideCloseButton={true}
            >
                <DialogContent>
                    <TagOptionsForm
                        options={EthnicityOptions.options}
                        currentValues={user.ethnicity}
                        label={EthnicityOptions.label}
                        maxOptions={EthnicityOptions.maxCounter}
                        onChange={setNewTags}
                    />
                </DialogContent>
            </DialogForm>
            <DialogForm
                isMobile={format === FORMAT_SIZES.MOBILE}
                isOpen={openModal === 'PRONOUNS'}
                label="What are your pronouns?"
                closePosition="right"
                description=""
                width={DEFAULT_DIALOG_WIDTH}
                onSave={handleTagUpdate('pronouns')}
                onClose={()=> setOpenModal(null)}
                hideCloseButton={true}
            >
                <DialogContent>
                    <TagOptionsForm
                        options={PronounOptions.options}
                        currentValues={user.pronouns}
                        label={PronounOptions.label}
                        maxOptions={PronounOptions.maxCounter}
                        onChange={setNewTags}
                    />
                </DialogContent>
            </DialogForm>
            <DialogForm
                isMobile={format === FORMAT_SIZES.MOBILE}
                isOpen={openModal === 'VALUES'}
                label="What are your values?"
                closePosition="right"
                description=""
                width={DEFAULT_DIALOG_WIDTH}
                onSave={handleTagUpdate('profilevalues')}
                onClose={()=> setOpenModal(null)}
                hideCloseButton={true}
            >
                <DialogContent>
                    <TagOptionsForm
                        options={ValueOptions.options}
                        currentValues={user.profilevalues}
                        label={ValueOptions.label}
                        maxOptions={ValueOptions.maxCounter}
                        onChange={setNewTags}
                    />
                </DialogContent>
            </DialogForm>
            <ImageCrop
                loading={loadingImage}
                isOpen={openModal === 'UPLOAD_PROFILE_IMAGE'}
                setShowModal={setOpenModal}
                imageToSave={imageToSave}
                fileInputRef={fileInputRef}
                setImage={setImageToSave}
                onClose={()=> setOpenModal(null)}
                isMobile={format === FORMAT_SIZES.MOBILE}
                filename={`user-${user.id}-profile-image-${Date().toString()}`}
                sendCroppedImageServer={uploadFinalImage}
            />
            <DialogForm
                isMobile={format === FORMAT_SIZES.MOBILE}
                isOpen={openModal === 'ASSET_FORM'}
                label=""
                description=""
                onClose={() => {
                    onCloseAsset();
                    resetErrors();
                }}
                closePosition="right"
            >
                <AssetForm
                    uuid={assetId}
                    isMobile={format === FORMAT_SIZES.MOBILE}
                    updateAsset={updateAsset}
                    createAsset={assetId ? undefined : createAsset}
                    errors={assetErrors}
                    updating={updating}
                    onClose={() => {
                        onCloseAsset();
                        resetErrors();
                    }}
                />
            </DialogForm>
        </>
    );
}