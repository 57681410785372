import styled from 'styled-components';
import { Typography } from "@mui/material";

const Header10 = styled(Typography)`
  font-size: 0.83rem !important;
  font-weight: 700 !important;
`;

const Header12 = styled(Typography)`
  font-size: 1rem !important;
  font-weight: 700 !important;
`;

const Header14 = styled(Typography)`
  font-size: 1.17rem !important;
  font-weight: 700 !important;
`;

const Header16 = styled(Typography)`
  font-size: 1.335rem !important;
  font-weight: 700 !important;
`;


const Header18 = styled(Typography)`
  font-size: 1.5rem !important;
  font-weight: 700 !important;
`;

const Header20 = styled(Typography)`
   font-size: 1.8rem !important;
   font-weight: 700 !important;
`;

const Header22 = styled(Typography)`
   font-size: 1.85rem !important;
   font-weight: 700 !important;
`;

const Header46 = styled(Typography)`
   font-size: 3.53rem !important;
   font-weight: 700 !important;
`;

const Header60 = styled(Typography)`
   font-size: 5rem !important;
   font-weight: 700 !important;
`;

const Subheader14 = styled(Typography)`
  font-size: 1.17rem !important;
  font-weight: 400 !important;
`;

const Subheader16 = styled(Typography)`
  font-size: 1.335rem !important;
  font-weight: 400 !important;
`;

const Subheader18 = styled(Typography)`
  font-size: 1.5rem !important;
  font-weight: 400 !important;
`;

const Subheader22 = styled(Typography)`
   font-size: 1.85rem !important;
   font-weight: 400 !important;
`;

const Body10 = styled(Typography)`
   font-size: 0.83rem !important;
   font-weight: 400 !important;
`;

const Body12 = styled(Typography)`
   font-size: 0.9rem !important;
   font-weight: 400 !important;
`;

const Body8 = styled(Typography)`
   font-size: 0.7rem !important;
   font-weight: 400 !important;
`;

export { Body8, Body10, Body12, Header10, Header12, Header14, Header16, Header18, Header20, Header22, Header46, Header60, Subheader14, Subheader16, Subheader18, Subheader22 }