import { styled as styledMUI } from "@mui/material/styles";
import { Button } from "@mui/material";
import { THEME_COLORS } from "./colors";

const SecondaryGrayButton = styledMUI(Button)(() => ({
    color: 'white',
    textTransform: 'capitalize',
    fontWeight: '400',
    backgroundColor: THEME_COLORS.secondary,
    borderRadius: 16,
    boxShadow: 'unset',
    width: '50%',
    marginRight: 8,
    svg: {
        fill: '#fff',
        fontSize: '16px !important',
    },
    '&:hover': {
        backgroundColor: '#A3A7A8',
    },
    '&.condensed': {
        minWidth: 75,
        width: 'unset',
        lineHeight: 'unset',
    }
}));

export default SecondaryGrayButton;