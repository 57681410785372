import _ from 'lodash';

export function getCategoryLabelsFromAssets(categories, assets) {
    const assetCategories = (assets ?? []).map((asset) => asset.category);
    return _.uniq(categories.filter(({value}) => (assetCategories ?? []).includes(value))
        .map(({label}) => label));
}

export function getCategoryLabelsFromValues(categories, values) {
    return _.uniq((categories ?? []).filter(({value}) => (values ?? []).includes(value))
        .map(({label}) => label));
}