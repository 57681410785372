import React, {useEffect, useState} from "react";
import {Avatar, Box, Grid, Tooltip, Typography} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import useFetchUserAssets from "./useFetchUserAssets";
import styled from "styled-components";
import Placeholder from "../../../assets/images/placeholderImage.png";
import moment from "moment";
import DialogForm from "../../common/DialogForm";
import useUpdateContract from "./useUpdateContract";
import { predefineFirstPersonEditing, predefineSecondPersonEditing } from "../predefinedMessages";
import { Body12, Header10, Header12, Subheader14 } from "../../common/Typography";
import UndoRoundedIcon from '@mui/icons-material/UndoRounded';
import { THEME_COLORS } from "../../common/colors";
import { SelectField } from "../../common/Form/SelectField";
import { TextFormField } from "../../common/Form/TextFormField";
import { DialogContent } from "@material-ui/core";
import { DateFormField } from "../../common/Form/DatePicker";
import { styled as styledMUI } from "@mui/material/styles";
import FORMAT_SIZES from "../../common/constants/FormatSizes";

const AssetContainer = styled.div`
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ? 'column' : 'row'};
    gap: ${({isMobile}) => isMobile ? 0 : 12}px;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
`;

const FormField = styled(Grid)`
  margin-bottom: 1.5rem !important;
`;

const AssetPanel = styledMUI(Box)(({ isMobile }) => ({
    flexDirection: `${isMobile ? 'row' : 'column'} !important`,
    gap: `${isMobile ? 12 : 0}px !important`,
    textAlign: `${isMobile ? 'left' : 'center'} !important`,
    ...(isMobile ? { width: '100%', maxWidth: '240px' } : {}),
    justifyContent: `${isMobile ? 'flex-start' : 'center'} !important`,
    alignItems: 'center !important',
}));

const SwappIcon = styled(UndoRoundedIcon)`
  font-size: ${({ isMobile }) => isMobile ? 30 : 50}px !important;
  display: block !important;
  fill: ${THEME_COLORS.primary}!important;
  ${({ flipped }) => flipped && `
    transform: scale(-1, -1) !important;
  `}
`;

const AssetMainImage = styled(Avatar)`
  width: ${({ isMobile }) => isMobile ? 50 : 100}% !important;
  max-width: 100px !important;
  height: auto !important;
  margin-bottom: 0.7rem !important;
`;

const FormTextField = styled(TextFormField)`
  .MuiInputBase-root {
    margin-bottom: 0.1rem !important;
  } 
  .Mui-error {
    margin-left: 0 !important;
  }
  .MuiInputBase-input {
    padding: 4px !important;
  }
`

const FormDateField = styled(DateFormField)`
  
  .MuiInputBase-root {
    margin-bottom: 0.1rem !important;
  } 
  .Mui-error {
    margin-left: 0 !important;
  }
  .MuiInputBase-input {
    padding: 4px !important;
  }
`

const TextAreaLimit = styled.div`
  &, span {
    color: ${ THEME_COLORS.primary } !important; 
  }
  text-align: right;
  font-size: 0.8rem;
`;

function initializeForm(contractUser, contractId, userData) {
    return ({
        id: contractUser.id,
        contractId,
        userId: userData.id,
        description: contractUser.description ?? '',
        estimatedCost: contractUser.estimatedCost,
        dateOfCompletion: moment(contractUser.dateOfCompletion),
        asset: contractUser.asset,
    })
}

export default function ContractForm({
     contractId,
     contractUser = {},
     otherUser = {},
     otherContractUser = {},
     userData = {},
     setOpenContractModal,
     onUpdateSwapContract,
     isMobile,
     isOpen,
     format,
}) {

    const [selectedAsset, setSelectedAsset] = useState(contractUser.asset ?? {});
    const [contractFormData, setContractFormData] = useState(initializeForm(contractUser, contractId, userData));
    const AssetHeader = isMobile ? Subheader14 : Body12;
    const Subheader = isMobile ? Header12: Header10;

    useEffect(() => {
        if (contractUser.asset) {
            setContractFormData(initializeForm(contractUser, contractId, userData))
            setSelectedAsset(contractUser.asset);
        }
    }, [contractUser, contractId, userData]);

    const { updateContract, errors } = useUpdateContract(setOpenContractModal, onUpdateSwapContract);
    const { userAssets } = useFetchUserAssets(userData, contractFormData, setSelectedAsset, setContractFormData);
    const handleValueChange = (field) => (value) => {
        setContractFormData({
            ...contractFormData,
            [field]: value,
        })
    }
    const handleEventChange = (field) => (e) => {
        handleValueChange(field)(e.target.value)
    }

    return (
        <DialogForm
            label="Submit Offer"
            description=""
            isOpen={isOpen}
            onSave={() => {
                const message = otherContractUser.asset?.title ?
                    predefineSecondPersonEditing(otherUser.firstName, userData.firstName) :
                    predefineFirstPersonEditing(otherUser.firstName, userData.firstName);
                updateContract({
                    ...contractFormData,
                    id: contractUser.id,
                }, message)
            }}
            width={500}
            buttonLabel="Submit"
            isMobile={isMobile}
            hideCloseButton={true}
            onClose={()=> setOpenContractModal(null)}
        >
            <DialogContent>
                <AssetContainer isMobile={format === FORMAT_SIZES.MOBILE}>
                    <AssetPanel
                        sx={{ display: { xs: 'flex', sm: 'inline-flex', md: 'inline-flex', lg: 'inline-flex' } }}
                        isMobile={format === FORMAT_SIZES.MOBILE}
                    >
                        <AssetMainImage
                            variant="round"
                            src={otherContractUser.asset?.mainImage || Placeholder}
                            isMobile={format === FORMAT_SIZES.MOBILE}
                        />
                        <div>
                            <AssetHeader>{otherContractUser.asset?.title ?? 'Waiting for asset'}</AssetHeader>
                            <Subheader>{otherUser.firstName} {otherUser.lastName}</Subheader>
                        </div>
                    </AssetPanel>
                    <AssetPanel sx={{ display: { xs: 'flex', sm: 'inline-flex', md: 'inline-flex', lg: 'inline-flex' } }}>
                        <SwappIcon isMobile={isMobile} />
                        <SwappIcon flipped={true} isMobile={isMobile} />
                    </AssetPanel>
                    <AssetPanel
                        sx={{ display: { xs: 'flex', sm: 'inline-flex', md: 'inline-flex', lg: 'inline-flex' } }}
                        isMobile={format === FORMAT_SIZES.MOBILE}
                    >
                        <AssetMainImage
                            variant="round"
                            src={selectedAsset.mainImage || Placeholder}
                            isMobile={format === FORMAT_SIZES.MOBILE}
                        />
                        <div>
                            <AssetHeader>My Asset</AssetHeader>
                            <Subheader>{userData.firstName} {userData.lastName}</Subheader>
                        </div>
                    </AssetPanel>
                </AssetContainer>
                <Grid container>
                    <Grid item xs={12} sm={2} lg={2}>
                        <Typography variant="body1" gutterBottom>
                            Pay with*
                        </Typography>
                    </Grid>
                    <FormField item xs={12} sm={10} lg={10}>
                        <SelectField
                            id="filled-select-asset"
                            select
                            error={!!errors.asset}
                            helperText={errors.asset}
                            value={selectedAsset.id ?? 0}
                            variant="filled"
                            color="warning"
                            style={{ width: '100%'}}
                            onChange={(e) => {
                                const value = e.target.value;
                                const asset = userAssets.find((asset) =>
                                    asset.id === value) ?? {};
                                setSelectedAsset(asset);
                                handleValueChange('asset')(asset);
                            }}
                        >
                            {userAssets.map((option) => (
                                <MenuItem key={`asset-${option.title}-${option.id}`} value={option.id}>
                                    {option.title}
                                </MenuItem>
                            ))}
                        </SelectField>
                    </FormField>
                    <Grid container>
                        <Grid item xs={12} sm={2} lg={2}>
                            <Typography variant="body1" gutterBottom>
                                Details*
                            </Typography>
                        </Grid>
                        <FormField item xs={12} sm={10} lg={10}>
                            <FormTextField
                                error={!!errors.description}
                                helperText={errors.description}
                                id="field-description"
                                multiline
                                placeholder="Add relevant details for expectation management and accountability."
                                rows={2}
                                variant="filled"
                                value={contractFormData.description}
                                onChange={handleEventChange('description')}
                            />
                        </FormField>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={2} lg={2}>
                            <Typography variant="body1" gutterBottom>
                                Swapp by*
                            </Typography>
                        </Grid>
                        <FormField item xs={12} sm={10} lg={10}>
                            <FormDateField
                                error={!!errors.dateOfCompletion}
                                helperText={errors.dateOfCompletion}
                                value={contractFormData.dateOfCompletion}
                                onChange={handleValueChange('dateOfCompletion')}
                                slotProps={{ textField: { variant: 'filled', color: 'warning' } }}
                            />
                        </FormField>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={2} lg={2}>
                            <Typography variant="body1" gutterBottom>
                                Estimated Cost*
                            </Typography>
                        </Grid>
                        <FormField item xs={12} sm={10} lg={10}>
                            <FormTextField
                                id="standard-number"
                                type="number"
                                error={!!errors.estimatedCost}
                                helperText={errors.estimatedCost}
                                inputMode="numeric"
                                value={contractFormData.estimatedCost}
                                onChange={(e) => {
                                    const value = e.target.value < 0 ? 0 : e.target.value;
                                    handleValueChange('estimatedCost')(value);
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                            />
                            <TextAreaLimit>
                                <Tooltip
                                    arrow
                                    placement="bottom"
                                    title="This is the estimated value or price of the product or service you'd like to offer."
                                >
                                    What is this?
                                </Tooltip>
                            </TextAreaLimit>
                        </FormField>
                    </Grid>
                </Grid>
            </DialogContent>
        </DialogForm>
    );
}