import React from "react";
import styled from "styled-components";
import Link from "@mui/material/Link";
import { ReactSVG } from 'react-svg'
import Logo from "../../assets/TheSwappLogo.svg";

const MinimalLink = styled(Link)`
  text-decoration: unset !important;
  color: unset !important;
  display: flex;
  ${({ alignment }) => alignment === 'center' && 'justify-content: center;'}
`;

const LogoContainer = styled.div`
  display: flex;
  position: ${({ position })=> position ?? 'relative' };
  align-items: center;
  width: ${({ width }) => width ?? 160}px;
`;

export default function HomeLogo({ alignment, position, noLink, width }) {
    return (
        <MinimalLink href={!noLink ? '/discovery' : undefined} alignment={alignment}>
            <LogoContainer width={width} position={position}>
                <ReactSVG src={Logo} />
            </LogoContainer>
        </MinimalLink>
    );
}