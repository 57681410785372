import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FORMAT_SIZES from "../common/constants/FormatSizes";

export function useGetFormat() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    if (isMobile) {
        return FORMAT_SIZES.MOBILE;
    }

    if (isTablet) {
        return FORMAT_SIZES.TABLET;
    }

    return FORMAT_SIZES.WEB;
}