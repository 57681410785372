import CoffeeAndLaptop from "../../assets/images/coffee-and-laptop.png";
import RidingBike from "../../assets/images/riding-bike.png";
import Trade from "../../assets/images/trade.png";
import FoldingClothes from "../../assets/images/folding-clothes.png";
import FarmerMarketHandOff from "../../assets/images/farmer-market.png";

export const BACKGROUNDS = [
    {
        background: Trade,
        header: 'Your time is your most valuable asset',
    },
    {
        background: CoffeeAndLaptop,
        header: 'Take your hobbies to the next level',
    },
    {
        background: FarmerMarketHandOff,
        header: 'You are worth more than your bank account',
    },
    {
        background: RidingBike,
        header: 'Unlock a new world of endless possibilities',
        color: '#000000',
    },
    {
        background: FoldingClothes,
        header: 'You are worth more than your bank account',
        maxWidth: '705px',
    },
]