import { useContext, useState } from "react";
import { AuthContext } from "../app/AuthContext";
import axios from "axios";

export function useGetFollowingsInfo(id) {
    const [loadingFollowings, setLoadingFollowings] = useState(false);
    const [loadedFollowings, setLoadedFollowings] = useState(false);
    const [loadingFollowers, setLoadingFollowers] = useState(false);
    const [loadedFollowers, setLoadedFollowers] = useState(false);

    const [followers, setFollowers] = useState([]);
    const [followings, setFollowings] = useState([]);

    const { authContext } = useContext(AuthContext);
    const { token } = authContext;

    const getFollowers = () => {
        axios
            .get(`${process.env.REACT_APP_PUBLIC_API_URL}/follow/followers/${id}`, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'x-access-token': token,
                },
            })
            .then((response) => {
                setFollowers(response.data ?? []);
                setLoadedFollowers(true);
                setLoadingFollowers(false);
            })
            .catch(error => {
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
                setLoadedFollowers(true);
                setLoadingFollowers(false);
            });
    };

    const getFollowings = () => {
        axios
            .get(`${process.env.REACT_APP_PUBLIC_API_URL}/follow/followings/${id}`, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'x-access-token': token,
                },
            })
            .then((response) => {
                setFollowings(response.data ?? []);
                setLoadedFollowings(true);
                setLoadingFollowings(false);
            })
            .catch(error => {
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
                setLoadedFollowings(true);
                setLoadingFollowings(false);
            });
    };

    if (!loadingFollowers && !loadedFollowers && id) {
        setLoadingFollowers(true);
        getFollowers();
    }

    if (!loadingFollowings && !loadedFollowings && id) {
        setLoadingFollowings(true);
        getFollowings();
    }

    return {
        followers,
        followersCount: followers.length,
        loadingFollowers,
        followings,
        followingsCount: followings.length,
        loadedFollowings
    };
}