import React, { useState } from "react";
import styled from "styled-components";
import { useFollow } from "../follow/useFollow";
import ActionMenuModal from "../common/Mobile/ActionMenuModal";
import { Post } from "./components/Post";
import { PostCommentModal } from "./comments/PostCommentModal";

const MiddlePanel = styled.div`
  ${({ isMobile }) => !isMobile && `
    grid-area: MiddlePanel;
    padding: 0 7%;
    padding-bottom: 100px;
  `}
`;

const Feed = styled.div`
  max-width: 445px;
  margin: 0 auto;
`;

function generatePost(posts, follow, unfollow, user, isMobile, handleOpenMenuAction) {
    const [selectedPost, setSelectedPost] = useState(null);

    const handleShowComments = (post) => {
        setSelectedPost(post);
    }

    const handleHideComments = () => {
        setSelectedPost(null);
    }
    return (
        <>
            {
                posts.map((post) =>
                    <Post
                        key={`${post.type}-${user.id}-${post.data.id}`}
                        follow={follow}
                        unfollow={unfollow}
                        user={user}
                        post={post}
                        isMobile={isMobile}
                        onShowComments={handleShowComments}
                        onOpenMenuAction={handleOpenMenuAction}
                    />
                )
            }
            <PostCommentModal
                isMobile={isMobile}
                isOpen={!!selectedPost}
                follow={follow}
                onClose={handleHideComments}
                unfollow={unfollow}
                onOpenMenuAction={handleOpenMenuAction}
                isFollowing={(selectedPost ?? {}).isFollowing}
                type={(selectedPost ?? {}).type}
                post={(selectedPost ?? {}).data}
                currentUser={user}
            />
        </>
    );
}

export function MiddleSection({
  isMobile,
  user,
  discoveryContent = [],
}) {
    const [openModal, setOpenModal] = useState(false);
    const [menuActions, setMenuActions] = useState([]);

    const handleOpenMenuAction = (options) => {
        setMenuActions(options);
        setOpenModal(true);
    };

    const { follow, unfollow } = useFollow();
    return (
        <MiddlePanel isMobile={isMobile}>
            <Feed>
                {generatePost(discoveryContent, follow, unfollow, user, isMobile, handleOpenMenuAction)}
            </Feed>
            {
                isMobile && openModal &&
                <ActionMenuModal
                    options={menuActions}
                    onClose={() => {
                        setOpenModal(false);
                        setMenuActions([]);
                    }}
                />
            }
        </MiddlePanel>
    );
}