import React, {useCallback, useState} from 'react';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import styled from "styled-components";
import { useSubscribe } from "./useSubscribe";

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  border-radius: 24px;
  border: 1px gray solid;
  padding: 4px 16px;
  background-color: #fff;
  max-width: 340px;
  width: 100%;
`;

const EmailInput = styled(InputBase)`
  font-size: 12px !important;
  width: 75% !important;
`;

const SubscribeButton = styled(Button)`
  text-transform: capitalize !important;
  color: #4F5253 !important;
  font-weight: 800 !important;
  float: right !important;
  padding: 0 !important;
  right: -8px !important;
`;

export function SubscribeForm() {
    const [email, setEmail] = useState('');
    const { onSubscribe, loading } = useSubscribe();

    const handleKeyPress = useCallback((event) => {
        if (event.key === 'Enter' && !loading) {
            onSubscribe(email.trim());
        }
    }, [loading, email]);

    const handleSubmit = useCallback(() => {
        onSubscribe(email.trim());
    }, [email]);

    return (
        <InputContainer>
            <EmailInput
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                inputProps={{ 'aria-label': 'subscribe with your email' }}
                size="small"
                onKeyDown={handleKeyPress}
            />
            <SubscribeButton
                onClick={() => handleSubmit()}
                disabled={loading}
                type="button"
                aria-label="subscribe"
            >
                Subscribe
            </SubscribeButton>
        </InputContainer>
    );
}