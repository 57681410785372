import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const SIGN_UP_SUCCESS_MESSAGE = 'You successfully signed up. Kindly check your email for the verification link to finalize the sign-up process.';
const INVALID_USER_MESSAGE = 'Your email is not valid. Kindly check your email for the verification link to finalize the sign-up process.';

const useLoginForm = (callback, queryDefaultParams) => {
  const [displayValidationMessage, setDisplayValidationMessage] =
      useState(queryDefaultParams.get('message') === 'signup_success' ? {
        type: 'success',
        message: SIGN_UP_SUCCESS_MESSAGE,
      } : {});
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
    rememberMe: 'false',
  });
  const { username, password, rememberMe } = inputs;

  const ipLocation = () => {
    return new Promise(resolve => {
      const locationApi = axios.get('https://ipapi.co/json').then(response => {
        return [response.data.latitude, response.data.longitude];
      });
      resolve(locationApi);
    });
  };
  const geoLocation = () => {
    return new Promise(resolve => {
      navigator.geolocation.getCurrentPosition(position => {
        const currentLocation = [0.0, 0.0];
        currentLocation[0] = position.coords.latitude;
        currentLocation[1] = position.coords.longitude;
        resolve(currentLocation);
      });
      setTimeout(resolve, 2000);
    });
  };

  const userLocation = async () => {
    const ip = ipLocation();
    const geo = await geoLocation();
    return geo || ip;
  };

  const handleSubmit = async event => {
    if (event) {
      setLoading(true);
      event.preventDefault();
      const location = await userLocation();
      axios
        .post(
          `${process.env.REACT_APP_PUBLIC_API_URL}/auth/login`,
          {
            username: username.toLowerCase(),
            password,
            rememberMe: rememberMe === 'true',
            lat: location[1],
            lon: location[0],
          },
          {
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          },
        )
        .then(({ data }) => {
          setLoading(false);
          if (data.success === true) {
            localStorage.setItem('token', data.token);
            callback(data);
          } else {
            if (data.err === 'The user is not validated') {
              setDisplayValidationMessage({ type: 'error', message: INVALID_USER_MESSAGE })
            }
            toast.error(data.err, {
              position: 'top-center',
            });
          }
        });
    }
  };

  const handleInputChange = (event) => {
    event.persist();
    const newInput = {
      ...inputs,
      [event.target.name]: event.target.checked ? event.target.checked.toString() : event.target.value,
    };
    setInputs(newInput);
  };

  return {
    displayValidationMessage,
    loading,
    handleSubmit,
    handleInputChange,
    inputs,
  };
};

export default useLoginForm;
