import moment from "moment/moment";
import _ from "lodash";
import { fromApi as fromMessageApi } from './message';

function fromApi(data = { other_user: {}, messages: [] }) {
    return ({
        id: data.id,
        serialId: data.serial_id,
        contractId: data.contract_id,
        status: data.status,
        asset: {
            id: data.asset[0].id,
            uuid: data.asset[0].uuid,
            mainImage: data.asset[0].main_image ?? '',
            title: data.asset[0].title ?? '',
        },
        messages: _.groupBy(data.messages.map(fromMessageApi), record =>
            moment(record.sentAt).format('YYYYMMDD')),
        owner: {
            id: data.owner[0].id,
            uuid: data.owner[0].uuid,
            profilePicture: data.owner[0].profilepicture ?? '',
            firstName: data.owner[0].firstname ?? '',
            lastName: data.owner[0].surname ?? '',
            displayLocation: data.owner[0].displaylocation ?? '',
        },
        requester: {
            id: data.requester[0].id,
            uuid: data.requester[0].uuid,
            profilePicture: data.requester[0].profilepicture ?? '',
            firstName: data.requester[0].firstname ?? '',
            lastName: data.requester[0].surname ?? '',
            displayLocation: data.requester[0].displaylocation ?? '',
        }
    });
}

export { fromApi };