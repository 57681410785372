import React, {useState} from 'react';
import { fromApi } from "../../common/models/user";
import useFetchUserAssets from "../../assets/hooks/useFetchUserAssets";
import { LeftSection } from "./LeftSection";
import { ProfileLayout } from "../components/ProfileLayout";
import { MiddleSection } from "./MiddleSection";
import { RightSection } from "./RightSection";
import useGetCategories from "../../common/hooks/useGetCategories";
import useSwapContract from "../hooks/useSwapContract";
import { getCategoryLabelsFromValues } from "../../common/utils";
import { MobileProfilePage } from "./MobileProfilePage";
import FORMAT_SIZES from "../../common/constants/FormatSizes";
import {AssetForm} from "../../assets/components/AssetForm";
import DialogForm from "../../common/DialogForm";
import useEditAsset from "../../assets/hooks/useEditAsset";

export default function UserProfilePage(props) {
    const { userInfo, format } = props;
    const user = fromApi(userInfo ?? {});
    const { userAssets, fetchAssets } = useFetchUserAssets(user);
    const { categories, loading: loadingCategories } = useGetCategories();
    const [openModal, setOpenModal] = useState(null);

    const { contractDetails, swappMetrics } = useSwapContract(user?.uuid, 'PENDING');
    const userLookingForList = getCategoryLabelsFromValues(categories, user.topcategories);

    const onCloseAsset = ()=> {
        setOpenModal(null);
    };

    const openAssetForm = () => {
        setOpenModal('ASSET_FORM');
    }

    const { updateAsset, createAsset, loading: updating, resetErrors, errors: assetErrors }
        = useEditAsset(onCloseAsset, fetchAssets);

    return (
        <>
            {
            format === FORMAT_SIZES.MOBILE &&
                <MobileProfilePage
                    user={user}
                    swappMetrics={swappMetrics}
                    userLookingForList={userLookingForList}
                    categories={categories}
                    userAssets={userAssets}
                    userInfo={userInfo}
                    contractDetails={contractDetails}
                    type="view"
                    openAssetForm={openAssetForm}
                />
            }
            {
                format !== FORMAT_SIZES.MOBILE &&
                <ProfileLayout type="view" rightPanelHeader="My fav assets to Swapp">
                    <LeftSection
                        type="view"
                        user={user}
                        swappMetrics={swappMetrics}
                        categories={categories}
                        userLookingForList={userLookingForList}
                    />
                    <MiddleSection
                        type="view"
                        user={user}
                        userInfo={userInfo}
                        categories={categories}
                        contractDetails={contractDetails}
                        userLookingForList={userLookingForList}
                        isCurrentUser={true}
                    />
                    <RightSection
                        type="view"
                        user={user}
                        userAssets={userAssets}
                        openAssetForm={openAssetForm}
                    />
                </ProfileLayout>
            }
            <DialogForm
                isMobile={format === FORMAT_SIZES.MOBILE}
                isOpen={openModal === 'ASSET_FORM'}
                label=""
                description=""
                onClose={() => {
                    onCloseAsset();
                    resetErrors();
                }}
                closePosition="right"
            >
                <AssetForm
                    isMobile={format === FORMAT_SIZES.MOBILE}
                    updateAsset={updateAsset}
                    createAsset={createAsset}
                    errors={assetErrors}
                    updating={updating}
                    onClose={() => {
                        onCloseAsset();
                        resetErrors();
                    }}
                />
            </DialogForm>
        </>
    );
}
