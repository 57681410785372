import React from "react";
import styled from "styled-components";
import AddCircleIcon from "@mui/icons-material/AddCircleRounded";
import { THEME_COLORS } from "../../common/colors";

const ASSET_CARD_WIDTH_MD = 170;
const ASSET_CARD_WIDTH = 122;

const EmptyAsset = styled.div`
  ${({ size }) => size === 'thumbnail' && `
    height: 75px;
    width: 75px;
    margin: 4px auto;
  `}
  
  ${({ size }) => size === 'mobile-thumbnail' && `
    height: 120px;
    width: 100px;
    border-radius: 4px;
  `}

  ${({ size }) => size === 'mobile' && `
    height: ${ASSET_CARD_WIDTH + 10}px;
    width: ${ASSET_CARD_WIDTH}px;
    border-radius: 4px;
    
    @media screen and (min-width: 375px) {
      width: ${ASSET_CARD_WIDTH + 26}px;
      height: ${ASSET_CARD_WIDTH  + 36}px;
    }

    @media screen and (min-width: 425px) {
      width: ${ASSET_CARD_WIDTH + 48}px;
      height: ${ASSET_CARD_WIDTH  + 58}px;
    }
  `}

  ${({ size }) => size === 'md' && `
    width: ${ASSET_CARD_WIDTH_MD}px;
    height: ${ASSET_CARD_WIDTH_MD + 10}px;
  `}

  ${({ size }) => !size && `
      height: 150px;
      width: 140px;
      margin-bottom: 8px;
  `}

  background-color: #FAFAFA;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const AddAssetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${({ size }) => size !== 'md' && 'margin: 4px;'}
  ${({ size }) => size === 'mobile-thumbnail' || size === 'mobile' && 'margin: 4px 0;'}
  ${({ size }) => !size && 'margin-bottom: 4rem;'}
`;

const AddIcon = styled(AddCircleIcon)`
  font-size: ${
    ({size}) =>
        size === 'thumbnail' || size === 'mobile-thumbnail' ?
            18 : 40
}px !important;
  fill: ${ THEME_COLORS.primary } !important;
  
`;
export function AddNewAsset({ size, header, openAssetForm }) {
    return (
        <AddAssetContainer size={size} onClick={openAssetForm}>
            <EmptyAsset size={size}>
                <AddIcon size={size} />
            </EmptyAsset>
            {header}
        </AddAssetContainer>
    );
}