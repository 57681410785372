import React, {useState} from "react";
import { Tag, TruncateButton } from "../../styledComponents";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import { SubHeader, TagDisplay } from "../styles";
import styled from "styled-components";
import {DEFAULT_MAX_CATEGORIES_DISPLAY} from "../../constants";

const ProfileLabel = styled(SubHeader)`
  margin-bottom: 0.6rem !important;
  font-weight: 800 !important;
`;

export function TopCategories({ user, userLookingForList }) {
    const [showAllCategories, setShowAllCategories] = useState(false);
    const userLookingForListDisplay = showAllCategories ? userLookingForList : userLookingForList.slice(0, DEFAULT_MAX_CATEGORIES_DISPLAY);

    return (
        <TagDisplay>
            <ProfileLabel>Looking for:</ProfileLabel>
            <div>
                <div>
                    {
                        user.topcategories.length > 0 ? userLookingForListDisplay
                            .map((value) => {
                                return (
                                    <Tag key={`${value}-tag`}>
                                        {value}
                                    </Tag>
                                )
                            }) : 'No top categories yet.'
                    }
                </div>
                {
                    userLookingForList.length > DEFAULT_MAX_CATEGORIES_DISPLAY &&
                    <TruncateButton
                        size="small"
                        onClick={() => setShowAllCategories(!showAllCategories)}
                        startIcon={showAllCategories ? <RemoveIcon/> : <AddIcon/>}
                    >
                        {showAllCategories ? 'Less' : 'More'}
                    </TruncateButton>
                }
            </div>
        </TagDisplay>
    );
}