import axios from 'axios';
import _ from 'lodash';
import {useContext, useState} from 'react';
import {fromApi} from "../../common/models/asset";
import {AuthContext} from "../../app/AuthContext";

function useFetchUserAssets(userData = {}, contractFormData, setSelectedAsset, setContractFormData) {
  const { authContext } = useContext(AuthContext);
  const [loaded, setLoaded] = useState(false);
  const [userAssets, setUserAssets] = useState([]);
  const fetchAssets = (search) => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API_URL}/assets/user/${userData.uuid}${search ? `?search=${search}` : ''}`, {
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'x-access-token': authContext.token,
        },
      })
      .then(response => {
        const userAssets = response.data.map(fromApi);
        setUserAssets(userAssets);
        if (contractFormData?.asset?.id) {
          const contractAsset = userAssets.find((asset) => asset.id === contractFormData?.asset?.id) ?? {};
          setSelectedAsset(contractAsset);
          setContractFormData({
            ...contractFormData,
            asset: contractAsset,
          });
        }
        setLoaded(true);
        return response.data;
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
      });
  };

  if (userData.id && _.isEmpty(userAssets) && loaded === false) {
    fetchAssets();
  }

  return { userAssets, loaded, fetchAssets };
};

export default useFetchUserAssets;
