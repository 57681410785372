import React from "react";
import styled from 'styled-components';
import HomeLogo from "../common/HomeLogo";
import { NavBar } from "../common/NavBar";
import AdVertical from "../../assets/ads/MoreAssetsVertical.png";

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
`;

const Header = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1.1fr 1fr 1fr 0.8fr 1.1fr;
  gap: 0;
  grid-auto-flow: row;
  grid-template-areas: "Logo Navigation Navigation Navigation RightHeader";
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Logo = styled.div`
  grid-area: Logo;
  display: flex;
  align-items: flex-end;
  padding-left: 2rem;
  background-color: #F4F5F4;
  min-height: 100px;
`;

const Navigation = styled.div`
  grid-area: Navigation;
  display: flex;
  align-items: flex-end;
  justify-content: stretch;
  padding: 0 7%;
  min-height: 100px;
`;

const RightHeader = styled.div`
  grid-area: RightHeader;
  text-align: center;
  display: flex;
  align-items: flex-end;
  background-color: #F4F5F4;
`;

const Form = styled.div`
  width: 100%;
  display: grid;
  height: 100%;
  min-height: 86vh;
  grid-template-columns: 1.1fr 1fr 1fr 0.8fr 1.1fr;
  gap: 0;
  grid-auto-flow: row;
  grid-template-areas: "LeftPanel MiddlePanel MiddlePanel MiddlePanel RightPanel";
`;

const Section = styled.div`
  min-height: 50px;
  width: 100%;
`;

const RightPanel = styled.div`
  grid-area: RightPanel;
  background-color: #F4F5F4;
  padding: 2rem;
  padding-bottom: 100px;
  img {
    width: 100%;
    height: auto;
  }
`;

export function PageLayout({ children }) {
    return (
        <Container>
            <HeaderContainer>
                <Header>
                    <Logo>
                        <Section>
                            <HomeLogo position="absolute" />
                        </Section>
                    </Logo>
                    <Navigation>
                        <Section>
                            <NavBar />
                        </Section>
                    </Navigation>
                    <RightHeader>
                        <Section />
                    </RightHeader>
                </Header>
            </HeaderContainer>
            <Form>
                { children }
                <RightPanel>
                    <img src={AdVertical} alt="Add more assets tip." />
                </RightPanel>
            </Form>
        </Container>
    );
}