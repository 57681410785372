import React from "react";
import Divider from '@mui/material/Divider';
import styled from "styled-components";
import {groupConversationsBySender} from "../utils";
import ChatMessage from "./ChatMessage";

const GroupedChatListContainer = styled.div`
  padding-top: 12px;
  
`;

const DateDivider = styled(Divider)`
  margin: 12px 24px !important;
`;

function GroupedChatList({ date, messages, currentUser, otherUser, isMobile }) {
    return (
        <GroupedChatListContainer>
            <DateDivider>{date}</DateDivider>
            {
                groupConversationsBySender(messages, currentUser, otherUser).map((groupedMessages) => {
                    return (
                        <ChatMessage
                            key={`grouped-messages-${groupedMessages.messages.map(m => m.id).join('_')}`}
                            avatar={groupedMessages.profilePicture}
                            otherUserId={otherUser.uuid}
                            side={groupedMessages.side}
                            messages={groupedMessages.messages}
                            isMobile={isMobile}
                        />
                    );
                })
            }
        </GroupedChatListContainer>
    );
}

export default GroupedChatList;