import {ALL_CATEGORIES} from "../common/constants";

export const DEFAULT_MAX_CATEGORIES_DISPLAY = 8;

function toTags({ label, value }) {
    return ({
        tag: label,
        value,
    });
}

const getCategoryOptions = (categories) => {
    return ({
        label: 'Categories',
        maxCounter: categories.length,
        options: categories.map(toTags),
    });
};

const EthnicityOptions = {
    label: 'Ethnicities',
    maxCounter: 6,
    options: [
        { tag: 'African', value: 'African'},
        { tag: 'Afrolatine', value: 'Afrolatine'},
        { tag: 'Andean-descend', value: 'AndeanDescend'},
        { tag: 'Black', value: 'Black'},
        { tag: 'Black American', value: 'BlackAmerican'},
        { tag: 'Black Caribbean', value: 'BlackCaribbean'},
        { tag: 'Caucasian (White)', value: 'Caucasian'},
        { tag: 'Central Asian', value: 'CentralAsian'},
        { tag: 'East Asian', value: 'EastAsian'},
        { tag: 'Indigenous', value: 'Indigenous'},
        { tag: 'Jewish', value: 'Jewish'},
        { tag: 'Latine', value: 'Latine'},
        { tag: 'Middle Eastern', value: 'MiddleEastern'},
        { tag: 'Native American or Alaska Native', value: 'NativeAmerican'},
        { tag: 'Native Hawaiian or Pacific Islander', value: 'PacificIslander'},
        { tag: 'Other', value: 'Other'},
        { tag: 'South Asian', value: 'SouthAsian'},
        { tag: 'Southeast Asian', value: 'SoutheastAsian'},
     ]
}

const PronounOptions = {
    label: 'Pronouns',
    maxCounter: 3,
    options: [
        { tag: 'she/her', value: 'she/her'},
        { tag: 'he/him', value: 'he/him'},
        { tag: 'they/them', value: 'they/them'},
        { tag: 'ze/zir', value: 'ze/zir'},
        { tag: 'xe/xim', value: 'xe/xim'},
        { tag: 'co/co', value: 'co/co'},
        { tag: 'ey/em', value: 'ey/em'},
        { tag: 've/ver', value: 've/ver'},
        { tag: 'per/per', value: 'per/per'},
    ]
};

const ValueOptions = {
    label: 'values',
    maxCounter: 5,
    options: [
        {tag: 'Accuracy', value: 'Accuracy'},
        {tag: 'Authenticity', value: 'Authenticity'},
        {tag: 'Availability', value: 'Availability'},
        {tag: 'Communication', value: 'Communication'},
        {tag: 'Punctuality', value: 'Punctuality'},
        {tag: 'Respect', value: 'Respect'},
        {tag: 'Return/Exchange available', value: 'Return/Exchange available'},
        {tag: 'Quality', value: 'Quality'},
        {tag: 'Transparency', value: 'Transparency'},
    ],
};

export { ALL_CATEGORIES, getCategoryOptions, PronounOptions, EthnicityOptions, ValueOptions }