import { Box, Button, Typography, Tooltip } from "@mui/material";
import LikableIcon from "@mui/icons-material/FavoriteTwoTone";
import LikedIcon from "@mui/icons-material/Favorite";
import React, {useState} from "react";
import styled from "styled-components";
import {THEME_COLORS} from "../../common/colors";
import IconButton from "@mui/material/IconButton";
import useCreateChat from "../hooks/useCreateChat";
import useFetchReviews from "../../review/useFetchReviews";
import {Ratings} from "../../common/RatingDisplays";
import {predefineFirstPersonContact} from "../../chat/predefinedMessages";

const AssetTitle = styled(Typography)`
  font-weight: bold !important;
  font-size: 0.83rem !important;
  display: block !important;
  height: 32px !important;
  overflow-y: hidden !important;
`;

const RatingCount = styled(Typography)`
  display: inline-block;
  font-size: 0.775rem;
`;

const SwappButton = styled(Button)`
  background-color: ${THEME_COLORS.secondary} !important;
  box-shadow: unset !important;
  border-radius: 0 !important;
  font-weight: 800 !important;
  font-size: 0.83rem;
  padding: 2px 10px !important;
  text-transform: capitalize !important;
  min-width: 85px !important;
`;

const AssetSwappContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  margin-top: 8px;
  
  p {
    font-size: 0.725rem;
  }
`;

const AssetCardContainer = styled.div`
  width: ${({ isMobile }) => isMobile ? 140 : 150}px;

  @media screen and (min-width: 375px) {
    width: 165px;
  }

  @media screen and (min-width: 425px) {
    width: 192px;
  }

  padding: ${({ isMobile }) => isMobile ? 4 : 12}px;
  border: 1px solid ${THEME_COLORS.secondary}50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: ${({ isMobile }) => isMobile ? 4 : 16}px;
  
  img {
    width: 100%;
    height: auto;
    border-radius: 4px;
    margin-bottom: 4px;
    cursor: pointer;
  }
`;

const AssetCardDetails = styled.div`
  height: 94px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LikableContainer = styled.div`
    position: relative;
`;

const LikeButtonContainer = styled(IconButton)`
  position: absolute;
  right: -16px;
  top: -16px;
  
  svg {
    fill: #FF6A6B !important;
    ${({ $isLiked }) => !$isLiked && `
        path:first-child {
          opacity: 1;
          fill: white;
        }
    `}
  }
`;

function LikableButton({ onClick, isLiked }) {
    const [likeToggle, setLikeToggle] = useState(!!isLiked);
    return (
        <LikeButtonContainer
            aria-label="like-button"
            onClick={() => setLikeToggle(!likeToggle)}
            $isLiked={likeToggle}
        >
            {likeToggle ? <LikedIcon /> : <LikableIcon />}
        </LikeButtonContainer>
    );
}

function TruncateTitle({ title }) {
    if (title.length > 36) {
        return (
            <Tooltip title={title}>
                {title.slice(0, 36)}...
            </Tooltip>
        );
    }

    return <span>{title}</span>
}

export default function AssetCard({ isMobile, userInfo, asset, history }) {
    const { initiateSwap, loading } = useCreateChat(history);

    const { reviews } = useFetchReviews('asset', asset?.id);

    const handleSwapp = () => {
        if (asset.serialId) {
            history.push(`/chat/${asset.serialId}`);
        } else {
            const predefinedMessage = '[PREDEFINED]Say hi and edit your offer above';
            initiateSwap({
                predefinedMessage,
                ownerId: asset.user.id,
                assetId: asset.id,
            });
        }
    }

    return (
        <AssetCardContainer isMobile={isMobile}>
            <LikableContainer>
                {false && <LikableButton />}
                <img
                    alt={asset.title}
                    src={asset.mainImage}
                    onClick={() => history.push(`/assets/${asset.uuid}`)}
                    style={{ cursor: 'pointer' }}
                />
            </LikableContainer>
            <AssetCardDetails>
                <div>
                    <AssetTitle
                        onClick={() => history.push(`/assets/${asset.uuid}`)}
                        style={{ cursor: 'pointer' }}
                    >
                        <TruncateTitle title={asset.title} />
                    </AssetTitle>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '4px 0 8px 0',
                        }}
                    >
                        <Ratings name="read-only" readOnly size="small" value={reviews.assetReviewAverage ?? 0} />
                        <RatingCount variant="body2">
                            ({reviews.assetReviewTotal})
                        </RatingCount>
                    </Box>
                </div>
                <AssetSwappContainer>
                    {false && <div>
                        <Typography variant="body2">
                            Value
                        </Typography>
                        <Typography variant="body2" fontWeight="bold">
                            $ {asset.estimatedCost}
                        </Typography>
                    </div>}
                    {
                        userInfo?.id !== asset.userId &&
                        userInfo.activeassetcount > 0 &&
                        <SwappButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleSwapp}
                            disabled={loading}
                        >
                            Swapp
                        </SwappButton>
                    }
                </AssetSwappContainer>
            </AssetCardDetails>
        </AssetCardContainer>
    );
}