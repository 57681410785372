/* eslint-disable react/jsx-props-no-spreading */
import React, {useState, useContext, useEffect, useMemo, useCallback} from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AuthContext } from './AuthContext';
import useFetchProfile from "../home/useFetchProfile";
import { isUserProfileCompleted } from "../common/isUserProfileCompleted";
import { HowToSwappModal } from "../common/HowToSwappModal";
import { SecureFooter } from "./Footer/SecureFooter";
import { isUserProfileActive } from "../common/isUserProfileActive";
import { FullscreenLoader } from "../common/FullscreenLoader";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import FORMAT_SIZES from "../common/constants/FormatSizes";
import {useGetFormat} from "./useGetFormat";
import OneSignal from "react-onesignal";

const BLOCKED_ROUTES = ['search', 'discovery', 'chat'];

function isOnBlockedRoute(pathname) {
    return BLOCKED_ROUTES.some((route) => pathname.includes(route))
}

const SecureRoute = ({ component: Component, openToPublic, ...rest }) => {
  const { authContext, setIsProfileCompleted, setIsProfileActive } = useContext(AuthContext);
  const history = useHistory();
  const defaultValue = authContext.token !== null;
  const [secureAuth, setSecureAuth] = useState(defaultValue);
  const [howToOpen, setHowToOpen] = useState(false);
  const format = useGetFormat();
  const isMobile= format === FORMAT_SIZES.MOBILE;

    useEffect(() => {
        if(window.location?.search && window.location?.search.includes('message')) {
            history.push(window.location.pathname);
        }
        window.scrollTo(0, 0);
    }, [window.location.pathname]);

  if (secureAuth === true) {
    authContext.userData.then(data => {
        if (data) {
            setSecureAuth(data.success);
        } else if (!openToPublic) {
            window.location = '/login';
        }
    });
  }

    const { userInfo, loaded, loading } = useFetchProfile(
        authContext.userData,
        authContext.token,
    );

    const isProfileCompleted = useMemo(() => {
        const completed = userInfo?.username && isUserProfileCompleted(userInfo)
        setIsProfileCompleted(completed);
        return completed;
    }, [userInfo.username]);

    const isProfileActive = useMemo(() => {
        const completed = userInfo?.username && isUserProfileActive(userInfo);
        setIsProfileActive(completed);
        return completed;
    }, [userInfo.username]);


  if (!loaded || loading) {
      return (
          <FullscreenLoader />
      );
  }

  if (!isProfileCompleted && isOnBlockedRoute(window.location?.pathname)) {
     history.push('/profile/edit');
  }

  const autoOpenHowTo = () => {
      setHowToOpen(true);
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (secureAuth || openToPublic) {
          return (
              <>
                  <div>
                      <Component
                          isProfileCompleted={isProfileCompleted}
                          isProfileActive={isProfileActive}
                          profileLoading={loading}
                          userInfo={userInfo}
                          loaded={loaded}
                          format={format}
                          autoOpenHowTo={autoOpenHowTo}
                          {...props}
                          {...rest}
                      />
                  </div>
                  <SecureFooter userInfo={userInfo} isMobile={isMobile} />
                  {
                    !isProfileActive &&
                    window.location.pathname !== '/search' &&
                    <HowToSwappModal
                        isOpen={howToOpen}
                        setOpen={setHowToOpen}
                        position={isMobile ? 'fixedMobile' : 'fixed'}
                        isMobile={isMobile}
                    />
                }
              </>
          );
        }
        return <Redirect to="/login" />;
      }}
    />
  );
};

SecureRoute.propTypes = {
  component: PropTypes.func.isRequired,
};
export default SecureRoute;
