import React from "react";
import { Typography, Avatar, Grid } from "@mui/material";

import defaultChatMessageStyles from "./styles";
import styled from "styled-components";
import moment from "moment/moment";
import Link from "@material-ui/core/Link";

const UserAvatar = styled(Grid)`
  display: flex !important;
  align-items: flex-end !important;
  justify-content: flex-end !important;
`;

const ChatMessages = styled(Grid)`
  margin-top: 8px !important;
`;

const MinimalLink = styled(Link)`
  color: unset !important;
  text-decoration: unset !important;
`;

function sortByLatest(message, message2) {
    if(moment(message.sentAt).isSame(moment(message2.sentAt))) return 0;
    return moment(message.sentAt).isAfter(moment(message2.sentAt)) ? 1 : -1;
}

function ChatMessage({
    avatar,
    messages,
    otherUserId,
    side,
    isMobile,
}) {
    const styles = defaultChatMessageStyles({ isMobile });
    return (
        <ChatMessages
            container
            spacing={1}
            sx={{ justifyContent: side === "right" ? "flex-end" : "flex-start"}}
        >
            <Grid container sx={{ width: '100%', marginLeft: { xs: '12px' } }}>
                {side === "left" && (
                    <UserAvatar item xs={1}>
                        <MinimalLink href={`/profile/${otherUserId}`}>
                            <Avatar
                                src={avatar}
                                sx={styles.avatar}
                            />
                        </MinimalLink>
                    </UserAvatar>
                )}
                <Grid item xs={side === "left" ? 11 : 12}>
                    {messages
                        .sort(sortByLatest)
                        .map((msg, i) => {
                            let content = msg.content;
                            let style = {};
                            const isPredefinedMessage = content.includes('[PREDEFINED]');
                            const convoAlignment = isPredefinedMessage ? 'center' : side;
                            const maxSizeWithAdjustment = convoAlignment === "right" ? `${isMobile ? 85 : 75}%` : `${isMobile ? 70 : 60}%`;
                            if (isPredefinedMessage) {
                                content = msg.content.replace('[PREDEFINED]', '');
                                style = styles.predefined;
                            }
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <div key={msg.id || i} style={styles[`${convoAlignment}Row`]}>
                                    <Typography
                                        align={convoAlignment}
                                        sx={{...styles.msg, ...styles[convoAlignment], ...style, maxWidth: { xs: convoAlignment === "center" ? '100%' : maxSizeWithAdjustment }}}
                                    >
                                        {content}
                                    </Typography>
                                </div>
                            );
                    })}
                </Grid>
            </Grid>
        </ChatMessages>
    );
    }

export default ChatMessage;
