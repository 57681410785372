import { createTheme } from "@mui/material/styles";
import {THEME_COLORS} from "../../common/colors";

export default ({ palette, spacing, isMobile }) => {
    const theme = createTheme({ palette: { mode: "light" } });
    const radius = theme.spacing(2.5);
    const size = theme.spacing(3);
    const rightBgColor = theme.palette.primary.main;
    const predefinedColor = THEME_COLORS.primary;
    // if you want the same as facebook messenger, use this color '#09f'
    return {
        avatar: {
            width: size,
            height: size,
            marginBottom: "6px",
            marginRight: "4px",
        },
        centerRow: {
            textAlign: "center",
        },
        leftRow: {
            textAlign: "left",
            marginTop: 1,
        },
        rightRow: {
            textAlign: "right",
            marginTop: 1,
        },
        msg: {
            padding: theme.spacing(1, 2),
            borderRadius: 4,
            marginBottom: "4px",
            display: "inline-block",
            wordBreak: "break-word",
            fontFamily:
            // eslint-disable-next-line max-len
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            fontSize: "12px !important"
        },
        left: {
            borderTopRightRadius: radius,
            borderBottomRightRadius: radius,
            backgroundColor: theme.palette.grey[100]
        },
        right: {
            borderTopLeftRadius: radius,
            borderBottomLeftRadius: radius,
            backgroundColor: rightBgColor,
            color: theme.palette.common.white
        },
         predefined: {
            backgroundColor: 'unset',
            color: predefinedColor,
            fontSize: '0.75rem !important',
            maxWidth: '100% !important',
            padding: theme.spacing(0, 2, 0.7, 2),
        },
        leftFirst: {
            borderTopLeftRadius: radius
        },
        leftLast: {
            borderBottomLeftRadius: radius
        },
        rightFirst: {
            borderTopRightRadius: radius
        },
        rightLast: {
            borderBottomRightRadius: radius
        }
    };
};
