import React from "react";
import { Avatar, Grid, Typography } from "@mui/material";
import SecondaryGrayButton from "../../common/SecondaryGrayButton";
import styled from "styled-components";
import PlaceIcon from '@mui/icons-material/LocationOnRounded';
import Link from "@material-ui/core/Link";
import {useHistory} from "react-router-dom";

const UserDetails = styled.div`
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
`;

const Location = styled(Typography)`
  font-size: 0.7rem !important;
  margin-top: 4px !important;
  margin-bottom: 8px !important;
  display: flex !important;
  align-items: center !important;
`;

const Username = styled(Typography)`
  font-size: 0.64rem !important;
`;

const LocationIcon = styled(PlaceIcon)`
  fill: #717273 !important;
  font-size: 1rem !important;
`;

const UserDescription = styled(Grid)`
  color: #717273;
  text-align: center;
  width: 60px;
  height: 12px;
  overflow-y: hidden;
`;

const Container = styled.div`
`;

const FollowButton = styled(SecondaryGrayButton)`
  padding: 2px !important;
`;

const UserProfile = styled(Avatar)`
  width: 54px !important;
  height: 54px !important;
  margin: 4px auto;
`;

function truncateName(name) {
    return name.length > 10 ? `${name.slice(0, 10)}...` : name;
}

function getMenuActions(history, userUuid, userId, isFollowingList, onFollow, onUnfollow) {
    return [{
        label: 'View profile',
        action: () => history.push(`/profile/${userUuid}`),
    }, {
        label: isFollowingList.includes(userId) ? 'Unfollow' : 'Follow',
        action: isFollowingList.includes(userId) ? onUnfollow: onFollow,
    }, {
        label: 'Help',
        action: () => history.push('/contact-us'),
    }]
}

export function SuggestFollowUserMobile({ user, onOpenMenuAction, follow, unfollow, isFollowingList }) {
    const history = useHistory();

    return (
        <UserDetails>
            <Container
                onClick={() => onOpenMenuAction(getMenuActions(history, user.uuid, user.id, isFollowingList, follow, unfollow))}
            >
                <UserProfile alt="user-profile-picture" src={user.profilepicture} />
                <UserDescription>
                    <Username variant="body1">{truncateName(`${user.firstname} ${user.surname}`)}</Username>
                </UserDescription>
            </Container>
        </UserDetails>
    )
}