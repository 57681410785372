import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { io } from "socket.io-client";
import { getToken, getUserData } from '../auth/AuthContainer';
import OneSignal from "react-onesignal";
import {isUserProfileCompleted} from "../common/isUserProfileCompleted";
import {isUserProfileActive} from "../common/isUserProfileActive";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const currentToken = getToken();
  const [token] = useState(currentToken);
  const [userData] = useState(getUserData(currentToken));
  const [isProfileCompleted, setIsProfileCompleted] = useState(false);
  const [isProfileActive, setIsProfileActive] = useState(false);

  useEffect(() => {
    OneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
      serviceWorkerPath: '/push/onesignal/OneSignalSDKWorker.js',
      serviceWorkerParam: { scope: '/push/onesignal' },
    })
    .then(() => userData)
    .then((userInfo) => {
      if (userInfo && userInfo.notificationpush) {
        OneSignal.Slidedown.promptPush();
        OneSignal.login(userInfo.data?.uuid);
        setIsProfileCompleted(isUserProfileCompleted(userInfo));
        setIsProfileActive(isUserProfileActive(userInfo));
      }
    })
  }, []);

  const authContext = {
    token,
    userData,
  };

  const socket = io(`${process.env.REACT_APP_PUBLIC_API_URL}`, {
    query: { token },
  });

  const socketContext = { socket };
  return (
    <AuthContext.Provider
      value={{
        authContext,
        socketContext,
        isProfileCompleted,
        isProfileActive,
        setIsProfileCompleted,
        setIsProfileActive,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
AuthProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.array.isRequired,
};
