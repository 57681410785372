import React from "react";
import styled from "styled-components";
import DialogForm from "../DialogForm";
import { ShareContent } from "./ShareContent";
import { Popover } from "../Popover";

const ShareContentWeb = styled(Popover)`
    .share-content-pop-over {
        transform: translate3d(463px, -139px, 0px) !important;
    }
`;

export function ShareContentModal({
   anchorEl,
   postMainImage,
   url,
   title,
   onClose,
   keepOpen,
   isMobile,
}) {
    if (isMobile) {
        return (
            <DialogForm
                label="Share"
                description=""
                isOpen={anchorEl}
                onClose={onClose}
                closePosition="right"
                isMobile={isMobile}
            >
                <ShareContent
                    shareUrl={url}
                    shareImagePreview={postMainImage}
                    onClose={onClose}
                    title={title}
                    isMobile={true}
                />
            </DialogForm>
        );
    }

    return (
        <ShareContentWeb
            open={anchorEl}
            onClose={onClose}
            keepOpen={keepOpen}
            anchorEl={anchorEl}
            placement="bottom-start"
        >
            <ShareContent
                shareUrl={url}
                title={title}
                shareImagePreview={postMainImage}
                onClose={onClose}
            />
        </ShareContentWeb>
    )
}