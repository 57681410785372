import { useMemo } from "react";
import _ from "lodash";
import { useGetFollowerContent } from "./useGetFollowerContent";

export function useGetContent() {
    const { followingContent, loading } = useGetFollowerContent();

    const discoveryContent = useMemo(() => {
        return (_.sortBy(followingContent, ['index', 'type']));
    }, [followingContent]);

    return {
        discoveryContent,
        loading,
    };
}