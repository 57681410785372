import {useContext, useState} from "react";
import {AuthContext} from "../app/AuthContext";
import {fromApi} from "../common/models/review";
import axios from "axios";
import _ from "lodash";

function useFetchReviews(type, id, callback) {
    const { authContext } = useContext(AuthContext);
    const [loaded, setLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [reviews, setReviews] = useState(fromApi({}));

    const fetchReview = (id) => {
        axios
            .get(`${process.env.REACT_APP_PUBLIC_API_URL}/reviews/${type}/${id}`, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'x-access-token': authContext.token,
                },
            })
            .then(response => {
                const reviewSummary = fromApi(response.data);
                setReviews(reviewSummary);
                setLoaded(true);
                setIsLoading(false);
                callback && callback(reviewSummary);
                return response.data;
            })
            .catch(error => {
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
            });
    };

    if (id && !isLoading && !loaded && !_.isEmpty(reviews)) {
        setIsLoading(true);
        fetchReview(id);
    }

    return { reviews, loaded, isLoading, fetchReview };
}

export default useFetchReviews;