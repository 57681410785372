import { isArray } from "lodash/lang";

export function queryParamsToFilters(query) {
    return null;
}
export function filtersToQueryParams(filters = {}) {
    const query = Object.entries(filters)
        .filter(([, value]) => isArray(value) ? value.length > 0 : value)
        .map(([key, value]) => {
            if (isArray(value)) {
                return value
                    .map((v) => `${key}[]=${v}`)
                    .join('&');
            }
            return `${key}=${value}`;
        })
        .join('&');
    return `?${query}`;
}

export function groupArrayElements(array, groupSize) {
    let result = [];

    for (let i = 0; i < array.length; i += groupSize) {
        let group = array.slice(i, i + groupSize);
        result.push(group);
    }

    return result;
}