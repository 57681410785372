import React from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../common/colors";
import Stack from "@mui/material/Stack";
import {Box} from "@mui/material";
import Divider from "@mui/material/Divider";

const SwappDetailContainer = styled.div`
  display: block;
  font-size: 0.83rem;
  background-color: #fff;
  ${({ isMobile }) => !isMobile && `
    margin-top: 12px;
    padding: 12px;
    text-align: center;
  `}
`;

const SwappDetails = styled.span`
  color: ${THEME_COLORS.primary} !important;
  font-style: italic !important;
`;

export function RecentSwapps({ isMobile, user, recentSwapps }) {
    const Component = isMobile ? Stack : Box;

    return (
        <Component
            divider={<Divider flexItem />}
            spacing={2}
        >
            {recentSwapps
                .slice(0, 3)
                .map((swapp) => {
                    const userSwapp = user.id === swapp.requestedUserContract.userId ? swapp.requestedUserContract : swapp.requesterUserContract;
                    const otherUserSwapp = user.id === swapp.requestedUserContract.userId ? swapp.requesterUserContract : swapp.requestedUserContract;
                    const {asset: userAsset, quantity: userQuantity} = userSwapp;
                    const {asset, quantity} = otherUserSwapp;
                    return (
                        <SwappDetailContainer isMobile={isMobile}>
                            {user.firstname} just swapped{' '}
                            <SwappDetails>
                                {userQuantity} {userAsset.title}
                            </SwappDetails>{' '}
                            for a
                            <SwappDetails>{' '}
                                {quantity} {asset.title}
                            </SwappDetails>
                        </SwappDetailContainer>
                    );
                })}
        </Component>
    );
}