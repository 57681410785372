import React from "react";
import moment from "moment/moment";
import styled from "styled-components";
import { Avatar, Grid, Typography } from "@mui/material";
import ChatPreview from "../components/ChatPreview";
import { THEME_COLORS } from "../../common/colors";
import PlaceIcon from '@mui/icons-material/LocationOnRounded';

import Searchbar from "../../common/Searchbar";

const LeftPanel = styled.div`
  grid-area: LeftPanel;
  background-color: #F4F5F4;
  padding: 0 2rem;
  ${({ isMobile }) => !isMobile && `
    padding-bottom: 100px;
  `}
  ${({ isMobile }) => isMobile && `
    width: 100%;
    padding-bottom: 4rem;
    `}
`;

const UserDetails = styled(Grid)`
  display: flex !important;
  max-height: 75px !important;
  .MuiAvatar-root, img {
    width: 70px !important;
    height: 70px !important;
  }
`;

const UserDescription = styled.div`
  margin-left: 12px;
  color: #717273;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PreviewContainer = styled.div`
  max-height: 61vh;
  overflow-y: auto;
  ${(showScroll) => showScroll && `
    margin-right: -4px;
    padding-right: 8px;
  `}
  ${({ ismobile }) => ismobile && 'margin-bottom: 30px;'}
`;

const CurrentUserDetails = styled(Typography)`
  font-size: 0.92rem !important;
  font-weight: 700 !important;
  color: ${THEME_COLORS.primary} !important;
  margin-bottom: 0.7rem !important;
`;

const SearchContainer = styled.div`
  margin: 2rem 0;
`;

const Location = styled(Typography)`
  font-size: 0.7rem !important;
  margin-bottom: 12px !important;
  display: flex !important;
  align-items: center !important;
`;

const LocationIcon = styled(PlaceIcon)`
  fill: #717273 !important;
  font-size: 1.25rem !important;
  margin-left: -0.3rem !important;
  margin-right: 0.1rem !important;
`;

function sortByLatest(chat, chat2) {
    if(moment(chat.latestMessage.sentAt).isSame(moment(chat2.latestMessage.sentAt))) return 0;
    return moment(chat.latestMessage.sentAt).isAfter(moment(chat2.latestMessage.sentAt)) ? -1 : 1;
}

export function LeftSection({ activeChats, user, onChatChange, onFetchCurrentUserChats, isMobile }) {
    return (
        <LeftPanel isMobile={isMobile}>
            <div>
                <UserDetails item xs={12} sm={12} lg={12}>
                    <Avatar alt="user-profile-picture" src={user.profilepicture} />
                    <UserDescription>
                        <CurrentUserDetails variant="h6">{user.firstname} {user.surname}</CurrentUserDetails>
                        <Location>
                            <LocationIcon fontSize="small" />
                            {user.displaylocation || 'No Location'}
                        </Location>
                    </UserDescription>
                </UserDetails>
                <SearchContainer>
                    <Searchbar onSearch={(search) => onFetchCurrentUserChats(search)} />
                </SearchContainer>
            </div>
            <PreviewContainer showScroll={activeChats.length > 8} ismobile={isMobile?.toString()}>
                {
                    activeChats
                        .sort(sortByLatest)
                        .map((activeChat) => (
                            <ChatPreview
                                key={`chat-preview-${activeChat.id}`}
                                chatInfo={activeChat}
                                onClick={onChatChange}
                                isMobile={isMobile}
                            />))
                }
            </PreviewContainer>
        </LeftPanel>
    );
}