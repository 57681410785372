export function toUserForm(user) {
    return ({
        id: user.id,
        firstname: user.firstname,
        surname: user.surname,
        description: user.description,
        profilepicture: user.profilepicture,
        notificationmail: user.notificationmail,
        pronouns: user.pronouns,
        ethnicity: user.ethnicity,
        topcategories: user.topcategories,
        profilevalues: user.profilevalues,
        phone: user.phone,
        displaylocation: user.displaylocation,
        show_savings: !!user.show_savings,
        top_my_asset_categories: user.top_my_asset_categories || [],
        top_my_assets: user.top_my_assets || [],
    });
}