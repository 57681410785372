import React, {useState} from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import { THEME_COLORS } from '../../common/colors';
import Badge from '@mui/material/Badge';
import Chip from '@mui/material/Chip';

const TagField = styled(Chip)`
  min-width: 80px;
  border-radius: 0;
  ${({isactive}) => isactive && `
        border: 2px solid gray;
        background-color: #F4F5F4;
  `}
`;

const SelectedBadge = styled(Badge)`
  .MuiBadge-colorPrimary {
    background-color: ${THEME_COLORS.primary};
  }
`;

const TagOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

const TagWrapper = styled.div`
  display: inline-block;
  margin: 8px;
`;

export default function TagOptionsForm({ options, currentValues = [], maxOptions, onChange }) {
    const [values, setValues] = useState(currentValues);
    let valueCount = 0;

    const handleClick = (value) => {
        let newValues = values;
        if (values.includes(value)) {
            newValues = values.filter((v) => v !== value);
        } else if (values.length !== maxOptions) {
            newValues = [...values, value];
        }
        setValues(newValues);
        onChange(newValues);
    }
    return (
        <div>
            <TagOptionsContainer>
                {
                    options.map(({ tag, value }) => {
                        if (values.includes(value)) {
                            valueCount++;
                            return (
                                <TagWrapper>
                                    <SelectedBadge key={`${tag}-tag`} badgeContent={valueCount} color="primary">
                                        <TagField isactive={true} label={tag} variant="outlined" onClick={() => handleClick(value)} />
                                    </SelectedBadge>
                                </TagWrapper>
                            );
                        }
                        return (
                            <TagWrapper>
                                <TagField key={`${tag}-tag`} label={tag} variant="outlined" onClick={() => handleClick(value)} />
                            </TagWrapper>
                        );
                    })
                }
            </TagOptionsContainer>
        </div>
    );
}