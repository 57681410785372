import { PageLayout } from "./components/PageLayout";
import { LeftSection } from "./LeftSection";
import { MiddleSection } from "./MiddleSection";
import useUserInfo from "../common/hooks/useUserInfo";
import { useGetSuggestedFollowing } from "../follow/useGetSuggestedFollowing";
import { useGetFollowerContent } from "./useGetFollowerContent";
import {useGetSuggestedContent} from "./useGetSuggestionContent";
import {DiscoveryPageMobile} from "./DiscoveryPageMobile";
import FORMAT_SIZES from "../common/constants/FormatSizes";
import {useGetSearchedUsers} from "./useGetSearchedUsers";
import {useGetContent} from "./useGetContent";

export function DiscoveryPage({ format, userInfo }) {
    const { userInfo: user } = useUserInfo(userInfo);
    const { suggestedFollowingUsers } = useGetSuggestedFollowing();
    const { discoveryContent, loading } = useGetContent();
    const { searchedUsers, onSearchQuery, loading: loadingSearch, loaded } = useGetSearchedUsers();

    if (format === FORMAT_SIZES.MOBILE) {
        return (
            <DiscoveryPageMobile
                user={user}
                suggestedFollowingUsers={suggestedFollowingUsers}
                discoveryContent={discoveryContent}
                searchedUsers={searchedUsers}
                onSearchQuery={onSearchQuery}
                loadingSearch={loadingSearch}
                searchLoaded={loaded}
            />
        );
    }

    return (
        <PageLayout>
            <LeftSection
                user={user}
                suggestedFollowingUsers={suggestedFollowingUsers}
                searchedUsers={searchedUsers}
                onSearchQuery={onSearchQuery}
                loadingSearch={loadingSearch}
                searchLoaded={loaded}
            />
            <MiddleSection user={user} discoveryContent={discoveryContent} />
        </PageLayout>
    );
}