import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import React from 'react';
import Signup from '../signup';
import './App.css';
import Login from '../login';
import { ProfileHome, EditProfile, PublicProfile } from '../profile';
import Home from '../home';
import { AuthProvider } from './AuthContext';
import SecureRoute from './SecureRoute';
import NotLoggedRoute from './NotLoggedRoute';
import 'react-toastify/dist/ReactToastify.css';
import Toaster from '../toaster';
import UserValidation from '../uservalidation';
import ResetForgotPassword from '../ResetforgotPassword';
import PublicUserReviewList from '../profile/review-list-view';
import ForgotPassword from '../forgotpassword';
import Chat from '../chat';
import { AssetSearchPage, ViewUserAssetDetailPage, ViewUserAssetPage } from "../assets";
import { ContactUsPage } from "../contactus/ContactUsPage";
import { DiscoveryPage } from "../discovery/DiscoveryPage";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { TermsAndConditions } from "../signup/TermsAndConditions";

function App() {
  return (
    <AuthProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Router>
          <Switch>
            <NotLoggedRoute path="/signup" component={Signup} />
            <NotLoggedRoute path="/login" component={Login} />
            <NotLoggedRoute
              path="/validation/newaccount/:token"
              component={UserValidation}
            />
            <NotLoggedRoute path="/forgotpassword" component={ForgotPassword} />
            <NotLoggedRoute path="/terms-and-conditions" component={TermsAndConditions} />
            <NotLoggedRoute
              path="/validation/forgotpassword/:token"
              component={ResetForgotPassword}
            />
            <SecureRoute path="/contact-us" component={ContactUsPage} />
            <SecureRoute path="/search" component={AssetSearchPage} />
            <SecureRoute exact path="/profile" component={ProfileHome} />
            <SecureRoute exact path="/profile/edit" component={EditProfile} />
            <SecureRoute exact path="/profile/:username/reviews" component={PublicUserReviewList} />
            <SecureRoute path="/profile/:username" component={PublicProfile} />
            <SecureRoute path="/assets/:id" component={ViewUserAssetDetailPage} openToPublic={true} />
            <SecureRoute path="/my-assets" component={ViewUserAssetPage} />
            <SecureRoute path="/user-assets/:id" component={ViewUserAssetPage} />
            <SecureRoute exact path="/chat" component={Chat} />
            <SecureRoute path="/chat/:serialId" component={Chat} />
            <SecureRoute path="/assets" component={ViewUserAssetPage} />
            <SecureRoute path="/discovery" component={DiscoveryPage} />
            <Route path="/" component={Home} />
          </Switch>
        <Toaster />
        </Router>
        <ToastContainer hideProgressBar={true} />
      </LocalizationProvider>
    </AuthProvider>
  );
}

export default App;
