import React, { useCallback, useState } from "react";
import { ImageCropper } from "./imageCropper";
import { blobToFile } from "../utils";

const formData = new FormData();

export function ImageCropForm({
  loading,
  setShowModal,
  imageToSave,
  fileInputRef,
  setImage,
  filename,
  aspectRatio,
  sendCroppedImageServer,
  isMobile,
}) {
    const [finalImage, setFinalImage] = useState(null);

    const resetImageFields = () => {
        setImage(null);
        setFinalImage(null);
        formData.delete('file');
        fileInputRef.current.value = '';
    }

    const upload = (imageBlob) => {
        const image = blobToFile(imageBlob, `${filename}.png`);
        formData.append('file', image);
        setFinalImage(formData);
    }

    const saveImage = useCallback(() => {
        sendCroppedImageServer(finalImage);
        resetImageFields();
    }, [finalImage, sendCroppedImageServer]);

    const onCloseCroppedImg = ()  => {
        setShowModal(null);
        resetImageFields();
    }
    return (
        <div style={{ padding: '2rem 0' }}>
            <ImageCropper
                aspectRatio={aspectRatio}
                onCloseCroppedImg={onCloseCroppedImg}
                loading={loading}
                image={imageToSave}
                upload={upload}
                isMobile={isMobile}
                saveImage={saveImage}
            />
        </div>
    );
}