import React from 'react';
import Lottie from 'react-lottie';
import Loader from '../../assets/lotties/loading-1.json';
import styled from "styled-components";
import { THEME_COLORS } from "./colors";

const LoaderContainer = styled.div`
  background-color: ${THEME_COLORS.logo};
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
export function FullscreenLoader() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Loader,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <LoaderContainer>
            <Lottie
                options={defaultOptions}
                height={400}
                width={400}
            />
        </LoaderContainer>
    );
}