
const required = (message) => ([field, value]) => {
    return !value.toString().trim() ? (message || `${field} is required.`) : '';
}

const requiredNumeral = (message) => ([field, value]) => {
    return isNaN(value) || !value ? (message || `${field} is required.`) : '';
}

const requiredList = (message) => ([field, value]) => {
    return !value || value.length === 0 ? (message || `${field} is required.`) : '';
}

const allOf = (validations) => (value) => {
    let err = '';
    validations.every(validation => {
        err = validation(value);
        return !err;
    });
    return err;
}

export function validation() {
    return ({
        firstname: required('First name is required.'),
        surname: required('Last name is required.'),
        profilepicture: required('Profile picture is required.'),
        profilevalues: requiredList('At least one value is required.'),
        ethnicity: requiredList('At least one ethnicity is required.'),
        topcategories: requiredList('At least one top category is required.'),
        top_my_asset_categories: requiredList('At least one top category is required.'),
        displaylocation: required('Location is required.'),
        description: required('Bio is required.'),
        username: required('Email is required.'),
        phone: required('Mobile number is required.'),
    })
}