import React, { useCallback, useState } from "react";
import styled from "styled-components";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorderRounded';
import FavoriteIcon from '@mui/icons-material/FavoriteRounded';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorderRounded';
import ShareIcon from '@mui/icons-material/ShareRounded';
import BookmarkIcon from '@mui/icons-material/BookmarkRounded';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import IconButton from "@mui/material/IconButton";
import { usePostLike } from "../usePostLike";
import { Body12, Header12 } from "../../common/Typography";
import { Tooltip } from "@mui/material";
import { THEME_COLORS } from "../../common/colors";
import {ShareContentModal} from "../../common/ShareContent/ShareContentModal";


const MenuContainer = styled(IconButton)`
  padding: 0;
  svg {
    font-size: 2rem !important;
  }
`;

const FilledLikeIcon = styled(FavoriteIcon)`
  fill: ${THEME_COLORS.primary} !important;
`;

const FilledSaveIcon = styled(BookmarkIcon)`
  fill: ${THEME_COLORS.primary};
`;

const ActionBarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PostActionContainer = styled.div`
  width: 100%;
`

const Description = styled(Body12)`
  margin-left: 6px;
`

const DescriptionContainer = styled.div`
  margin-top: 0.7rem;
`

const MoreButton = styled.span`
  display: inline-block;
  margin-left: 0.3rem;
  cursor: pointer;
  color: #737373;
`

const ActionColumn = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-${({ offset }) => offset}: -5px; 
  position: relative;
  align-items: center;
`

function TruncateDescription({ msg, length }) {
    const [showMore, setShowMore] = useState(false);
    const truncateMessage = msg.length > length ? `${msg.slice(0, length)}...` : msg;

    const handleClick = () => setShowMore(!showMore);

    return (
        <Description as="span">
            {showMore ? msg : truncateMessage}
            {!showMore && msg.length > length && <MoreButton onClick={handleClick}>more</MoreButton>}
        </Description>
    );
}

export function PostActionBar({
    type,
    likeId,
    postId,
    assetUuid,
    assetTitle,
    hideShare,
    postMainImage,
    currentLikeNum,
    userFirstname,
    description,
    isMobile,
    onShowComments,
}) {
    const { isLiked: isPostLiked, likeNum, handleChangeLike } = usePostLike(type, postId, likeId, currentLikeNum);
    const LikeComponent = isPostLiked ? FilledLikeIcon : FavoriteBorderIcon;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleShareContentClick = useCallback((event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }, [anchorEl]);

    return (
        <PostActionContainer>
            <ActionBarContainer>
                <ActionColumn offset="left">
                    <MenuContainer
                        onClick={handleChangeLike}
                        type="button"
                        aria-label="toggle-like-action"
                    >
                        <LikeComponent />
                    </MenuContainer>
                    {
                        !!onShowComments &&
                        <MenuContainer
                            onClick={onShowComments}
                            type="button"
                            aria-label="open--action"
                         >
                            <ChatBubbleOutlineIcon/>
                        </MenuContainer>
                    }
                    {
                        assetUuid &&
                        !hideShare &&
                        <MenuContainer
                            onClick={handleShareContentClick}
                            type="button"
                            aria-label="toggle-like-action"
                        >
                            <ShareIcon />
                        </MenuContainer>
                    }
                    {
                        assetUuid &&
                        !hideShare &&
                        <ShareContentModal
                            anchorEl={anchorEl}
                            title={`Check this out on TheSwapp - ${assetTitle}`}
                            url={`${process.env.REACT_APP_PUBLIC_URL}/assets/${assetUuid}`}
                            postMainImage={postMainImage}
                            onClose={() => setAnchorEl(null)}
                            keepOpen={() => setAnchorEl(anchorEl)}
                            isMobile={isMobile}
                        />
                    }
                </ActionColumn>
                <ActionColumn offset="right">
                    <Tooltip title="Feature coming soon!" arrow placement="bottom">
                    <MenuContainer
                        onClick={undefined}
                        type="button"
                        aria-label="toggle-like-action"
                    >
                        <BookmarkBorderIcon />
                    </MenuContainer>
                    </Tooltip>
                </ActionColumn>
            </ActionBarContainer>
            <Header12>{likeNum} likes</Header12>
            {userFirstname && description &&
                <DescriptionContainer>
                    <Header12 as="span">{userFirstname}</Header12>
                    <TruncateDescription msg={description} length={isMobile ? 45 : 65}/>
                </DescriptionContainer>
            }
        </PostActionContainer>
    );
}