function fromApi(data = {}) {
    return ({
        id: data.id,
        attachments: data.attachments,
        content: data.message,
        readAt: data.read_at,
        recipientId: data.recipient_id,
        senderId: data.sender_id,
        sentAt: data.sent_at,
    });
}

export { fromApi };