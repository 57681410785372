import axios from 'axios';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { AuthContext } from "../../app/AuthContext";
import { fromApi } from "../../common/models/user";

function useFetchProfile(userData) {
    const { authContext } = useContext(AuthContext);
    const [loaded, setLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(fromApi(userData));

  const fetchProfile = (id) => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API_URL}/users/${id}`, {
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'x-access-token': authContext.token,
        },
      })
      .then(response => {
        setUserInfo(response.data[0]);
        setLoaded(true);
        setIsLoading(false);
        return response.data;
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        setLoaded(true);
        setIsLoading(false);
      });
  };

    if (!userInfo.email && !_.isEmpty(userData) && userData.email) {
      setUserInfo(fromApi(userData));
      setLoaded(true);
    }

    if (!isLoading && !loaded && !userInfo.email && !_.isEmpty(userData) && !userData.email) {
        setIsLoading(true);
        fetchProfile(userData.uuid);
    }

  return { userInfo, loaded, fetchProfile, setUserInfo };
};

export default useFetchProfile;
