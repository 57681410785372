import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Grid, Tooltip, Typography} from "@mui/material";
import { SubscribeForm } from "./SubscribeForm";
import HomeLogo from "../../common/HomeLogo";
import PrimaryButton from "../../common/PrimaryButton";
import { Body10, Header22 } from "../../common/Typography";
import { useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import useEnableLocation from "../../profile/hooks/useEnableLocation";

const Header14 = styled(Typography)`
  font-size: 1.17rem !important;
  font-weight: 800 !important;
  margin-top: 4rem !important;
  margin-bottom: 2rem !important;
`;

const Header12 = styled(Typography)`
  font-size: 1rem !important;
  font-weight: 800 !important;
  ${({ secondary }) => secondary && 'margin-top: 2rem !important;'}
  margin-bottom: 1.5rem !important;
`;

const SubHeader12 = styled(Typography)`
  font-size: 1rem !important;
  font-weight: 800 !important;
  text-align: center !important;
`;

const CenterContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${({width}) => width};
  margin: 0 auto;
`;

const SubscribeContainer = styled.div`
    background-color: #F2D0C2;
    padding: ${({ isMobile }) => isMobile ? 2 : 4}rem;
`;

const ColumnContainer = styled(Grid)`
  width: 87% !important;
  margin: 0 auto !important;
  ${({ isMobile }) => isMobile && 'text-align: center;'}
`;

const CenterContainer = styled(Grid)`
    margin: 0 auto !important;
`;

const WhatIsContainer = styled.div`
  padding: ${({ isMobile }) => isMobile ? 2 : 4}rem !important;
  width: 100%;
`;

const WhatIsHeader = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'};
  ${({ isMobile }) => isMobile && 'gap: 4px;'}
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const HaveQuestionContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 305px;
  width: 100%;
`;

const LegalContainer = styled.div`
  background-color: #F4F5F4;
  padding: 4rem;
  ${({ isMobile }) => isMobile && 'padding: 2rem 2rem 2rem 0;'}
  font-size: 0.7rem !important;
`;

const WhatIsHeaderText = styled(Header22)`
  margin: 0 0.5rem !important;
`;

const WhatIsLogo = styled.div`
  display: flex;
  align-items: center;
`;

const SocialContainer = styled.div`
  padding: 2rem 4rem;
  ${({ isMobile }) => isMobile && 'padding: 2rem;'}
  background-color: #F4F5F4;
`;

const SocialSections = styled(Grid)`
  width: 100% !important;
`;

const DownloadSection = styled(Grid)`
  display: flex !important;
  align-items: center !important;
  
  flex-direction: column !important;
  ${({ isMobile }) => !isMobile && `
    border-right: 1px solid #717273 !important;
  `}
  ${({ isMobile }) => isMobile && `
    margin-bottom: 1.5rem !important;
  `}

  button {
    margin-top: 2rem !important;
  }
`;

const CopyrightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 4rem;
  ${({ isMobile }) => isMobile && `
    padding-bottom: 78px;
    flex-direction: column;
    text-align: center;
    gap: 8px;
  `}
  background-color: #717273;
  span {
    color: #fff !important;
  }
`;

const MinimalLink = styled(Link)`
  text-decoration: unset !important;
  color: inherit !important;
`;


export function SecureFooter({ isMobile }) {
    const history = useHistory();
    const [userCountry, setUserCountry] = useState('');
    const { handleEnableLocation } = useEnableLocation(({ country }) => {
        setUserCountry(country);
    });

    useEffect(() => {
        handleEnableLocation()
    }, []);

    const navigateTo = (route) => () => {
        history.push(route);
    }

    return (
        <div>
            <SubscribeContainer isMobile={isMobile}>
                <CenterContent width="100%" style={{ maxWidth: 600 }}>
                    <SubHeader12>Send me exclusive and personalized offers for swapping with TheSwapp</SubHeader12>
                    <SubscribeForm />
                </CenterContent>
            </SubscribeContainer>
            <WhatIsContainer isMobile={isMobile}>
                <WhatIsHeader isMobile={isMobile}>
                    <WhatIsHeaderText>What is</WhatIsHeaderText>
                    <WhatIsLogo>
                        <HomeLogo noLink />
                        <WhatIsHeaderText>?</WhatIsHeaderText>
                    </WhatIsLogo>
                </WhatIsHeader>
                <ColumnContainer container isMobile={isMobile} columnSpacing={isMobile ? 1 : 6}>
                    <Grid item xs={12} sm={4} lg={4}>
                        <Header14>More access. More freedom.</Header14>
                        <Typography variant="body">Discover ready-to-trade nearby products & services and learn what others are willing to swapp for your skills and available products. Join to redefine what worth and access looks like.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4}>
                        <Header14>Optimize your spending.</Header14>
                        <Typography variant="body">Get personalized findings and swapp suggestions tailored just for you. Optimize your spending, keep track of your savings, and your active swapps, all in one stop.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4}>
                        <Header14>Expand your financial network.</Header14>
                        <Typography variant="body">Learn trends such as hot swapps near you, and follow new friends to access a world of possibilities. You don’t just save money, you also expands your global financial network effortlessly.</Typography>
                    </Grid>
                    <HaveQuestionContainer>
                        <Header14>Have a question?</Header14>
                        <PrimaryButton
                            onClick={navigateTo('/contact-us')}
                            expanded={isMobile}
                        >
                            Go to The Help Center
                        </PrimaryButton>
                    </HaveQuestionContainer>
                </ColumnContainer>
            </WhatIsContainer>
            <LegalContainer isMobile={isMobile}>
                <ol>
                    <li>Trade-in values will vary based on personal criteria. The information provided on this platform regarding nearby products, services, and exchange opportunities is based on user submissions and may not always be accurate or up-to-date. Users are encouraged to verify the details independently before engaging in any transactions.</li>
                    <li>Users are responsible for the decisions they make regarding the exchange of products, services, or skills on this platform. We do not endorse or guarantee the quality, safety, or legality of any offerings listed by users.</li>
                    <li>Our platform may contain links to third-party websites or services for additional information or convenience. We do not endorse or assume responsibility for the content, policies, or practices of any third-party sites.</li>
                    <li>Information provided on this platform should not be construed as financial advice. Users should consult with a qualified financial advisor before making any financial decisions or investments.</li>
                    <li>We are not liable for any direct, indirect, incidental, special, or consequential damages arising out of the use or inability to use our platform, even if we have been advised of the possibility of such damages.</li>
                </ol>
            </LegalContainer>
            <SocialContainer>
                <SocialSections container columnSpacing={isMobile ? 1 : 3}>
                    <DownloadSection isMobile={isMobile} item xs={12} sm={5} lg={5}>
                        <HomeLogo noLink />
                        <Tooltip title="App coming soon!" arrow placement="bottom">
                            <PrimaryButton expanded={isMobile}>Download the App</PrimaryButton>
                        </Tooltip>
                    </DownloadSection>
                    <Grid item xs={12} sm={7} lg={7}>
                        <CenterContainer container columnSpacing={isMobile ? 1 : 6}>
                            <Grid item xs={12} sm={4} lg={4}>
                                <Header12>Swapp</Header12>
                                <MinimalLink href="/signup">
                                    <Body10 as="p">Sign up</Body10>
                                </MinimalLink>
                                <MinimalLink href="/#">
                                    <Body10 as="p">Refer a friend</Body10>
                                </MinimalLink>
                                <Header12 secondary={true}>Account</Header12>
                                <MinimalLink href="/profile/edit">
                                    <Body10 as="p">Manage Your Account</Body10>
                                </MinimalLink>
                            </Grid>
                            <Grid item xs={12} sm={4} lg={4}>
                                <Header12>About</Header12>
                                <MinimalLink href="/">
                                    <Body10 as="p">TheSwapp</Body10>
                                </MinimalLink>
                                <MinimalLink href="/#">
                                    <Body10 as="p">Impact</Body10>
                                </MinimalLink>
                                <MinimalLink href="/#">
                                    <Body10 as="p">Policies</Body10>
                                </MinimalLink>
                                <MinimalLink href="/#">
                                    <Body10 as="p">Our Values</Body10>
                                </MinimalLink>
                            </Grid>
                            <Grid item xs={12} sm={4} lg={4}>
                                <Header12>Help</Header12>
                                <MinimalLink href="/contact-us">
                                    <Body10 as="p">Help Center</Body10>
                                </MinimalLink>
                                <MinimalLink href="/#">
                                    <Body10 as="p">Privacy Setting</Body10>
                                </MinimalLink>
                            </Grid>
                        </CenterContainer>
                    </Grid>
                </SocialSections>
            </SocialContainer>
            <CopyrightContainer isMobile={isMobile}>
                <Typography variant="body">{userCountry || 'United States'}  |  English </Typography>
                <Typography variant="body">Copyright © 2024 TheSwapp LLC. All rights reserved.</Typography>
            </CopyrightContainer>
        </div>
    );
}