import React, { useState, createRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import SecondaryGrayButton from "../SecondaryGrayButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PrimaryButton from "../PrimaryButton";
import styled from "styled-components";

const IMAGE_HEIGHT = 440;
const MOBILE_IMAGE_HEIGHT = 350;
const MOBILE_IMAGE_PREVIEW = 265;

const ActionMenu = styled.div`
  width: ${({ isMobile }) => isMobile ? 100 : 50}%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const PreviewContainer = styled.div`
  height: ${({ isMobile }) => isMobile ? MOBILE_IMAGE_PREVIEW : IMAGE_HEIGHT }px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  img {
    height: 100%;
    width: auto;
  }
`;
export function ImageCropper({
     aspectRatio,
     onCloseCroppedImg,
     loading,
     image,
     upload,
     saveImage,
     isMobile,
}) {
    const [] = useState(null);
    const [cropData, setCropData] = useState(null);
    const cropperRef = createRef();

    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            const canvas = cropperRef.current?.cropper.getCroppedCanvas();
            setCropData(canvas.toDataURL());
            canvas.toBlob(file => {
                upload(file);
            }, 'image/jpeg');
        }
    };

    return (
        <div>
            {
                !cropData &&
                <div>
                    <div style={{ marginBottom: '2rem' }}>
                        <Cropper
                            ref={cropperRef}
                            style={{height: isMobile ? MOBILE_IMAGE_HEIGHT : IMAGE_HEIGHT, width: "100%"}}
                            zoomTo={0.5}
                            initialAspectRatio={aspectRatio ?? 1}
                            preview=".img-preview"
                            src={image}
                            viewMode={2}
                            dragMode="move"
                            cropBoxResizable={false}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            guides={true}
                        />
                    </div>
                    <ActionMenu isMobile={isMobile}>
                        <SecondaryGrayButton
                            onClick={() => onCloseCroppedImg()}
                            variant="contained"
                            color="primary"
                        >
                            Close
                        </SecondaryGrayButton>
                        <PrimaryButton
                            onClick={getCropData}
                            variant="contained"
                            color="secondary"
                            expanded={isMobile}
                        >
                            Crop image
                        </PrimaryButton>
                    </ActionMenu>
                </div>
            }
            {
                cropData &&
                <div>
                    <PreviewContainer isMobile={isMobile}>
                        <img src={cropData} alt="cropped-image" />
                    </PreviewContainer>
                    <ActionMenu isMobile={isMobile}>
                        <SecondaryGrayButton
                            onClick={() => setCropData(null)}
                            startIcon={<ArrowBackIcon />}
                            variant="contained"
                            color="primary"
                        >
                                Back
                        </SecondaryGrayButton>
                        <PrimaryButton
                            onClick={() => saveImage()}
                            variant="contained"
                            color="secondary"
                            disabled={loading}
                            loading={loading}
                            expanded={isMobile}
                        >
                            Save
                        </PrimaryButton>
                    </ActionMenu>
                </div>
            }
        </div>
    );
}
