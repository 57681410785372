import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import { Input } from '@mui/material';

const CustomLabel = styled(InputLabel)(() => ({
  '&': {
    marginBottom: 0,
    fontSize: 16,
  },
}));

const CustomInput = styled(Input)(({ theme }) => ({
  'label + &': {
    marginTop: 0,
    marginBottom: 16,
    width: '100%',
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid',
    borderColor: '#4E5152',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export function FormInput(props) {
  const { label, htmlFor, style, ...rest } = props;
  return (
    <div style={style}>
      <CustomLabel shrink htmlFor={htmlFor}>
        {label}
      </CustomLabel>
      <CustomInput {...rest} />
    </div>
  );
}
