import moment from "moment";

export function sortByLatest(message, message2) {
    if(moment(message.sentAt).isSame(moment(message2.sentAt))) return 0;
    return moment(message.sentAt).isAfter(moment(message2.sentAt)) ? 1 : -1;
}

function groupConversationsBySender(messages = [], currentUser, otherUser) {
    return messages
        .sort(sortByLatest)
        .reduce((groupedConversations, message) => {
        const lastGroupIndex = groupedConversations.length - 1;
        if (message.content.includes('[PREDEFINED]')) {
            groupedConversations.push({
                senderId: undefined,
                profilePicture: undefined,
                side: 'center',
                messages: [message],
            })
        } else if (lastGroupIndex >= 0 && groupedConversations[lastGroupIndex].senderId === message.senderId) {
            groupedConversations[lastGroupIndex].messages.push(message);
        } else {
            groupedConversations.push({
                senderId: message.senderId,
                profilePicture: otherUser.id === message.senderId ? otherUser.profilePicture : '',
                side: otherUser.id === message.senderId ? 'left' : 'right',
                messages: [message],
            })
        }
        return groupedConversations;
    }, []);
}

export { groupConversationsBySender }