import { useCallback, useContext, useState } from "react";
import { AuthContext } from "../app/AuthContext";
import axios from "axios";

export function usePostLike(postType, postId, currentLikeId, currentLikeNum = 0){
    const { authContext } = useContext(AuthContext);
    const { token } = authContext;
    const [isLiked, setIsLiked] = useState(!!currentLikeId);
    const [likeId, setLikeId] = useState(currentLikeId);
    const [likeNum, setLikeNum] = useState(currentLikeNum);

    const likeContent = () => {
        axios
            .post(`${process.env.REACT_APP_PUBLIC_API_URL}/postLikes`,
                {
                    postType,
                    postId
                }
                ,{
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'x-access-token': token,
                },
            })
            .then(({ data }) => {
                if(data.created) {
                    setLikeId(data.id);
                }
            })
            .catch(error => {
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
            });
    };

    const unlikeContent = () => {
        axios
            .delete(`${process.env.REACT_APP_PUBLIC_API_URL}/postLikes/${likeId}`, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'x-access-token': token,
                },
            })
            .then(({ data }) => {
                if(data.deleted) {
                    setLikeId(data.id);
                }
            })
            .catch(error => {
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
            });
    };

    const handleChangeLike = useCallback(() => {
        if (isLiked) {
            unlikeContent();
            setIsLiked(false);
            setLikeNum(likeNum - 1);
        } else {
            likeContent();
            setIsLiked(true);
            setLikeNum(likeNum + 1);
        }
    }, [isLiked, likeNum]);

    return ({
        handleChangeLike,
        isLiked,
        likeNum,
        likeId,
    })
}