import axios from 'axios';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { AuthContext } from "../../app/AuthContext";
import { fromApi } from "../../common/models/user";

function useUserInfo(userId) {
    const { authContext } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [userInfo, setUserInfo] = useState({});

  const fetchUserInfo = () => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API_URL}/users/${userId}`, {
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'x-access-token': authContext.token,
        },
      })
      .then(response => {
        setLoaded(true);
        setLoading(false);
        setUserInfo(fromApi(response.data[0]));
        return response.data;
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
          setLoaded(true);
          setLoading(false);
      });
  };

  if (userId && _.isEmpty(userInfo) && !loading && !loaded) {
      setLoading(true);
      fetchUserInfo();
  }

  return { userInfo, loading };
};

export default useUserInfo;
