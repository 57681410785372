const ALL_CATEGORIES = [
    {
        label: 'Art & Collectibles',
        value: 'ART_COLLECTIBLES',
    },
    {
        label: 'DIY',
        value: 'DIY',
    },

    {
        label: 'Education',
        value: 'EDUCATION',
    },
    {
        label: 'Electronics',
        value: 'ELECTRONICS',
    },
    {
        label: 'Entertainment',
        value: 'ENTERTAINMENT',
    },
    {
        label: 'Fashion',
        value: 'FASHION',
    },
    {
        label: 'Fitness & Wellness',
        value: 'FITNESS_WELLNESS',
    },
    {
        label: 'Food and beverages',
        value: 'FOOD_BEVERAGES',
    },
    {
        label: 'Games & Toys',
        value: 'GAME_TOYS',
    },

    {
        label: 'Hair & Beauty',
        value: 'HAIR_BEAUTY',
    },
    {
        label: 'Home & Living',
        value: 'HOME_LIVING',
    },
    {
        label: 'Professional Services',
        value: 'PROFESSIONAL_SERVICES',
    },
    {
        label: 'Pets',
        value: 'PETS',
    },
    {
        label: 'Social Connections',
        value: 'SOCIAL',
    },
    {
        label: 'Transportation',
        value: 'TRANSPORTATION',
    },
    {
        label: 'Travel',
        value: 'TRAVEL',
    },
];

const ALL_SUBCATEGORIES = [
    {
        label: 'Automobile',
        value: 'AUTOMOBILE',
    },
    {
        label: 'Beauty',
        value: 'BEAUTY',
    },
    {
        label: 'Bikes',
        value: 'BIKES',
    },
    {
        label: 'Books',
        value: 'BOOKS',
    },
    {
        label: 'Cleaning',
        value: 'CLEANING',
    },
    {
        label: 'Clothes',
        value: 'CLOTHING',
    },
    {
        label: 'Concert Tickets',
        value: 'CONCERT',
    },
    {
        label: 'Data entry',
        value: 'DATA_ENTRY',
    },
    {
        label: 'Delivery',
        value: 'DELIVERY',
    },

    {
        label: 'Education',
        value: 'EDUCATION',
    },
    {
        label: 'Electronics',
        value: 'ELECTRONICS',
    },
    {
        label: 'Entertainment',
        value: 'ENTERTAINMENT',
    },
    {
        label: 'Event Planning',
        value: 'EVENT_PLANNING',
    },
    {
        label: 'Eyelashes',
        value: 'EYELASHES',
    },
    {
        label: 'Food and beverages',
        value: 'FOOD_SERVICE',
    },
    {
        label: 'Furniture',
        value: 'FURNITURE',
    },
    {
        label: 'Furniture assembly',
        value: 'ASSEMBLY',
    },
    {
        label: 'Groceries',
        value: 'GROCERIES',
    },
    {
        label: 'Grocery shopper',
        value: 'GROCERY_SERVICE',
    },
    {
        label: 'Hair',
        value: 'HAIR',
    },
    {
        label: 'Handyman Services',
        value: 'HANDYMAN_SERVICES',
    },
    {
        label: 'Home',
        value: 'HOME',
    },
    {
        label: 'Laundry',
        value: 'LAUNDRY'
    },
    {
        label: 'Moving/packing boxes',
        value: 'MOVING_SERVICE',
    },
    {
        label: 'Nails',
        value: 'NAILS',
    },
    {
        label: 'Other',
        value: 'OTHER',
    },
    {
        label: 'Painting',
        value: 'PAINTING',
    },
    {
        label: 'Pets',
        value: 'PET_SERVICE',
    },
    {
        label: 'Photography',
        value: 'PHOTOGRAPHY',
    },
    {
        label: 'Rides',
        value: 'RIDES',
    },
    {
        label: 'Sports',
        value: 'SPORTS',
    },
    {
        label: 'Toys',
        value: 'TOYS',
    },
    {
        label: 'Web design',
        value: 'WEB_DESIGN',
    },
];

const ALL_CATEGORIES_TYPES = [
    {
        label: 'Product',
        value: 'PRODUCT',
    },
    {
        label: 'Service',
        value: 'SERVICE',
    },
]

const PRODUCT_CONDITIONS = [
    {
        label: 'For Parts',
        value: 1,
        type: 'PRODUCT',
    },
    {
        label: 'Acceptable',
        value: 2,
        type: 'PRODUCT',
    },
    {
        label: 'Good',
        value: 3,
        type: 'PRODUCT',
    },
    {
        label: 'Like-New',
        value: 4,
        type: 'PRODUCT',
    },
    {
        label: 'Brand-New',
        value: 5,
        type: 'PRODUCT',
    },
];

const SERVICE_CONDITIONS= [
    {
        label: 'Novice',
        value: 1,
        type: 'SERVICE',
    },
    {
        label: 'Intermediate',
        value: 2,
        type: 'SERVICE',
    },
    {
        label: 'Proficient',
        value: 3,
        type: 'SERVICE',
    },
    {
        label: 'Advanced',
        value: 4,
        type: 'SERVICE',
    },
    {
        label: 'Expert',
        value: 5,
        type: 'SERVICE',
    },
];

export { ALL_CATEGORIES, ALL_CATEGORIES_TYPES, ALL_SUBCATEGORIES, PRODUCT_CONDITIONS, SERVICE_CONDITIONS }