import {styled as styledMUI} from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const Select = styledMUI(TextField)(() => ({
    width: '100%',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    '.MuiInputBase-root::before': {
        border: 'unset !important',
    },
    '.MuiSelect-select': {
        padding: '4px',
    },
    '.Mui-error': {
        marginLeft: '0 !important',
    },
    '.MuiInputBase-root::after': {
        border: 'unset !important',
    },
    '.MuiInputBase-multiline': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        padding: '2px',
    },
    '.MuiInputBase-root': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    }
}));

export function SelectField(props) {
    return( <Select {...props} />);
}