import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";
import { THEME_COLORS } from "../colors";

const FormCheckbox = styled(Checkbox)`
  font-size: 0.775rem;
  &.Mui-checked {
    color: ${THEME_COLORS.primary} !important;
  }
`;

export default FormCheckbox;