import {useContext, useState} from "react";
import {AuthContext} from "../../app/AuthContext";
import axios from "axios";
import {genericUpdateError} from "../../toaster/utils";

export default function useCreateChat(history) {
    const { authContext } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const initiateSwap = (formData) => {
        setLoading(true);
        console.log('formData', formData)
        axios
            .post(`${process.env.REACT_APP_PUBLIC_API_URL}/chat`,
                formData,
                {
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'x-access-token': authContext.token,
                    },
                })
            .then(({ data }) => {
                window.location = `/chat/${data.serial_id}`;
            })
            .catch(error => {
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
                genericUpdateError(`Failed to start a swapp: ${error}`);
            });
    }

    return { initiateSwap, loading }
}