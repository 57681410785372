import React from 'react';
import styled from "styled-components";
import { SecondaryButton } from "./SecondaryButton";
import { Header18 } from "../common/Typography";

const Header = styled(Header18)`
    text-align: center;
    font-weight: bold;
    margin: 0 24px;
    margin-top: 14px;
`;
const Description = styled.div`
    text-align: center;
    background-color: #F4F5F4;
    padding: 12px 8px;
    margin: 24px 0;
`;

export function ResearchDisclaimer() {
    const onClick = () => {
        window.location = '/profile/edit?message=login_success';
    }
    return (
        <div>
            <Header>This is a free Beta research preview</Header>
            <Description>
                Our goal is to get external feedback aimed at improving our user’s experience. We aim to expand people’s opportunities to access care, leisure, safety, and overall security by enabling seamless resource exchange, both material and immaterial, with those nearby.
            </Description>
            <Description>
                Now more than ever we need to rely in the power of collective wealth and well being. We encourage you to explore and provide your thoughts via our Contact Us section or email hello@theswapp.com.
            </Description>
            <Description>
                Diversify and enhance your acquisition power in this user-friendly ecosystem. Tap into the power of collective wealth while expanding your financial network.
            </Description>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <SecondaryButton
                    onClick={onClick}
                >
                    Continue
                </SecondaryButton>
            </div>
        </div>
    );
}