import React from "react";
import { AddNewAsset } from "../../assets/components/AddNewAsset";
import { Typography } from "@mui/material";
import { SubHeader } from "../editProfile/styles";

const AddNewAssetCard = ({ size, openAssetForm, hideTitle }) => {
    return (
        <AddNewAsset
            header={
                !hideTitle && (size === 'thumbnail' ?
                    <Typography variant="body2">Add a new asset</Typography> :
                    <SubHeader>Add new assets</SubHeader>)
            }
            size={size}
            openAssetForm={openAssetForm}
        />
    );
}

export { AddNewAssetCard };